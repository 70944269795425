export const cityData = [
    {
      "country": "USA", 
      "city": [
        {"Atlanta": {"latitude": 33.7490, "longitude": -84.3880, "address": "Atlanta, GA"}},
        {"New York": {"latitude": 40.7128, "longitude": -74.0060, "address": "New York, NY"}},
        {"Baltimore": {"latitude": 39.2904, "longitude": -76.6122, "address": "Baltimore, MD"}},
        {"Boston": {"latitude": 42.3601, "longitude": -71.0589, "address": "Boston, MA"}},
        {"Charlotte": {"latitude": 35.2271, "longitude": -80.8431, "address": "Charlotte, NC"}}
      ]
    },
    {
      "country": "Canada", 
      "city": [
        {"Toronto": {"latitude": 43.651070, "longitude": -79.347015, "address": "Toronto, ON"}},
        {"Vancouver": {"latitude": 49.282729, "longitude": -123.120738, "address": "Vancouver, BC"}},
        {"Montreal": {"latitude": 45.501690, "longitude": -73.567253, "address": "Montreal, QC"}},
        {"Denver": {"latitude": 39.7392, "longitude": -104.9903, "address": "Denver, CO"}},
        {"Calgary": {"latitude": 51.0447, "longitude": -114.0719, "address": "Calgary, AB"}}
      ]
    },
    {
      "country": "UK", 
      "city": [
        {"London": {"latitude": 51.5074, "longitude": -0.1278, "address": "London"}},
        {"Liverpool": {"latitude": 53.4084, "longitude": -2.9916, "address": "Liverpool"}},
        {"Oxford": {"latitude": 51.754816, "longitude": -1.254367, "address": "Oxford"}},
        {"Bristol": {"latitude": 51.4545, "longitude": -2.5879, "address": "Bristol"}},
        {"Manchester": {"latitude": 53.4808, "longitude": -2.2426, "address": "Manchester"}}
      ]
    },
    {
      "country": "India", 
      "city": [
        {"Mumbai": {"latitude": 19.0760, "longitude": 72.8777, "address": "Mumbai"}},
        {"Delhi": {"latitude": 28.7041, "longitude": 77.1025, "address": "Delhi"}},
        {"Noida": {"latitude": 28.5355, "longitude": 77.3910, "address": "Noida"}},
        {"Bengaluru": {"latitude": 12.9716, "longitude": 77.5946, "address": "Bengaluru"}},
        {"Kolkata": {"latitude": 22.5726, "longitude": 88.3639, "address": "Kolkata"}}
      ]
    }
  ];
  