import { useState, useEffect, useRef } from "react"
import common from "../../utils/common"
import fetcher from "../../utils/fetch"
import config from "../../utils/config"
import { useNavigate } from "react-router-dom"
import apiMapper from "../../utils/apiMapper"

const Adminmembership = () => {

    const [membershipPlanData, setMembershipPlanData] = useState([]);

    const [membershipFields, setMembershipFields] = useState({
        "name": "",
        "description": "",
        "priceUSD": "",
        "priceINR": "",
        "duration": "",
        "status": "",
        "discountPriceUSD": "",
        "trial": ""
    });
    const navigate = useNavigate();
    const ref = useRef();

    const getMemberShipPlan = async () => {

        const headers = { ...config.headers };
        headers['Authorization'] = localStorage.getItem('Admin-Authorization');
        const getResponse = await fetcher.get(apiMapper.GET_MEMBERSHIP, config.host, headers);

        if ([200, 201].includes(getResponse?.status)) {
            setMembershipPlanData(getResponse?.data?.data?.message);
        } else if ([401].includes(getResponse?.status)) {
            localStorage.clear();
            localStorage.clear();
            navigate('/admin/login')
        }

    }
    const saveChangeHandler = async (event) => {
        event.preventDefault();

        const name = event.target.name.value;
        const description = event.target.description.value;
        const priceUSD = event.target.priceUSD.value;
        const duration = event.target.duration.value;
        const status = event.target.status.value;
        const discountPriceUSD = event.target.discountPriceUSD.value;
        let trial;
        if(event.target.trial.value==='true'){
          trial = true
        }else{
            trial = false
        }

        // console.log("trail",trial);
        const headers = { ...config.headers };
        headers['Authorization'] = localStorage.getItem('Admin-Authorization');
        // console.log('headers', headers)

        const responseMember = await fetcher.put(apiMapper.UPDATE_MEMBERSHIP_PLAN + `/${membershipFields.id}`, { ...membershipFields, name, description, priceUSD, duration, status, discountPriceUSD, trial }, config.host, headers);


        if ([200, 201].includes(responseMember?.status)) {

            setMembershipFields({
                "name": "",
                "description": "",
                "priceUSD": "",
                "priceINR": "",
                "duration": "",
                "status": "",
                "discountPriceUSD": "",
                "trial": ""
            })
            await getMemberShipPlan();

            ref.current.click();


        }
        else if (responseMember?.status === 401) {
            localStorage.clear();
            localStorage.clear();
            ref.current.click();
            navigate('/admin/login');
        }
    }




    useEffect(() => {
        const isValid = common.isAdminLoggedIn();
        if (isValid) {

            setMembershipFields({
                "name": "",
                "description": "",
                "priceUSD": "",
                "priceINR": "",
                "duration": "",
                "status": "",
                "discountPriceINR": "",
                "discountPriceUSD": "",
                "trial": ""
            })
            getMemberShipPlan();

        }
        else {
            localStorage.clear();
            localStorage.clear();
            navigate('/admin/login')
        }
    }, [])

    // console.log("getData", membershipPlanData)

    const editHandler = (id, name, description, priceUSD, discountPriceUSD, status, duration, trial) => {
        //   getMemberShipRecord(id);
        setMembershipFields({ ...membershipFields, id, name, description, priceUSD, discountPriceUSD, status, duration, trial });

    }


    return membershipPlanData.length === 0 ? (<><h2>Loding....!!</h2></>) : (<>
        <div className="container-lg mt-3">
            <div className="table-responsive">
                <div className="table-wrapper">
                    <div className="table-title">
                        <div className="row">
                            <div className="col-sm-8"><h2>Membership Details</h2></div>
                            {/* <div className="col-sm-4 text-center">
                        <button  type="button" className="main-btn" data-bs-toggle="modal" data-bs-target="#addNewRuleModal" ><i className="bi bi-plus"></i> Add New</button>
                    </div> */}
                        </div>
                    </div>
                    <table className="table table-bordered mt-2">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Price $USD</th>
                                <th>DiscountPrice $USD</th>
                                <th>Status</th>
                                <th>Duration</th>
                                <th>Trail</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {membershipPlanData?.map((ele, i) => {
                                return (<>
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele?.name}</td>
                                        <td>{ele?.description}</td>
                                        <td>{ele?.priceUSD}</td>
                                        <td>{ele?.discountPriceUSD}</td>
                                        <td>{ele?.status}</td>
                                        <td>{ele?.duration}</td>
                                        <td>{ele?.trial.toString()}</td>
                                        <td>
                                            <a onClick={() => { editHandler(ele._id, ele.name, ele.description, ele.priceUSD, ele.discountPriceUSD, ele.status, ele.duration, ele.trial) }} className="btn px-3 " data-bs-toggle="modal" data-bs-target="#EditMembershipModal" title="Edit" href="#/"><i className="bi bi-pencil"></i></a>
                                        </td>
                                    </tr>
                                </>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* ----modal---- */}
            <div className="modal fade" id="EditMembershipModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> Edit MembershipPlan</h5>
                            <button ref={ref} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <form onSubmit={(e) => { saveChangeHandler(e) }}>
                            <div className="modal-body">

                                <div className="row">
                                    <div className="mb-3 mt-1">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input onChange={(e) => { setMembershipFields({ ...membershipFields, name: e.target.value }) }} value={membershipFields?.name} type="text" className="form-control" id="name" name="name" placeholder="Name" required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 mt-1">
                                        <label htmlFor="description" className="form-label">Description</label>
                                        <input onChange={(e) => { setMembershipFields({ ...membershipFields, description: e.target.value }) }} value={membershipFields?.description} type="text" className="form-control" id="description" name="description" placeholder="Description" required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3">
                                        <label htmlFor="priceUSD" className="form-label">Price $USD</label>
                                        <input onChange={(e) => { setMembershipFields({ ...membershipFields, priceUSD: e.target.value }) }} value={membershipFields?.priceUSD} type="number" className="form-control" id="priceUSD" name="priceUSD" placeholder="PriceUSD" required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3">
                                        <label htmlFor="discountPriceUSD" className="form-label">DiscountPrice $USD</label>
                                        <input onChange={(e) => { setMembershipFields({ ...membershipFields, discountPriceUSD: e.target.value }) }} value={membershipFields?.discountPriceUSD} type="number" className="form-control" id="discountpriceUSD" name="discountPriceUSD" placeholder="discountPriceUSD" required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 ">
                                        <label htmlFor="duration" className="form-label">Duration</label>
                                        <input onChange={(e) => { setMembershipFields({ ...membershipFields, duration: e.target.value }) }} value={membershipFields?.duration} type="number" className="form-control" id="duration" name="duration" placeholder="Duration" required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 ">
                                        <label htmlFor="status" className="form-label">Status</label>
                                        <input onChange={(e) => { setMembershipFields({ ...membershipFields, status: e.target.value }) }} value={membershipFields?.status} type="text" className="form-control" id="status" name="status" placeholder="Status" required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 ">
                                        <label htmlFor="trial" className="form-label">Trial Period</label>
                                        <select className="form-control" onChange={(e)=>{setMembershipFields({...membershipFields, trial: e.target.value})}} value={membershipFields?.trial} id="trial" name="trial">
                                            <option selected value='' disabled>Trail</option>
                                            <option value='true'>True</option>
                                            <option value='false'>False</option>
                                        </select>
                                        {/* <input onChange={(e)=>{setMembershipFields({...membershipFields, trial: e.target.value})}} value={membershipFields?.trial ===true || membershipFields?.trial?.toLocaleLowerCase?.()==='true' } type="text" className="form-control" id="trial" name="trial" placeholder="Trail Period" required /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Update</button>
                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </div>



    </>
    )
}

export default Adminmembership