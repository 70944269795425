
import { useState, useRef, useEffect } from "react";
import fetchFunction from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
// import Registeruserinfo from "./registerUserInfo";
// import Registered from "./registerAdd";
import './css/register.css';
import './css/forgetpassword.css';
import { useNavigate } from "react-router-dom";

const Forgetpassword = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState();
  const [show, setShow] = useState(false);
  const ref = useRef();

  const otp1 = useRef();
  const otp2 = useRef();
  const otp3 = useRef();
  const otp4 = useRef();
  const refFeedback = useRef();

  const otprefArray = [otp1, otp2, otp3, otp4];
  const [password, setpassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      // console.log("setTimeOut Call")
      setShow(false);
    }, 2000);

    return () => clearTimeout(timer);
  });

  const hideAlert = () => {
    setShow(false);
  };

  // const [userInfo, setUserInfo] = useState("")

  // const getUserInfoData = (userInfo) => {
  //   setUserInfo(userInfo);
  // }

  // const selectFormState = (formState) => {
  //   setFormState(formState)

  // }

  const [registrationData, setRegistrationData] = useState({});

  const fetchRegistrationOtp = async (email, resend=false) => {
    let response = null ;
    // console.log('resend', resend);
    if (resend){
      response = await fetchFunction.post(apiMapper.FORGET_PASSWORD_OTP, { email: email, resend });
    } else {
      response = await fetchFunction.post(apiMapper.FORGET_PASSWORD_OTP, { email: email });
    }
    

    if ([200, 201].includes(response?.status)) {
      return response?.data;

    }

  };

  const registrationEmailChecker = async (event) => {

    event.preventDefault();
    const email = event?.target?.userEmail?.value;

    if (email) {
      // setRegistrationData({ ...registrationData, email });
      const result = await fetchRegistrationOtp(email, false);

      // console.log("result", result)
      if (result?.status === "SUCCESS") {
        setRegistrationData({
          ...registrationData,
          email,
          isUserExist: true,
        });
        // setFormState(2);
        alert('otp is sent Please check your Email')
      }
      else {
        // event.target.userEmail.classList.add('is-invalid');
      }
    }
  };

  // const handlerEmail = (event) => {
  //   if (ref.current.className.includes('is-invalid')) {
  //     ref.current.className = ref.current.className.replace('is-invalid');
  //   }
  // }

  const otpVerified = async (event) => {
    
    // event.target.value = event.target.value.replace(/[^0-9]/g, "");
    
    const otp = otprefArray.map((ele) => {
      return ele.current.value
    }).join("");
    if (otp.length === 4) {

      if (registrationData.email && registrationData.isUserExist && (password && confirmpassword && password === confirmpassword)) {
        const payload = { email: registrationData.email, otp, password };

        const getOtp = await fetchFunction.put(apiMapper.FORGET_PASSWORD_VERIFY, payload);
        

        if ([200, 201].includes(getOtp.status) && (getOtp?.data?.status === "SUCCESS")) {
          setRegistrationData({ ...registrationData, isVerified: true });
          setShow(true);

          setTimeout(() => {
            navigate('/');
          }, 2000);

        } else {

          refFeedback.current.className = refFeedback.current.className.replace('d-none', '');

        }

      }
    }


  };



  const handleOtpInput = (event) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, "");
    
    const otpId = parseInt(event?.target?.id?.split('-')?.reverse()[0]);
    if (event.target.value !== '' && otpId !== 4) {
      otprefArray[otpId].current.focus();
    }

    // otprefArray


  }
  const [resendcount, setResendcount] = useState(1);
  const resentOtp = async () => {
    const email = registrationData.email;
    if (resendcount <= 3) {
      const result = await fetchRegistrationOtp(email, true);
      if (result?.status === "SUCCESS") {
        setRegistrationData({
          ...registrationData,
          email,
          isUserExist: true,
        });
        setResendcount(resendcount + 1);
      }

      // 

    }
  }

  return <>

    <div className="container-fluid register-fragment mt-5" style={{ "minHeight": "100vh" }}>
      {/* <div className="row mt-5">
        {show && (
          <div className="alert alert-warning">
            Succefully changed password !!
            <button onClick={hideAlert} className="close-btn">&times;</button>
          </div>)}
      </div> */}
      {show ? <div className="container success-message ">
              <div className="thanks-message text-center" id="text-message"> <img src="https://i.imgur.com/O18mJ1K.png" width="100" alt="message" className="mb-4" />
                <h3>{ "Password changes Successfully"}</h3> <span> You will be redirected to Home screen now!</span>
              </div>
            </div> : <div className="row register-form-control forgetpassword">
        <div className="container-fluid email-verification">
          <div className="row mb-10 "></div>
          <div className="row email-verification-form text-center d-flex justify-content-center align-items-center">
            <div className="col">
              <form onSubmit={registrationEmailChecker}>
                <div className="row">
                  <div className="container d-flex justify-content-center align-items-center">
                    <div className="position-relative" >
                      <div className="d-flex  flex-row forgetpassword-email-input-container justify-content-center">
                        <div className="forgetpassword-email-input">
                          <input
                            // ref={ref}
                            type="email"
                            className="form-control "
                            name="userEmail"
                            placeholder="Enter Email"
                            id="userEmail"
                            aria-describedby="emailHelp"
                            // onChange={handlerEmail}
                            required
                            disabled={!(!registrationData?.isUserExist ||
                              registrationData?.isUserExist === false)}
                          />
                          {/* <div className="invalid-feedback">
                            Email is already Exist.
                          </div> */}
                        </div>
                        <div className="email-verifcation-button  d-flex justify-content-center align-items-center">
                          {(!registrationData?.isUserExist ||
                            registrationData?.isUserExist === false) ? <button type="submit" className="btn btn-primary email-verification-button">
                            Submit
                          </button> : <i className="bi bi-check-square-fill " ></i>}
                        </div>


                      </div>

                    </div>
                  </div>
                  <div className="row">
                    {registrationData?.isUserExist === true &&
                      <div className="container otp-input mt-3 height-100 d-flex justify-content-center align-items-center">
                        <div className="position-relative">
                          <div className="card p-2 text-center">
                            <h6>Please enter the one time password <br /> to verify your account</h6>
                            <div> <span>A code has been sent to</span> <small>*******.com</small> </div>
                            <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
                              <input ref={otp1} className="m-2 text-center form-control rounded" type="text" id="otp-1" maxlength="1" onChange={handleOtpInput} />
                              <input ref={otp2} className="m-2 text-center form-control rounded" type="text" id="otp-2" maxlength="1" onChange={handleOtpInput} />
                              <input ref={otp3} className="m-2 text-center form-control rounded" type="text" id="otp-3" maxlength="1" onChange={handleOtpInput} />
                              <input ref={otp4} className="m-2 text-center form-control rounded" type="text" id="otp-4" maxlength="1" onChange={handleOtpInput} />

                            </div>
                            <div className="email-input mt-3">
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              placeholder="Enter New password"
                              id="password"
                              aria-describedby="passwordHelp"
                              onChange={(e) => { setpassword(e.target.value) }}
                              pattern=".{8,}"  
                              title="Password must be at least 8 characters long"
                              required
                            />

                            </div>
                            <div className="email-input mt-3">
                              <input
                                // ref={ref}
                                type="password"
                                className={"form-control " + ((confirmpassword && confirmpassword !== password) ? "is-invalid" : "")}
                                name="confirmpassword"
                                placeholder="Confirm password"
                                id="confirmpassword"
                                aria-describedby="confirmpasswordHelp"
                                onChange={(e) => { setConfirmpassword(e.target.value) }}
                                minLength="8"
                                required

                              />


                            </div>
                            <div ref={refFeedback} className="invalid-feedback-2 d-none">
                              OTP is not correct.
                            </div>
                            <div className="mt-4"> <a href="#/" className="btn  validate " onClick={otpVerified}>Save</a> </div>
                          </div>
                          <div className="card-2">
                            <div className="content d-flex justify-content-center align-items-center"> <span>Didn't get the code</span> <a
                              href="#/" className="text-decoration-none ms-3" onClick={resentOtp}>Resend({(resendcount - 1)}/3 )</a> </div>
                            {/* {(resendcount <=3 && resendcount!==0 )&& <p>Succefully sent OTP</p>} */}
                            {(resendcount > 3) && <p>OTP limit reached</p>}
                          </div>
                        </div>
                      </div>
                    }

                  </div>
                </div>
              </form>

            </div>
          </div>


        </div>



        {/* {formState === 2 &&
          <Registeruserinfo getUserInfoData={getUserInfoData} selectFormState={selectFormState} registrationData={registrationData} />
        } */}

        {/* {
          formState === 3 &&
          <><Registered userInfo={userInfo} registrationData={registrationData} /></>
        } */}
      </div>}
      
    </div>


  </>



};

export default Forgetpassword


