import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import common from "../utils/common";
import AddListing from './Addlisting';
import './css/register.css';

const SuccessMessage = ({isMembershipEnabled}) => {
    const [isUserExist, setIsUserExist] = useState(false);
    const [formState, setFormState] = useState(3);
    const [showAddListing, setShowAddListing] = useState(false); // Step 1: Add visibility control
    const navigate = useNavigate();

    const linkStyle = {
        fontWeight: 'bold',
        textDecoration: 'underline',
    };

    useEffect(() => {
        const isValid = common.isLoggedIn();
        if (isValid) {
            setIsUserExist(true);
        } else {
            localStorage.clear();
            navigate('/');
        }
    }, [navigate]);

    const toggleAddListingVisibility = () => setShowAddListing(!showAddListing); // Step 2: Toggle function

    return (
        <>
            {isUserExist && (
                <div className="container success-message pt-4 pt-md-0">
                    <div className="thanks-message text-center py-4 my-md-0" id="text-message">
                        <img src="https://i.imgur.com/O18mJ1K.png" width="40" alt="message" className="mt-10 mb-4"/>
                        <h3> "Registration Done!"</h3>  
                    </div>
                    <div className="container-fluid register-fragment mt-20" style={{ minHeight: "100vh" }}>
                        <div className="row register-steps pt-20">
                            <div className="container">
                                <div className="progressbar">
                                    <div className={"progress-step " + (formState === 1 ? "progress-step-active" : "")} data-title="Verification"></div>
                                    <div className={"progress-step " + (formState === 2 ? "progress-step-active" : "")} data-title="User Info"></div>
                                    <div className={"progress-step " + (formState === 3 ? "progress-step-active" : "")} data-title="Add Listing"></div>
                                    {isMembershipEnabled ?<div className={"progress-step " + (formState === 4 ? "progress-step-active" : "")} data-title="Membership"></div>:''}
                                </div>
                                <div className='row linklisting py-3'>
                                    <div className='col-md-4 offset-2 col-4 text-end'>
                                        <Link onClick={toggleAddListingVisibility} style={linkStyle}>
                                                {showAddListing ? 'Hide Listing Now' : 'Add Listing Now'}
                                        </Link>
                                    </div>
                                    <div className='col-md-4  col-4'>
                                        <Link to='/'>
                                            <a href={null} style={linkStyle}>May be Later!</a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showAddListing && <AddListing />} {/* This will only render AddListing if showAddListing is true */}
                    </div>
                </div>
            )}
        </>
    );
};

export default SuccessMessage;
