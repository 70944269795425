import React, {useState, useEffect} from "react";
import { Form, Input, Col, Row, Select, Modal, Button, Typography } from "antd";
import './css/RegisterContact.css';
import fetchFunction from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import { Link } from "react-router-dom";

const { Item } = Form;

const RegisterContact = ({
  setRegisterModalVisible,
  registerModalVisible,
  setRegisterContactSuccess,
  setRegisterContactInfo,
}) => {
  const [form] = Form.useForm();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("")
  const [defaultCountry, setDefaultCountry] = useState("")
  const [phoneValue, setPhoneValue] = useState("");

  const getContactOwnerOtp = async (data) => {
    const contactResponse = await fetchFunction.post(
      apiMapper.CONTACT_REGISTER,
      data
    );
    //
    if ([200, 201].includes(contactResponse?.status)) {
        setRegisterContactSuccess(true);
    }
  };

  async function fetchCountryCode() {
    const result = await fetchFunction.get(apiMapper.GET_COUNTRYCODE);

    if ([200, 201].includes(result.status)) {
      const countriesNameWithFlag = [];

      result.data.data.message.map((e) => {
        const countryCode = '+' + e.phonecode + " " + e.name + " " + e.flag;
        const data = { value: e.phonecode + '-' + e.isoCode, label: countryCode };

        countriesNameWithFlag.push(data);
        const splitDataValue = data.value?.split('-')
        if (splitDataValue[0] === "1" && splitDataValue[1] === "US") {
          setSelectedCountry(data.value);
          setDefaultCountry(data.value)
        }
      });
      setCountries(countriesNameWithFlag);
    } else {
      return <h2>Somthing Went Wrong please retry</h2>;
    }
  }

  const handleOk = async () => {
    const values = await form.validateFields();
    const sendData = {
      name: values.name,
      phone: `+${values.countryCode.split('-')[0]}-${values.phone}-${values.countryCode.split('-')[1]}`, // Combine country code and phone number
      email: values.email,
      contactMessage: values.message,
    };
    setRegisterContactInfo(sendData);
    await getContactOwnerOtp(sendData);
    setPhoneValue("") // reset
    setSelectedCountry(defaultCountry) //reset
    form.resetFields();
    setRegisterModalVisible(false);
  };

  const handleCancel = () => {
    setPhoneValue("") // reset
    setSelectedCountry(defaultCountry) //reset
    form.resetFields();
    setRegisterModalVisible(false);
  };

  useEffect(() => {fetchCountryCode()},[])

  const handleSelectChange = async (value) =>{
    setSelectedCountry(value);
  }

  const handlePhoneChange = (event) => {
    const numericValue = event.target.value.replace(/\D+/g, "");
    form.setFieldValue('phone', numericValue) // setting the form field value of phone
    setPhoneValue(numericValue);
  };
  
  return (
    <Modal
      title={
        <div className="modal-title">
          Send Inquiry
        </div>
      }
      open={registerModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      footer={null} // Remove default footer to customize
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleOk}
        preserve={false}
      >
        <Row gutter={16}>
          <Col span={24}>
            <div className="styled-input-container">
              <label className="styled-input-label">Name</label>
              <Item
                name="name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input className="styled-input" />
              </Item>
            </div>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <div className="styled-input-container">
              <label className="styled-input-label">Country Code</label>
              <Item
                name="countryCode"
                rules={[{ required: true, message: "Please select your country code!" }]}
                initialValue={selectedCountry}
              >
                <Select value={selectedCountry} defaultValue={selectedCountry} onChange={handleSelectChange}>
                    {countries.map(option => (
                     <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}  
                </Select>
              </Item>
            </div>
          </Col>

          <Col span={12}>
            <div className="styled-input-container">
              <label className="styled-input-label">Phone Number</label>
              <Item
                name="phone"
                rules={[{ required: true, message: "Please input your phone number!" }]}
                initialValue={phoneValue}
              >
                <Input value={phoneValue} className="styled-input" minLength={10} maxLength={10} onChange={handlePhoneChange}/>
                {/* p tag is used for render phonevalue */}
                {/* <p style={{display:"none"}}>Current Phone Number:{phoneValue}</p> */}
              </Item>
            </div>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <div className="styled-input-container">
              <label className="styled-input-label">Email</label>
              <Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                    type: "email",
                  },
                ]}
              >
                <Input className="styled-input" />
              </Item>
            </div>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <div className="styled-input-container">
              <label className="styled-input-label">Message</label>
              <Item
                name="message"
                rules={[
                  { required: true, message: "Please input your message!" },
                ]}
              >
                <Input.TextArea rows={4} className="styled-input" />
              </Item>
            </div>
          </Col>
        </Row>
        <div className="style-button-modal-cont">
          <Button type="primary" htmlType="submit">
            Send Response
          </Button>
          <Button type="primary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
        <div className="style-privacy-text-modal-cont">
        <Typography.Text style={{marginTop:".5rem", fontSize:"12px", textAlign:"justify"}} level={5} type="secondary">
        By Sending, you acknowledge that you have read and agree with the <Link to={"/content/termCondition"} target="_blank" rel="noopener noreferrer">EkSarathi.com Terms of Service</Link>, <Link to={"/content/privacy-policy"} target="_blank" rel="noopener noreferrer">Privacy Policy</Link> and Cookie Policy.
        </Typography.Text>
        </div>
      </Form>

    </Modal>
  );
};

export default RegisterContact;
