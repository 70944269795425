import { Link, useNavigate } from "react-router-dom";
import apiMapper from "../../utils/apiMapper";
import fetcher from "../../utils/fetch";
import common from "../../utils/common";
import { useEffect, useState } from "react";


const Adminlogin = () => {
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState(null); // State to manage login error

    useEffect(() => {
        const isValid = common.isAdminLoggedIn();
        if (isValid) {
            navigate('/admin');
            navigate(0);
            alert('You are already logged in to the admin panel')
        }
    }, [])

    const adminHandlerLogin = async (event) => {
        event.preventDefault();
        const email = event.target.email.value;
        const password = event.target.password.value;

        if (email && password) {
            const resp = await fetcher.post(apiMapper.ADMIN_LOGIN, { email, password });

            if ([200, 201].includes(resp?.status)) {
                const respdata = resp?.data?.data;
                const token = respdata?.token;
                if (token) {
                    localStorage.setItem("Admin-Authorization", token);
                    localStorage.setItem("adminEmail", email);
                    localStorage.setItem("adminName", respdata?.name || 'admin');
                    localStorage.setItem("adminProfileImg", respdata?.profileImg || `/images/placeholder/${respdata?.gender}.png`);
                    navigate('/admin');
                    navigate(0);
                } else {
                    setLoginError("Invalid email or password"); // Set login error message
                }
            } else {
                setLoginError("Invalid email or password"); // Set login error message
            }
        }
    }

    return (
        <>
            <div className="vh-100 d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-md-8 col-lg-6">
                            <div className="card bg-white">
                                <h2 className="fw-bold mb-1 text-center">Admin Login</h2>
                                <div className="card-body p-5">
                                    <form onSubmit={adminHandlerLogin}>
                                        <p className="mb-4 text-center">Please enter your login and password!</p>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email address</label>
                                            <input type="email" className="form-control" id="email" name="email" placeholder="Email" required />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <input type="password" className="form-control" id="password" name="password" placeholder="Password" required />
                                        </div>
                                        {loginError && <div className="alert alert-danger" role="alert">{loginError}</div>} {/* Display login error message */}
                                        <div className="d-grid">
                                            <button className="main-btn" type="submit">Login</button>
                                        </div>
                                    </form>
                                    <div className="text-center mt-3">
                                        <Link to="/forgetpassword">Forgot Password?</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Adminlogin;
