import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState, useRef, useCallback } from "react"
import fetcher from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import "../components/css/productpage.css"
import schema from "../utils/schema";
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
// import Registered from "./registerAdd";
import common from "../utils/common";
import Contactpagemodal from "./Contactpagemodal";
import config from "../utils/config"
import Addlisting from "./Addlisting";
import Spinner from "./spinner";
import PageNotFound from "./pageNotfound";
import './css/ownerProduct.css'
import { SEOCommon } from "./SEOCommon";
import RegisterContact from "./registerContact";
import RegisterContactOTP from "./registerContactOtp";
import ShareAndSaveButton from "./ShareAndSaveButton";
import { message } from "antd";

const Ownerproductpage = ({ data, isMembershipEnabled }) => {
    // console.log("data",data);
    const [registeredStatus, setRegisteredStatus] = useState(false)
    const [ownerProductDetails, setOwnerProductDetails] = useState({});
    const [isDescription, setIsdescription] = useState(true);
    const [contactDetails, setContactDetails] = useState({});
    const { ownerId } = useParams();
    const ref = useRef();
    const contactRef = useRef();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [isLoading, setLoading] = useState(true);
    const [contactResCode, setContactResCode] = useState(null)

    // not logged in user 
    const [registerModalVisible, setRegisterModalVisible] = useState(false);
    const [registerContactSuccess, setRegisterContactSuccess] = useState(false);
    const [registerContactInfo, setRegisterContactInfo] = useState({})
    const [registerContactOtpSuccess, setRegisterContactOtpSuccess] = useState(false);

    const descriptionHandler = () => {
        setIsdescription(!isDescription);
    }

    async function getOwnerProductDetails() {
        if (!Object.keys(data || {}).length) {
            const ownerProductresponse = await fetcher.get(apiMapper.OWNER_PRODUCT + ownerId + `?userid=${common.getUserId()}`);

            if (ownerProductresponse?.status === 200 && ownerProductresponse?.data?.data) {
                setOwnerProductDetails(ownerProductresponse?.data?.data);
            }
            // loading will false any way if no data 
            setLoading(false);
        } else {
        //    console.log(data)
            setOwnerProductDetails(data);
            // console.log("ownerData", Object.keys(data))
            // console.log("true/false", !Object.keys(data || {}).length)
        }

    }
//   console.log("ownerProductDetails",ownerProductDetails);
  

    const getContactOwner = useCallback(async () => {
        const headers = { ...config.headers };
        headers['Authorization'] = localStorage.getItem('Authorization');
        const contactResponse = await fetcher.get(apiMapper.GET_CONTACT + `/${ownerId}?addtype=owner`, config.host, headers);
        // 
        if ([200, 201].includes(contactResponse?.status)) {
            setContactDetails(contactResponse?.data?.data);
            setContactResCode(200);
        }
        else if ([429].includes(contactResponse?.status)) {
            setContactDetails({});
            setContactResCode(429);
            message.info(contactResponse?.data?.data.message)
        }
        else if ([401].includes(contactResponse?.status)){
            localStorage.clear()
            setContactResCode(401)
            setContactDetails({});
            navigate(0)
        }

    },[contactResCode])

    const ownerSchema = schema.ownerSchema();
    const arrayFields = [ownerSchema.furnishingTypes, ownerSchema.requiredLease, ownerSchema.rules, ownerSchema.dietType, ownerSchema.amenities, ownerSchema.buildingAmenities]




    useEffect(() => {
        // getContactOwner();
        getOwnerProductDetails();

    }, [])

    useEffect(() => {
        if (contactResCode !== null && contactResCode !== 429 && contactResCode === 200) contactRef?.current?.click();
    }, [contactResCode]) // contactResCode

    useEffect(() => {
        // show modal after otp success
        if(registerContactOtpSuccess === true) contactRef?.current?.click();
    }, [registerContactOtpSuccess])

    // ---amenities---render---
    const housePropertiesBlock = (ele, startIndex, endIndex) => {

        return (ownerProductDetails?.[ele.alias] || []).slice(startIndex, endIndex).map((aliasValue) => {
            return <div className="col-6 owneramenities">
                <div className="form-check form-check-inline pt-2">
                    <div> <img className="icon-class-active" src={(ele?.values || []).filter((subEle) => { if (subEle.value === aliasValue?.[ele?.values?.[0]?.id]) return subEle.img })?.[0]?.img} alt={aliasValue?.[ele?.values?.[0]?.id]}></img></div>
                    <p className={"icon-class-text"}> {ownerSchema[ele.alias].values.filter((e, i) => {
                        if (e.value === aliasValue?.[ele?.values?.[0]?.id]) return true

                    })?.[0]?.meta}</p>

                </div>
            </div>

        })
    }

    const handlerShowMore = (event) => {
        if (event.target.innerText === "show more") {
            event.target.innerText = "show less";
        } else {
            event.target.innerText = "show more";
        }

    }

    //Edit handler
    const editHandler = (data) => {
        // console.log("edithandlercall", data)
         setRegisteredStatus(true);
    }

// Delete Ads
const deleteAdHandler = async(adId)=>{
    // console.log("adId",adId);
   if(adId){
       if(window.confirm( ownerProductDetails.status === 'active' ? 'Are you sure want to disable this advertisment?' :'Are you sure want to enable this advertisment?')){
        const deleteAdResponse = await fetcher.delete(apiMapper.Delete_USER_ADD+adId,{status: ownerProductDetails.status === 'active' ? 'inactive' : 'active'},config.host,common.getHeaders());
        // console.log('deleteAdResponse',deleteAdResponse)
         if([200,201].includes(deleteAdResponse?.status)){
            alert( ownerProductDetails.status === 'active' ? 'Advertisment is successfully disabled!' : 'Advertisment is successfully enabled!');
            navigate(0);
         }else{
            return <h1 className="mt-5">OOPs,Somthing wnet wrong Please Try Later</h1>;
         }
       }
   }
}

    const contactHandler = useCallback(async () => {
        const isValid = common.isLoggedIn();
        if (!isValid) {
            setRegisterModalVisible(true);
            return;
        }
        if(isMembershipEnabled){
            if ((common.isMember() && common.isMember() === 'true') || (ownerProductDetails?.userId?.membershipStatus === true)) {
                await getContactOwner();
                if(contactResCode !== null && contactResCode !== 429 && contactResCode === 200) contactRef?.current?.click();

            } else {
                alert('To access the contact information, either the individual placing the advertisement or the person seeking to make contact must hold a membership.')
                navigate('/membership');
            }
        }
        else{
            await getContactOwner();
            if(contactResCode !== null && contactResCode !== 429 && contactResCode === 200) contactRef?.current?.click();
        }
    },[contactResCode])

    const messageHandler = async () => {
        if(common.isLoggedIn()){
            const payload = {
                "participant": ownerProductDetails.userId._id
            }
            const messageResp = await fetcher.post(apiMapper.CREATE_CHANNEL, payload, config.host, common.getHeaders());
            if([200,201].includes(messageResp?.status)){
                const data = messageResp?.data?.data;
                await localStorage.setItem('defaultChannel', data._id);
                navigate('/message/');
            }
             else{
                alert('Something Wrong try after sometime.')
            }
        } else {
            alert('Login required to send message.');
        }

    }
    
    function capitalizeFirstLetter(string) {
        // Check if the string is null, undefined, or empty after trimming
        if (!string || typeof string !== "string" || string.trim() === "") {
            return ""; // Return an empty string if the input is null, undefined, or empty
        } else {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }

    if(!isLoading && Object.keys(ownerProductDetails).length === 0) return <PageNotFound margin={{marginTop: '4%'}}/>

    return registeredStatus ? (<><Addlisting userAddData={{ userAdType: "tenant", data: data }} /></>) : (!Object.keys(ownerProductDetails).length) ? (<Spinner/>) : (
        <div className="container-fluid min-vh-100  px-0 productpage">
            <div>
                <SEOCommon dynamicData={{
                    description: ownerProductDetails?.description,
                    ogDescription:ownerProductDetails?.description,
                    keywords:ownerProductDetails?.description,
                    ogTitle: 'Eksarathi - Affordable Rooms and Apartments for Rent in the USA',
                    ogImage:ownerProductDetails?.photos.length >= 1 ? "https://www.eksarathi.com/" + ownerProductDetails?.photos[0]: null
                }} />
            </div>
            <div className="container productconatiner">
                <div className="row">
                    <div className="col-md-7 offset-md-1 offset-sm-0 px-md-2 px-0">
                        <div className="productdetails">

                            <div className="row productheading">
                                <div className={isDesktop ? "row lastsection px-3 py-3" : "row lastsection px-3 py-3 pt-4"} ><span>{"Last updated at: " + moment(ownerProductDetails?.createdAt).format("MMM DD, YYYY")}</span></div>
                                <div className="tiitle">
                                {ownerProductDetails?.userType === 'owner' ? 
                                    <h3>
                                        <span>{[
                                                ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'room-shared-1') ? 'Double Sharing Room' : '',
                                                ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'room-shared-2') ? 'Triple Sharing Room' : '',
                                                ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'private-room') ? 'Private Room' : '',
                                                ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'full-house') ? 'Full House' : ''
                                            ].filter(Boolean).join(', ')}
                                            {' '}
                                             {(ownerProductDetails?.userType === 'owner' ? 'On Rent For ' : 'Required For ')}
                                             {capitalizeFirstLetter (ownerProductDetails.offerFor === 'any' ? ('Male/Female') : 
                                             (ownerProductDetails.offerFor))}{ownerProductDetails?.rent === 0 ? "" : " at $" + ownerProductDetails?.rent}
                                             {ownerProductDetails?.location?.address && ' in ' + ownerProductDetails?.location?.address}
                                             {/* {!ownerProductDetails.locality || ownerProductDetails.locality.trim() === "" ? " in" : " in " + ownerProductDetails.locality + ","} {capitalizeFirstLetter(ownerProductDetails?.city?.name)}
                                              , {ownerProductDetails?.city?.stateCode}, {ownerProductDetails?.city?.countryCode} */}
                                        </span>
                                    </h3>
                                : 
                                    <h3>
                                        <span>{[
                                                ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'room-shared-1') ? 'Double Sharing Room' : '',
                                                ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'room-shared-2') ? 'Triple Sharing Room' : '',
                                                ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'private-room') ? 'Private Room' : '',
                                                ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'full-house') ? 'Full House' : ''
                                            ].filter(Boolean).join(', ')}
                                            {' '}
                                             {(ownerProductDetails?.userType === 'owner' ? 'On Rent For ' : 'Required For ')}
                                             {capitalizeFirstLetter (ownerProductDetails.offerFor === 'any' ? ('Male/Female') : 
                                             (ownerProductDetails.offerFor))}{ownerProductDetails?.rent === 0 ? "" : " at $" + ownerProductDetails?.rent}
                                             {ownerProductDetails?.location?.address && ' in ' + ownerProductDetails?.location?.address}
                                             {/* {!ownerProductDetails.locality || ownerProductDetails.locality.trim() === "" ? " in" : " in " + ownerProductDetails.locality + ","} {capitalizeFirstLetter(ownerProductDetails?.city?.name)}
                                              , {ownerProductDetails?.city?.stateCode}, {ownerProductDetails?.city?.countryCode} */}
                                        </span>
                                    </h3>
                                    }
                                </div>
                            </div>
                            <div className="row productheading">
                                <div className="lastsection">
                                    <span className="px-1">{(ownerProductDetails?.userType === 'owner' ? 'Offer For ' : 'Offer By ')}{ownerProductDetails?.offerFor === 'any' ? ('Male/Female') : (ownerProductDetails?.offerFor)} </span>
                                    {ownerProductDetails?.roomTypes?.length > 0 && <div className="inline-block">
                                        <span> | </span>
                                        {[
                                                ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'room-shared-1') ? 'Double Sharing Room' : '',
                                                ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'room-shared-2') ? 'Triple Sharing Room' : '',
                                                ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'private-room') ? 'Private Room' : '',
                                                ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'full-house') ? 'Full House' : ''
                                            ].filter(Boolean).join('/ ')}
                                            {' '}
                                    </div> }
                                </div>
                            </div>
                            <ShareAndSaveButton ownerData={ownerProductDetails}/>
                        </div>

                        {/* ---House Gallary-- */}
                        <div className="productgallary">
                            <div id="carouselExampleIndicators" className="carousel slide">
                                <div className="carousel-indicators">
                                    {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                                    {(ownerProductDetails.photos || []).map((ele,i)=>{
                                        return <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={`${i}`} className={ (i === 0 ? "active" : "")} aria-current="true" aria-label={`Slide ${i+1}`}></button>

                                    })}
                                </div>
                                <div className="carousel-inner">
                                        {/* {/ change in deployment /} */}
                                    {(ownerProductDetails.photos || []).map((ele, i) => {
                                        return <div className={"carousel-item " + (i === 0 ? "active" : "")}>
                                            <img src={ele} className="d-block owner-img-carousel" alt={"image" + i} />
                                        </div>
                                    })}
                                    {/* {(ownerProductDetails.photos || []).map((ele, i) => {
                                    const imageUrl = `http://localhost:6167/${ele.replace(/\\/g, '/')}`;
                                    return (
                                        <div className={"carousel-item " + (i === 0 ? "active" : "")} key={i}>
                                        {console.log("image URL", imageUrl)}
                                            <img src={imageUrl} className="d-block w-100" alt={"image" + i} />
                                        </div>
                                    );
                                })} */}

                                    {/* <div className="carousel-item">
                                        <img src="/images/house/house2.jpg" className="d-block w-100" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="/images/house/house3.jpg" className="d-block w-100" alt="..." />
                                    </div> */}
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>

                        <div className="productoverview" id="property_feature">
                            <div className="propertycard">
                                <div className=" row content">
                                    <div className="rentsecurity px-0">
                                        <div className="heading">
                                            <span>{(ownerProductDetails?.userType === 'owner' ? 'Rent this house at ' : 'Looking for ')}</span>
                                        </div>
                                     {isDesktop && <div className='row px-3'>
                                            <div className="col-md-3 col-6 chargesdetails">
                                                 <div className='labelheading'>
                                                    <span>{(ownerProductDetails?.userType === 'owner' ? 'AVAILABLE FROM ' : 'REQUIRED FROM ')}</span>
                                                 </div>
                                                 <div className="datashow">{moment(ownerProductDetails?.availableFrom).format('ll')}</div>
                                            </div>
                                            <div className="col-md-3 col-6 chargesdetails">
                                                 <div className='labelheading'>ACCOMODATES</div>
                                                 <div className="datashow">{ownerProductDetails?.accomodates || '--'}</div>
                                            </div>
                                            <div className="col-md-3 col-6 chargesdetails">
                                                 <div className='labelheading'>POSTED BY</div>
                                                 <div className="datashow" data-bs-toggle="tooltip" title={ownerProductDetails?.userId?.name}>{(ownerProductDetails?.userId?.name || '').length > 14 ? `${(ownerProductDetails?.userId?.name || '').slice(0, 14)}...` : ownerProductDetails?.userId?.name }</div>
                                            </div>
                                            {/* <div className="col-md-3 col-6 chargesdetails border-start-0">
                                                 <div className='labelheading'>{(ownerProductDetails?.userType === 'owner' ? 'ROOM TYPE ' : 'ROOM TYPE REQUIRED ')}</div>
                                                 <div className="datashow">{[
                                                    ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'room-shared-1') ? 'Double Sharing Room' : '',
                                                    ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'room-shared-2') ? 'Triple Sharing Room' : '',
                                                    ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'private-room') ? 'Private Room' : '',
                                                    ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'full-house') ? 'Full House' : ''
                                                ].filter(Boolean).join(', ')}
                                                {' '}</div>
                                                </div> */}
                                            <div className="col-md-3 col-6 chargesdetails">
                                                 <div className='labelheading'>{(ownerProductDetails?.userType === 'owner' ? 'RENT' : 'EXPECTED RENT ')}</div>
                                                 <div className="datashow"><span className="rent" font>{ownerProductDetails?.rent === 0 ? "Not Decided Yet": "$"+ownerProductDetails?.rent+"/Month"}</span></div>
                                            </div>
                                            {(ownerProductDetails?.userType === 'owner' ?
                                                <div className="col-md-3 col-6 chargesdetails">
                                                    <div className='labelheading'>DEPOSIT</div>
                                                    <div className="datashow"><span>$</span>{ownerProductDetails?.deposit}</div>
                                                </div>
                                                :"")}
                                            <div className="col-md-3 col-6 chargesdetails">
                                                 <div className='labelheading'>ATTACHED BATH</div>
                                                 <div className="datashow">{ownerProductDetails?.attachedBath ? 'Yes':'No'}</div>
                                            </div>
                                            <div className="col-md-3 col-6 chargesdetails">
                                                 <div className='labelheading'>{(ownerProductDetails?.userType === 'owner' ? 'PREFERRED GENDER ' : 'GENDER ')}</div>
                                                 {console.log(ownerProductDetails?.offerFor)}
                                                 <div className="datashow">{ownerProductDetails?.offerFor === 'any' ? ('Male/Female') : (ownerProductDetails?.offerFor || '--')}</div>
                                            </div>
                                            <div className="col-md-3 col-6 chargesdetails">
                                                 <div className='labelheading'>STAY TYPE</div>
                                                 <div className="datashow">{ownerProductDetails?.stayType || '--'}</div>
                                            </div>
                                            <div className="col-md-3 col-6 chargesdetails">
                                                 <div className='labelheading'>{(ownerProductDetails?.userType === 'owner' ? 'ROOM TYPE ' : 'ROOM TYPE REQUIRED ')}</div>
                                                 {ownerProductDetails?.roomTypes?.length > 0 ? <div className="datashow roomType">{[
                                                    ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'room-shared-1') ? 'Double Sharing Room' : '',
                                                    ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'room-shared-2') ? 'Triple Sharing Room' : '',
                                                    ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'private-room') ? 'Private Room' : '',
                                                    ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'full-house') ? 'Full House' : ''
                                                ].filter(Boolean).join(', ')}
                                                {' '}</div>: <div className="datashow">--</div>}
                                            </div>
                                        </div>}
                                     {isMobile && <div className='row px-3'>
                                            <div className="col-md-3 col-6 chargesdetails">
                                                 <div className='labelheading'>
                                                    <span>{(ownerProductDetails?.userType === 'owner' ? 'AVAILABLE FROM ' : 'REQUIRED FROM ')}</span>
                                                 </div>
                                                 <div className="datashow">{moment(ownerProductDetails?.availableFrom).format('ll')}</div>
                                            </div>
                                            <div className="col-md-3 col-6 chargesdetails">
                                                 <div className='labelheading'>ACCOMODATES</div>
                                                 <div className="datashow">{ownerProductDetails?.accomodates || '--'}</div>
                                            </div>
                                            <div className="col-md-3 col-6 chargesdetails">
                                                 <div className='labelheading'>POSTED BY</div>
                                                 <div className="datashow" data-bs-toggle="tooltip" title={ownerProductDetails?.userId?.name}>{(ownerProductDetails?.userId?.name || '').length > 14 ? `${(ownerProductDetails?.userId?.name || '').slice(0, 14)}...` : ownerProductDetails?.userId?.name }</div>
                                            </div>
                                            <div className="col-md-3 col-6 chargesdetails">
                                            <div className='labelheading'>{(ownerProductDetails?.userType === 'owner' ? 'RENT' : 'EXPECTED RENT ')}</div>
                                            <div className="datashow"><span className="rent" font>{ownerProductDetails?.rent === 0 ? "Not Decided Yet": "$"+ownerProductDetails?.rent+"/Month"}</span></div>
                                            </div>
                                            
                                            {(ownerProductDetails?.userType === 'owner' ?
                                                <div className="col-md-3 col-6 chargesdetails">
                                                    <div className='labelheading'>DEPOSIT</div>
                                                    <div className="datashow"><span>$</span>{ownerProductDetails?.deposit}</div>
                                                </div>
                                                :"")}
                                            <div className="col-md-3 col-6 chargesdetails">
                                                 <div className='labelheading'>ATTACHED BATH</div>
                                                 <div className="datashow">{ownerProductDetails?.attachedBath ? 'Yes':'No'}</div>
                                            </div>
                                            <div className="col-md-3 col-6 chargesdetails">
                                            <div className='labelheading'>{(ownerProductDetails?.userType === 'owner' ? 'PREFERRED GENDER ' : 'GENDER ')}</div>
                                                 <div className="datashow">{ownerProductDetails?.offerFor === 'any' ? ('Male/Female') : (ownerProductDetails?.offerFor || '--')}</div>
                                            </div>
                                            <div className="col-md-3 col-6 chargesdetails">
                                                 <div className='labelheading'>STAY TYPE</div>
                                                 <div className="datashow">{ownerProductDetails?.stayType || '--'}</div>
                                            </div>
                                            <div className="col-md-3 col-6 chargesdetails">
                                            <div className='labelheading'>{(ownerProductDetails?.userType === 'owner' ? 'ROOM TYPE ' : 'ROOM TYPE REQUIRED ')}</div>
                                                 {ownerProductDetails?.roomTypes?.length > 0 ? <div className="datashow roomType">{[
                                                    ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'room-shared-1') ? 'Double Sharing Room' : '',
                                                    ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'room-shared-2') ? 'Triple Sharing Room' : '',
                                                    ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'private-room') ? 'Private Room' : '',
                                                    ownerProductDetails?.roomTypes.some(r => r.roomTypes === 'full-house') ? 'Full House' : ''
                                                ].filter(Boolean).join(', ')}
                                                {' '}</div>: <div className="datashow">--</div>}
                                            </div>
                                        </div>}
                                                
                                    </div>
                                </div>
                                
                                {/* ---description-- */}
                                <div className="description">
                                    <div className="row  mb-1">
                                        <div className="heading">Descriptions</div>
                                    </div>

                                    {isDescription ? <div className="row descriptionContent" >
                                        {ownerProductDetails?.description?.slice(0, 180)}

                                    </div> : <div className="row descriptionContent" >
                                        {ownerProductDetails?.description}

                                    </div>
                                    }
                                    {ownerProductDetails?.description?.length > 180 && (<div className="row ">
                                        <a onClick={descriptionHandler} href='#/'><p className="text-end">{isDescription ? "see more" : "see less"}</p></a>
                                    </div>)}
                                    
                                </div>


                                {arrayFields?.map((ele, i) => {
                                    if (ele.alias === "rules") {
                                        // rules
                                        if (!ownerProductDetails?.hasOwnProperty(ele.alias) || ownerProductDetails?.userType !== 'owner' || ownerProductDetails?.[ele.alias].length === 0) return (<></>) 
                                        return (<>
                                            <div className="heading">{ele.meta}</div>
                                            {ownerProductDetails?.rules?.map((ele, index) => (
                                            <div key={index} className="row rules mb-3">
                                                <ul className="list-unstyled">
                                                <li>
                                                    <i className="bi bi-check-lg" style={{ color: "blue" }}></i>
                                                    {ele.name}
                                                </li>
                                                </ul>
                                            </div>
                                            ))}
                                        </>
                                        )
                                    }

                                    if (ele.alias === "requiredLease") {
                                        if (!ownerProductDetails?.hasOwnProperty(ele.alias) || ownerProductDetails?.userType !== 'owner') return (<></>) 
                                        return (<>
                                            <div className="heading">{ele.meta}</div>
                                            <div className="row rules mb-3">
                                            
                                            <ul className="list-unstyled">
                                                <li>
                                                    <i className="bi bi-check-lg" style={{ color: "blue" }}></i>
                                                    {capitalizeFirstLetter(ownerProductDetails?.[ele.alias])}
                                                </li>
                                                </ul>
                                            </div>
                                        </>
                                        )
                                    }

                                    // to remove the empty arrays
                                    if (ownerProductDetails?.hasOwnProperty(ele.alias) && ownerProductDetails?.[ele.alias]?.length === 0) return (<></>) 

                                    return (<>
                                        <div className="houseProperties" key={i}>
                                            <div className="row  pt-2 mb-2">
                                                <div className="heading">{ele.meta}</div>
                                            </div>

                                            <div className="row row-cols-2 propertyName text-center mb-2">
                                                {housePropertiesBlock(ele, 0, 2)}
                                                
                                            </div>

                                            <div id={ele.alias} className="collapse">
                                                <div className="row row-cols-2 propertyName text-center mb-2">
                                                    {housePropertiesBlock(ele, 2, (ownerProductDetails?.[ele.alias] || []).length)}
                                                </div>
                                            </div>
                                            {ownerProductDetails?.[ele.alias].length > 2 && <div className="row text-center">
                                                <a onClick={handlerShowMore} ref={ref} href="#/" data-bs-toggle="collapse" id={ele.values[0].id} data-bs-target={'#' + (ele.alias || '')} aria-expanded="false" aria-controls={ele.alias}>
                                                    show more
                                                </a>
                                            </div>}
                                        </div>
                                    </>)
                                })
                                }

                                {/* <div className="row mb-2 mt-2 Otherdetails">
                                    <div className="heading">
                                        <span>Other Details</span>
                                    </div>
                                    <ul className="list-unstyled">
                                        {ownerProductDetails.operatingSince && <li className="px-4 subHeading">
                                            <div className="row">
                                                <div className="col-6">
                                                    Operating Since
                                                </div>
                                                <div className="col-6">
                                                    <span className="subdataText">{ownerProductDetails.operatingSince}</span>
                                                </div>
                                            </div>
                                        </li>}
                                        {ownerProductDetails?.ownerType && <li className="px-4 subHeading">
                                            <div className="row">
                                                <div className="col-6">
                                                    Owner Type
                                                </div>
                                                <div className="col-6 ">
                                                    <span className="subdataText">{ownerProductDetails?.ownerType}</span>
                                                </div>
                                            </div>
                                        </li>}
                                        {ownerProductDetails.pgName && <li className="px-4 subHeading">
                                            <div className="row">
                                                <div className="col-6">
                                                    Pg Name
                                                </div>
                                                <div className="col-6 ">
                                                    <span className="subdataText">{ownerProductDetails.pgName}</span>
                                                </div>
                                            </div>
                                        </li>}
                                        {ownerProductDetails.numberBed && <li className="px-4 subHeading">
                                            <div className="row">
                                                <div className="col-6">
                                                    Number Of Bed
                                                </div>
                                                <div className="col-6 ">
                                                    <span className="subdataText">{ownerProductDetails.numberBed}</span>
                                                </div>
                                            </div>
                                        </li>}
                                        {ownerProductDetails?.offerFor && <li className="px-4 subHeading">
                                            <div className="row">
                                                <div className="col-6">
                                                    Offer For
                                                </div>
                                                <div className="col-6 ">
                                                    <span className="subdataText">{ownerProductDetails?.offerFor}</span>
                                                </div>
                                            </div>
                                        </li>}
                                    </ul>
                                </div> */}


                                {/* -----contact us for mobile ------   */}
                                {(!Object.keys(data || {}).length) ? (isMobile && <div className="contactUsmobile fixeedPstionmobile">
                                    <div className="row">
                                        <div className="col-6 ">
                                            <div className="row  mb-4 mt-4 mx-3">
                                                <button className="main-btn" onClick={contactHandler}>Contact</button>
                                                <button  ref={contactRef} className="d-none" data-bs-toggle="modal" data-bs-target="#exampleModal1">dummyButton</button>
                                            </div>
                                        </div>
                                        <div className="col-6 " >
                                            <div className="row  mb-4 mt-4 mx-3">
                                                <button className="main-btn " onClick={messageHandler}>Message</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>) : (isMobile && <div className="contactUsmobile fixeedPstionmobile">
                                    <div className="row">
                                        <div className="col-6 ">
                                            <div className="row mb-2 mt-2 mx-3">
                                                <button className="main-btn mobile-edit-button" onClick={() => editHandler(data)}>Edit</button>
                                            </div>
                                        </div>
                                        <div className="col-6 ">
                                            <div className="row mb-2 mt-2 mx-3">
                                                <button className="main-btn mobile-edit-button" onClick={()=>deleteAdHandler(ownerProductDetails?._id)}>{ownerProductDetails.status === 'active' ?  'Disable Advertisment' : 'Enable Advertisment' }</button>
                                            </div>
                                        </div>
                                    </div>      
                                </div>)}


                            </div>
                        </div>
                    </div>

                    {/* Contact Modal */}
                    {!common.isLoggedIn() ?
                        <RegisterContact
                        setRegisterModalVisible={setRegisterModalVisible}
                        registerModalVisible={registerModalVisible}
                        setRegisterContactSuccess={setRegisterContactSuccess}
                        setRegisterContactInfo={setRegisterContactInfo}/> :
                        <Contactpagemodal contactDetailsData={contactDetails} />}

                    {registerContactSuccess && <RegisterContactOTP 
                        setRegisterContactSuccess={setRegisterContactSuccess}
                        registerContactSuccess={registerContactSuccess}
                        registerContactInfo={registerContactInfo}
                        setContactDetails={setContactDetails}
                        setRegisterContactOtpSuccess={setRegisterContactOtpSuccess}
                        ownerProductDetails={ownerProductDetails}
                    />}
                    {registerContactOtpSuccess && <Contactpagemodal contactDetailsData={contactDetails}/>}

                    
                    {/* ----contatct us ----- */}
                    {isDesktop && <div className="col-md-2 px-md-2 product">

                        <div className="ownerContactus">
                            <div className="row  contactusHeading">
                                <span>{(ownerProductDetails?.userType === null ? '' : capitalizeFirstLetter(ownerProductDetails?.userType) + " Details")}</span>
                            </div>
                            <hr />
                            <div className="row mb-2">
                                <div className="contactimagesection">
                                    <img className="contactimg" src="/images/ownercontactus.png" alt="ownercontactid" />
                                </div>
                            </div>
                            <div className="row title" pt-2>
                                <span data-bs-toggle="tooltip" title={ownerProductDetails?.userId?.name}>By {(ownerProductDetails?.userId?.name || '').length > 22 ? `${(ownerProductDetails?.userId?.name || '').slice(0, 22)}...` : ownerProductDetails?.userId?.name }</span>
                                <p className="subtext">{ownerProductDetails?.location?.address} </p>
                            </div>
                            {(!Object.keys(data || {}).length) ? (<>

                                <div className="row   mb-4 mt-4 mx-3">
                                    <button className="main-btn" onClick={contactHandler} >Get Contact Info</button>
                                    <button  ref={contactRef} className="d-none" data-bs-toggle="modal" data-bs-target="#exampleModal1">dummyButton</button>
                                </div>

                                <div className="row  mb-4 mt-4 mx-3">
                                    <button className="main-btn" onClick={messageHandler}>Message Advertiser</button>
                                </div></>) : (<>
                                    <div className="row   mb-4 mt-4 mx-3">
                                        <button className="main-btn" onClick={() => editHandler(data)}>Edit</button>
                                    </div>
                                    <div className="row   mb-4  mx-3">
                                        <button className="main-btn" onClick={()=>deleteAdHandler(ownerProductDetails?._id)}>{ownerProductDetails.status === 'active' ?  'Disable Advertisment' : 'Enable Advertisment' }</button>
                                    </div>
                                    </>)}
                            <div className="row  shedualAvilable mt-3 mx-2" style={{marginBottom:-12}}>
                                <div className="col-2 p-3 ">
                                    <i className="bi bi-calendar" style={{ "font-size": "180%", color: "blue" }} ></i>
                                </div>
                                <div className="col-8 p-3">
                                    <div className="row p-1 calender-title ">
                                        <span>Contact soon</span>
                                    </div>

                                </div>
                            </div>
                            <div className="row shedualvisit mx-2">
                                <div className="col-2 p-3 ">
                                    <i className="bi bi-stopwatch" style={{ "font-size": "180%", color: "blue" }} ></i>
                                </div>
                                <div className="col-8 p-3">
                                    <div className="row p-1 visit-title ">
                                        <span>Most of the listing likely fill out in very first week.</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>}



                </div>

            </div>
        </div>

    )
}


export default Ownerproductpage 
