import React, { useState, useRef, useEffect } from "react";
import fetchFunction from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import Select from "react-select";
import "./css/registerUserInfo.css";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { addSlash, removeHostUrl } from "../utils/helper";


const customStyles = {
  control: base => ({
    ...base,
    height: 50,
    minHeight: 50,

  })
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Registeruserinfo = ({registrationData}) => {


  const [userData, setUserData] = useState({
    name: "",
    phone: "",
    gender: "",
    password: "",
    age: "",
    email:"",
    // userType:""
  });
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const passwordRef = useRef(null);

  //get api htmlFor country code

  async function fetchCountryCode() {
    const result = await fetchFunction.get(apiMapper.GET_COUNTRYCODE);

    if ([200, 201].includes(result.status)) {
      const countriesNameWithFlag = [];
      const defaultCountry = [];

      result.data.data.message.map((e) => {
        const countryCode = '+' + e.phonecode.replace('+', '').replace('-', '') + " " + e.name + " " + e.flag;
        const data = { value: e.phonecode + '-' + e.isoCode, label: countryCode };
        const phonecode = { value: e.phonecode + '-' + e.isoCode, label: countryCode };

        countriesNameWithFlag.push(data);
        const splitDataValue = data.value?.split('-')
        if (splitDataValue[0] === "1" && splitDataValue[1] === "US") defaultCountry.push(phonecode);
      });
      setCountries(countriesNameWithFlag);
      setSelectedCountry(defaultCountry?.[0] || {});
    } else {
      return <h2>Somthing Went Wrong please retry</h2>;
    }
  }

  useEffect(() => {
    fetchCountryCode();
  }, []);

  useEffect(() => {
    setUserData({ ...userData, phonecode: selectedCountry.value });
  }, [selectedCountry]);

  // file upload code
  const OnSubmitregisteruserHandle = async (event) => {
    event.preventDefault();

    const name = event.target.name.value;
    const phone = event.target.phone.value;
    const gender = event.target.gender.value;
    const password = event.target.password.value;
    const age = event.target.age.value;
    const email = registrationData?.email;
    // const userType = event.target.userType.value;
    const registerInfoData = {
      ...userData,
      name,
      phone,
      gender,
      password,
      age,
      email,
      // userType
    };
    const phonecode = registerInfoData?.phonecode.split('-');

    registerInfoData["phone"] = `+${phonecode[0] || ""}-${registerInfoData.phone || ""}-${phonecode[1] || ""}`;
    // console.log("registerInfoData", registerInfoData)
  
   const  registerationResponse = await fetchFunction.post(apiMapper.REGISTER,registerInfoData);
  //  console.log("registerationResponse",registerationResponse)
  if ([200, 201].includes(registerationResponse?.status) && registerationResponse?.data?.status === "SUCCESS") {
    setLoading(true);
    setUserData(registerInfoData);
    const token = registerationResponse?.data?.data?.token;
    if (token) {
      localStorage.setItem("Authorization", token);
      localStorage.setItem("name", registerInfoData.name);
      localStorage.setItem("email", registerInfoData.email);
      localStorage.setItem("profileImg", registerInfoData.profileImg);
      localStorage.setItem("gender", registerInfoData.gender);
      // localStorage.setItem("userType", registerInfoData.userType);
    }
    setTimeout(() => {
      navigate("/successmessage/listing");
      navigate(0);
    }, 2000);
  } else {
    setLoading(false);
  }
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([

    // {
    //   uid: '-2',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-3',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-4',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-xxx',
    //   percent: 50,
    //   name: 'image.png',
    //   status: 'uploading',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-5',
    //   name: 'image.png',
    //   status: 'error',
    // },
  ]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = (event) => {

    // setFileList(newFileList)

  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8}} >Upload</div>
    </div>
  );

  const handleFileUpload = async (event) => {

    const file = event.file?.originFileObj;

    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      // make a POST request
      const uploadResponse = await fetchFunction.upLoadFile(
        apiMapper.UPLOAD_FILE,
        formData
      );
      if ([200, 201].includes(uploadResponse?.status)) {
        const image = uploadResponse.data.files[0];


        const imagePriviewData = {
          uid: image.split('/').reverse()[0].split('.')[0],
          name: image.split('/').reverse()[0],
          status: 'done',
          url: removeHostUrl(image),
        };

        setFileList([...fileList, imagePriviewData]);
        setUserData({ ...userData, profileImg: image });
      }
    } else if (event?.file?.status === 'removed') {
      const imageUrl = removeHostUrl(event?.file?.url, true);
      const deletePayload = { "files": [imageUrl] }
      const deleteImageResp = await fetchFunction.delete(apiMapper.UPLOAD_FILE, deletePayload);
      if (deleteImageResp?.status === 200) {
        setFileList(fileList.filter((e) => {
          if (e.url !== addSlash(imageUrl)) return true;
        }))
      }

    }


  };

  useEffect(() => {
    // console.log('fileList', fileList)
  }, [fileList])

  const passwordViewhandler = () => {
    if (passwordRef.current.type === "password") {
      passwordRef.current.type = "text";
    } else {
      passwordRef.current.type = "password";
    }

  }

  return (
    <>
      <div className="container mt-5 mb-5 d-flex justify-content-center align-items-center">
        <div className="position-relative" style={{ width: (isDesktop ? "40%" : "100%") }}>
          <form className="" onSubmit={OnSubmitregisteruserHandle}>
            <div className=" group-inputs ">
              <label htmlFor="name" className="">
                Name
              </label>
              <input type="text" className="form-control" id="name" required />
            </div>
            <div className="row mt-3">

              <div className="col-6 ">
                <Select
                  options={countries}
                  value={selectedCountry}
                  id="countrycode"
                  styles={customStyles}
                  // defaultValue={selectedCountry}
                  onChange={(selectedOption) =>
                    setSelectedCountry(selectedOption)
                  }
                />
              </div>


              <div className="col-6 ">
                <div className="group-inputs">
                  <label htmlFor="phone" className="">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="phone"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="group-inputs mt-3">
                  <label htmlFor="gender">Gender</label>
                  <select id="gender" className="form-control" required>
                    <option key={"male"} value={"male"}>
                      Male
                    </option>
                    <option key={"female"} value={"female"}>
                      Female
                    </option>
                    <option key={"other"} value={"other"}>
                      Other
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="group-inputs mt-3">
                  <label htmlFor="age">Age</label>
                  <input
                    type="number"
                    className="form-control"
                    id="age"
                    min="18"
                    max="110"
                  />
                </div>
              </div>
            </div>
        {/* <div className="row">
              <div className="col-md-12">
                <div className="group-inputs mt-3">
                  <label htmlFor="userType">Type</label>
                  <select id="userType" className="form-control" required>
                    <option key={"tenant"} value={"tenant"}>
                      Tenant
                    </option>
                    <option key={"owner"} value={"owner"}>
                      Owner
                    </option>
                  </select>
                </div>
              </div>
            </div> */}

            <div className=" row mt-3 ">
              <div className="col-10 pe-0">
                <div className="group-inputs">
                  <label htmlFor="password">Password</label>
                  <input
                    ref={passwordRef}
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    required
                    minLength="8"
                  />
                  
                </div>
                
              </div>
              <div className="col-2 ps-0">
              <span class="input-group-btn">
                    <button class="btn btn-default reveal border w-100 h-100 border-start-0 rounded-start-0" type="button" onClick={passwordViewhandler}><i class="bi bi-eye"></i></button>
              </span>
              </div>


            </div>

            {/* <div className="form-group d-flex flex-row mt-3">
              <label htmlFor="addphoto" className="form-label">
                Add Photo
              </label>
              <Upload
                // action= {`${config.host}${apiMapper.UPLOAD_FILE}`}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleFileUpload}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </div> */}

            <button type="submit" className="main-btn mt-3">
              Register
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Registeruserinfo;
