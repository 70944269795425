
 export const removeStyleShow = () =>{

        let navBar = document.querySelectorAll('.nav-link');
        let navCollapse = document.querySelector('.navbar-collapse.collapse');
        navBar.forEach(function(a){
            a.addEventListener("click", function(){
                navCollapse.classList.remove("show");
            })
        })
    }


export const forAddress = (inputString) =>{
    return inputString.replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ','-');
};

export const removeHostUrl = (url, isDelete = false) =>{
    if(process.env.NODE_ENV === "development") return url
    const amazonUrl = url?.split('amazonaws.com/').reverse()[0]
    if(isDelete && amazonUrl[0] === "/") return amazonUrl.replace('/', '');
    if(isDelete) return amazonUrl
    if(amazonUrl[0] === "/") return amazonUrl
    return '/' + amazonUrl;
}

export const addSlash = (url) =>{
    if(process.env.NODE_ENV === "development") return url
    if(url[0] === "/") return url
    return '/' + url;
}

export function maskEmail(email) {
    if (!email) return null
    const [localPart, domain] = email?.split('@');
    
    const startLength = 1;
    const endLength = 3;
    const maskedLength = localPart.length - startLength - endLength;
  
    const maskedLocalPart = maskedLength > 0 
      ? localPart.slice(0, startLength) + '*'.repeat(maskedLength) + localPart.slice(-endLength)
      : localPart;
    return `${maskedLocalPart}@${domain}`;
}
  
