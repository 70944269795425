import { useState, useRef, useEffect } from "react";
import fetchFunction from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import Registeruserinfo from "./registerUserInfo";
import Registered from "./registerAdd";
import utils from "../utils/common";
import './css/register.css';
import Addlisting from "./Addlisting";
import { useNavigate } from "react-router-dom";

const Register = ({isMembershipEnabled}) => {

  const navigate = useNavigate();
  const [formState, setFormState] = useState(1);
  const ref = useRef();

  const otp1 = useRef();
  const otp2 = useRef();
  const otp3 = useRef();
  const otp4 = useRef();
  const refFeedback = useRef();

  const otprefArray = [otp1, otp2, otp3, otp4];

  useEffect(()=>{

    if(utils.isLoggedIn()){
      navigate('/');
      alert('Already logged in');
    }
  }, [])

 

  const [registrationData, setRegistrationData] = useState({});

  const fetchRegistrationOtp = async (email, resend= false) => {
    let response  = null;
    if (resend){
      response = await fetchFunction.post(apiMapper.REGISTRATION_OTP, {
        email, resend
      });
    } else {
      response = await fetchFunction.post(apiMapper.REGISTRATION_OTP, {
        email,
      });
    }
    

    if ([200, 201].includes(response?.status)) {
      return response.data;
    } 

  } ;

  const registrationEmailChecker = async (event) => {
    event.preventDefault();
    const email = event?.target?.userEmail?.value;
    if (email) {
      // setRegistrationData({ ...registrationData, email });
      const result = await fetchRegistrationOtp(email);
      if (result?.status === "SUCCESS") {
        setRegistrationData({
          ...registrationData,
          email,
          isUserExist: true,
        });
        // setFormState(2);
      }
      else {  
        event.target.userEmail.classList.add('is-invalid');
      }
    }
  };

  const handlerEmail = (event)=> {
    if (ref.current.className.includes('is-invalid')){
      ref.current.className = ref.current.className.replace('is-invalid');
    }
  }

  const otpVerified = async (event) => {
    // event.target.value = event.target.value.replace(/[^0-9]/g, "");
    
    const otp = otprefArray.map((ele)=>{
      return ele.current.value
    }).join("");
    if (otp.length === 4) {

      if (registrationData.email && registrationData.isUserExist) {
        const payload = { email: registrationData.email, otp };

        const getOtp = await fetchFunction.post(apiMapper.OTP_VERIFY, payload);
        
        
        if ([200, 201].includes(getOtp?.status)&&(getOtp?.data?.status==="SUCCESS")){
          setRegistrationData({...registrationData,isVerified:true});
          setFormState(2);
        }else{
          refFeedback.current.className = refFeedback.current.className.replace('d-none', '');
            
        }
      
      }
    }

    
  };

  const handleOtpInput = (event)=> {
    event.target.value = event.target.value.replace(/[^0-9]/g, "");
    
    const otpId = parseInt(event?.target?.id?.split('-')?.reverse()[0]);
    if(event.target.value !== '' && otpId !== 4 ){
      otprefArray[otpId].current.focus();
    }
    
    // otprefArray

    
  }
  const [resendcount, setResendcount] = useState(1);
  const resentOtp = async()=>{
    const email = registrationData.email;
    if(resendcount<=3){
      const result = await fetchRegistrationOtp(email, true);
      if (result.status === "SUCCESS") {
        setRegistrationData({
          ...registrationData,
          email,
          isUserExist: true,
        });
        setResendcount(resendcount+1);
        }
    
      // 
      // setFormState(2);
    }
  }

  return <>

    <div className="container-fluid register-fragment mt-5" style={{"minHeight": "100vh"}}>
      <div className="row register-steps  pt-5">
        <div className="container">
          <div className="progressbar">
              <div className={ isMembershipEnabled ? "progressMember" : "progress"} id="progress"></div>

              <div className={"progress-step "+  (formState ===1 ? " progress-step-active": "")} data-title="Verification"></div>

              <div className={"progress-step "+  (formState ===2 ? " progress-step-active": "")} data-title="User Info"></div>
              <div className={"progress-step "+  (formState ===3 ? " progress-step-active": "")} data-title="Add Listing"></div>
              {isMembershipEnabled ?<div className={"progress-step "+  (formState ===4 ? " progress-step-active": "")} data-title="Membership"></div> : ''}
              {/* <div className={"progress-step "+  (formState ===3 ? " progress-step-active": "")} data-title="Advertisement"></div> */}
          </div>
         
        </div>
        

      </div>
      <div className="row register-form-control ">
        {formState === 1 &&
          <>
            <div className="container-fluid email-verification">

              
              <div className="row mb-10 "></div>
              <div className="row email-verification-form text-center d-flex justify-content-center align-items-center">
                  <div className="col">
                      <form onSubmit={registrationEmailChecker}>
                          <div className="row">
                            <div className="container d-flex justify-content-center align-items-center">
                              <div className="position-relative" >
                                <div className="d-flex flex-row register-email-input-container justify-content-center">
                                 <div className="register-email-input">
                                  <input
                                      ref= {ref}
                                      type="email"
                                      className="form-control "
                                      name="userEmail"
                                      placeholder="Enter Email"
                                      id="userEmail"
                                      aria-describedby="emailHelp"
                                      onChange={handlerEmail}
                                      required
                                      disabled={!(!registrationData?.isUserExist ||
                                        registrationData?.isUserExist === false)}
                                    />
                                    <div className="invalid-feedback">
                                      Email is already Exist.
                                    </div>
                                 </div>
                                  
                                  <div className="email-verifcation-button  d-flex justify-content-center align-items-center">
                                    {(!registrationData?.isUserExist ||
                                      registrationData?.isUserExist === false) ? <button type="submit" className="btn btn-primary email-verification-button">
                                      Submit
                                      </button> : <i className="bi bi-check-square-fill " ></i>}
                                  </div>
                  
                                  
                                </div>
                                
                              </div>
                            </div>
                            <div className="row">
                            {registrationData?.isUserExist === true &&
                              <div className="container otp-input mt-3 height-100 d-flex justify-content-center align-items-center">
                              <div className="position-relative">
                                  <div className="card p-2 text-center">
                                      <h6>Please enter the one time password <br /> to verify your account</h6>
                                      <div> <span>A code has been sent to</span> <small>*******.com</small> </div>
                                      <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
                                        <input ref={otp1} className="m-2 text-center form-control rounded" type="text" id="otp-1" maxlength="1" onChange={handleOtpInput}/> 
                                        <input ref={otp2} className="m-2 text-center form-control rounded" type="text" id="otp-2" maxlength="1" onChange={handleOtpInput}/> 
                                        <input ref={otp3} className="m-2 text-center form-control rounded" type="text" id="otp-3" maxlength="1" onChange={handleOtpInput}/>
                                        <input ref={otp4} className="m-2 text-center form-control rounded" type="text" id="otp-4" maxlength="1" onChange={handleOtpInput}/>
                                        
                                      </div>
                                      <div ref={refFeedback} className="invalid-feedback-2 d-none">
                                        OTP is not correct.
                                      </div>
                                      <div className="mt-4"> <button className="btn  validate " onClick={otpVerified}>Validate</button> </div>
                                  </div>
                                  <div className="card-2">
                                      <div className="content d-flex justify-content-center align-items-center"> <span>Didn't get the code</span> <a
                                              href="#/" className="text-decoration-none ms-3" onClick={resentOtp}>Resend({(resendcount-1)}/3 )</a> </div>
                                              {/* {(resendcount <=3 && resendcount!==0 )&& <p>Succefully sent OTP</p>} */}
                                              {(resendcount >3 )&& <p>OTP limit reached</p>}
                                  </div>
                              </div>
                            </div>
                            }
                          
                          </div>
                          </div>
                      </form>
                      
                  </div>
              </div>
      

            </div>
          </>
        }
      
        { formState === 2 &&
          <Registeruserinfo  registrationData={registrationData}/>
        }
        { formState === 3 &&
          <Addlisting/>
        }

      </div>
    </div>
  
    
  </>
  
  

};

export default Register;