import { useState, useEffect, useRef } from "react"
import common from "../../utils/common"
import fetcher from "../../utils/fetch"
import config from "../../utils/config"
import { useNavigate } from "react-router-dom"
import apiMapper from "../../utils/apiMapper"

const AdminUserControls = () => {

  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const [searchEmailInput, setSearchEmailInput] = useState("");
  const [designation, setDesignation] = useState({ value: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const limit = 5

  const getAllUser = async (email = '') => {
    // console.log("emial", email)
    const headers = { ...config.headers };
    headers['Authorization'] = localStorage.getItem('Admin-Authorization');
    const getResponse = await fetcher.get(apiMapper.GET_ALL_USER + `?page=${currentPage - 1}&limit=${limit}&email=${email}&designation=${designation.value}`, config.host, headers);
    // console.log("getresponse", getResponse);
    const count = getResponse?.data?.totalCount;
    // console.log("count",count);
    if ([200, 201].includes(getResponse?.status)) {
      setTotalCount(count);
      setUserData(getResponse?.data?.data);

    } else if ([401].includes(getResponse?.status)) {
      localStorage.clear();
      localStorage.clear();
      navigate('/admin/login')
    }

  }




  useEffect(() => {
    const isValid = common.isAdminLoggedIn();
    if (isValid) {

      getAllUser();

    }
    else {
      localStorage.clear();
      localStorage.clear();
      navigate('/admin/login')
    }
  }, [currentPage, designation])


  const adminDesignationfunction = async (email, role) => {
    // console.log({ email: email, role: role })
    const headers = { ...config.headers };
    headers['Authorization'] = localStorage.getItem('Admin-Authorization');

    const resonseDesignation = await fetcher.put(apiMapper.UPDATE_USER_ADMIN, { email: email, adminStatus: role }, config.host, headers);
    //  console.log("reasonDesignation",resonseDesignation)
    if ([200, 201].includes(resonseDesignation?.status)) {

      await getAllUser();
    }

    else if (resonseDesignation?.status === 401) {
        alert("you will Not Allowed For This Section");
    }
  }

  const AdminHandler = (email, adminStatus) => {

    adminDesignationfunction(email, adminStatus);
  }

  // search Filter
  const searchByEmail = (searchValue) => {

    // console.log("searchValue", searchValue);
    // console.log("SearchEmailInput", searchEmailInput);
    if (searchValue !== '') {

      getAllUser(searchValue.trim().toLocaleLowerCase());

    } else {
      getAllUser();

    }
  }

  // designation Filter
  const designationChange = (event) => {

    setDesignation({ ...designation, value: event.target.value });
  }

  if (!userData) {
    return null
  }

  return (<>
    <div className="container-lg mt-3">
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-6"><h2>User Details</h2></div>

            </div>
          </div>

          <table className="table table-bordered mt-2">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th className="text-center">
                  Email
                  <input type="text" onChange={(e) => { setSearchEmailInput(e.target.value) }} onBlur={(e) => searchByEmail(e.target.value)} value={searchEmailInput} className="form-control" id="search-emial" placeholder="serach..." />
                </th>
                <th>Phone</th>
                <th>Age</th>
                <th>Gender</th>
                <th className="text-center">
                  Designation
                  <select class="btn btn-light" onChange={designationChange} value={designation?.value}>
                    <option selected value=''>No-Filter</option>
                    <option value='User'>User</option>
                    <option value='Admin'>Admin</option>
                  </select>
                </th>
                {/* <th>ProfileImg</th> */}
                {/* <th>UserType</th> */}
                <th>Membership Status</th>
                <button type="button" class="btn btn-primary">Make Admin</button>
              </tr>
            </thead>
            <tbody>
              {userData?.map((ele, i) => {
                return userData.length === 0 ? (<><h2> Loading.......</h2></>) : (<>
                  <tr key={ele?.userId?._id}>
                    <td>{(i + 1) + (currentPage - 1) * limit}</td>
                    <td>{ele?.name}</td>
                    <td>{ele?.email}</td>
                    <td>{ele?.phone}</td>
                    <td>{ele?.age}</td>
                    <td>{ele?.gender}</td>
                    <td>{ele?.designation}</td>
                    {/* <td>{ele?.profileImg}</td> */}
                    {/* <td>{ele?.userType}</td> */}
                    <td>{ele?.membershipStatus.toString()}</td>
                    <td>
                      {/* {console.log("designation", ele?.designation === "User")} */}
                      <button onClick={() => { AdminHandler(ele?.email, (ele?.designation === "User")) }} 
                      className={ele?.designation === "User" ? "btn btn-danger" : "btn btn-primary"}>{ele?.designation === "User" ? 
                      "Make Admin" : "Make User"}</button>

                    </td>

                  </tr>
                </>)
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* ---pagination----- */}
      <div className="row">
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <a className={"page-link " + ((currentPage <= 1 || userData.length === 0) ? 'disabled' : '')} href="#/" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>

              </a>
            </li>
            {totalCount > 0 && [...Array(Math.ceil(totalCount / limit)).keys()].slice(0, 3).filter((pgNumber, i) => {

              if (pgNumber + currentPage < Math.ceil(totalCount / limit) + 1) {
                return true;
              }


            }).map((pgNumber) => {
              return <li key={pgNumber+currentPage}
                className={`page-item ${currentPage === pgNumber + currentPage ? 'active' : ''} `} >
                <a onClick={() => setCurrentPage(pgNumber+currentPage)}
                  className='page-link'
                  href='#/'>
                  {pgNumber+currentPage}
                </a>
              </li>
            })}

            <li className="page-item">
              <a className={"page-link " + ((userData?.length < limit || userData.length === 0) ? 'disabled' : '')} href="#/" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

    </div>
  </>
  )
}

export default AdminUserControls



