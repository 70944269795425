import { useState } from "react";
import "./css/contactus.css"
import fetchFunction from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useMediaQuery } from "react-responsive";


const Contactus = () => {
    const navigate = useNavigate();
    const [onSuccess, setOnSuccess] = useState(false);
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const handlerCotactSubmit = async (event) => {
        event.preventDefault();
        const data = {};
        data['email'] = event.target.email.value;
        data['subject'] = event.target.subject.value;
        data['content'] = event.target.content.value;
        data['name'] = event.target.name.value;
        data['phone'] = event.target.phone.value;

        const resp = await fetchFunction.post(apiMapper.CONTACT_US, data);
        // console.log('resp', resp);
        if ([200, 201].includes(resp?.status)) {
            setOnSuccess(true);
            setTimeout(() => {
                navigate('/');
            }, 2000);
        } else {
            alert('something Wrong Please try after sometime!')
        }

    }
    return (
        <>
        <div>
            <Helmet>
                {/* Meta tags for SEO */}
                <meta name="description" content="Contact Eksarathi for any inquiries or assistance. Reach out to us regarding room and apartment rentals, affordable housing, or any other questions you may have." />
                <meta name="keywords" content="contact Eksarathi, room for rent inquiries, apartment rental assistance, affordable housing questions, Eksarathi customer support" />
                <meta name="author" content="Eksarathi" />

                {/* Open Graph meta tags for social media sharing */}
                <meta property="og:title" content="Contact Eksarathi for Inquiries and Assistance" />
                <meta property="og:description" content="Reach out to Eksarathi for room and apartment rentals, affordable housing, and any other questions you may have." />
                <meta property="og:image" content="url-to-your-image.jpg" />
                <meta property="og:url" content="https://www.eksarathi.com/contact-us" />
                <meta property="og:type" content="website" />

                {/* Twitter Card meta tags for Twitter sharing */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Contact Eksarathi for Inquiries and Assistance" />
                <meta name="twitter:description" content="Reach out to Eksarathi for room and apartment rentals, affordable housing, and any other questions you may have." />
                <meta name="twitter:image" content="url-to-your-image.jpg" />

                {/* Page title */}
                <title>Contact Eksarathi - Inquiries and Assistance</title>
            </Helmet>
            {/* Your component content */}
        </div>
            <div className="contact-us" style={{ 'min-height': "60vh" }}>
                {onSuccess ? <div className="container success-message ">
                    <div className="thanks-message text-center" id="text-message"> <img src="https://i.imgur.com/O18mJ1K.png" width="100" alt="message" className="mb-4" />
                        <h3>{"Query Registered Successfully"}</h3> <span> You will be redirected to Home screen now!</span>
                    </div>
                </div> : <div className="container-fluid min-vh-100 contactUs">
                    <div className="container contactcontainer">
                        <div className={isDesktop ? "row contactdisplay subContainer":"contactdisplay"}>
                            <div className="row text-center pt-5 py-md-2 ">
                                    <h1 className="heading" style={{color:"black"}}>Contact Us</h1>   
                            </div>
                            <div className={isDesktop ? "col-8 col-md-6 pb-3" : "col-10 col-md-6 pb-3"}>

                                <form onSubmit={handlerCotactSubmit}>
                                    <div className="row">
                                        <div className="offset-1">
                                            <div className="group-inputs">
                                                <label htmlFor="email">Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    name="email"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="offset-1">
                                            <div className="group-inputs">
                                                <label htmlFor="subject">Subject</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="subject"
                                                    name="subject"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="offset-1">
                                            <div className="group-inputs">
                                                <label htmlFor="subject">Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    name="name"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="offset-1">
                                            <div className="group-inputs">
                                                <label htmlFor="subject">Phone</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="phone"
                                                    name="phone"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row mt-3 mb-3">
                                        <div className="offset-1">
                                            <div className="group-inputs">
                                                <label htmlFor="description">Description</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    id="content"
                                                    name="content"
                                                    required
                                                    rows="4"
                                                    cols="50"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="offset-1 submotbutton">
                                            <button type="submit" className="main-btn">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {isDesktop && <div className="col-md-2">
                                <div className="verticalline">
                                </div>
                            </div>}
                          
                             
                            
                            <div className="col-12 col-md-4"  style={{alignItems:"center"}}>
                                <div>
                                {/* <h2>Adreess</h2>
                                <p> Ek Sarathi
                                    2505 Durango Dr,
                                    Carrollton, TX,
                                    75010, USA </p> */}
                                </div>
                                                             
                                <div>
                                    {/* <h2>Call Us On</h2> */}
                                    <p style={{paddingLeft:"-100px",color:'var(--primary-color)', fontWeight:"bolder"}}>USA : +1 (469) 803-0743 </p>
                                    <a href="https://twitter.com/eksarathi?s=11&t=LJ9r9vkghCdThorz60BVQQ" style={{color:'var(--primary-color)', fontWeight:"bolder", display: 'flex', alignItems: 'center'}}>
                                        <img className="" src="/images/whatsapp-business.svg" alt="Whatsapp Icon" style={{ width: '25px', height: '25px', marginRight: '10px' }} />
                                        <a href="https://wa.me/message/KLREQ5PMOT5HH1"><span style={{color:'var(--primary-color)'}}>:+1 (469) 803-0743</span></a>
                                    </a>
                                    <p style={{color:'var(--primary-color)',fontWeight:"-moz-initial", marginTop:"15px"}}><b>E-mail: </b>
                                         <a href="mailto:support@EkSarathi.com" style={{fontSize:"14px", fontWeight:"bold",color:'var(--primary-color) !important'}}>support@EkSarathi.com</a>
                                    </p>
                                </div>
     
                            </div>
                        </div>

                    </div>

                </div>

                }
            </div>
        </>
    )
}

export default Contactus