import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import common from "../../utils/common";
import apiMapper from "../../utils/apiMapper";
import fetcher from "../../utils/fetch";
import config from "../../utils/config";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const AdContent = () => {

  const [getContent, setContent] = useState([]);
  const [type, setType] = useState("add");
  const [formstate, setFormstate] = useState({
    "title": "",
    "contentDescription": "",
    "slug": ""
  });
  const ref = useRef();
  const navigate = useNavigate();

  const [contentDescription, setContentDescription] =  useState("");

  async function fetchContent() {
    const headers = { ...config.headers };
    headers['Authorization'] = localStorage.getItem('Admin-Authorization');
    
    const responseAdContent = await fetcher.get(apiMapper.GETALL_CONTENT + `?status=active`, config.host, headers);
    // console.log("response", responseAdContent)

    if ([200, 201].includes(responseAdContent.status)) {
      setContent(responseAdContent?.data?.data);
    }

    else if ([401].includes(responseAdContent.status)) {
      localStorage.clear();
      localStorage.clear();
      navigate('/admin/login')
    } else {
      localStorage.clear();
      localStorage.clear();
      navigate('/admin/login')
    }
  }

  useEffect(() => {
    const isValid = common.isAdminLoggedIn();
    if (isValid) {

      setFormstate({
        "contentDescription": "",
        "title": "",
        "slug": ""
      })
      fetchContent();

    }
    else {
      localStorage.clear();
      localStorage.clear();
      navigate('/admin/login')
    }
  }, [])


  // Add Content
  // on saveChanges new Rules
  const saveChangeHandler = async (event, type = 'add') => {
    event.preventDefault();
    

    const title = event.target.title.value;
    const content = contentDescription;
    const slug = event.target.slug.value;

    
    const headers = { ...config.headers };
    headers['Authorization'] = localStorage.getItem('Admin-Authorization');

    let responseNewContent = null;
    if (type === "add") {
      responseNewContent = await fetcher.post(apiMapper.CREATE_CONTENT, { title: title, contentDescription: content, slug: slug }, config.host, headers);
    } else {
      // console.log('id', formstate.id, type)
      responseNewContent = await fetcher.put(apiMapper.UPDATE_CONTENT , {_id:formstate.id ,title: title,contentDescription: content, slug }, config.host, headers);

    }


    if ([200, 201].includes(responseNewContent?.status)) {

      setFormstate({
        "title": "",
        "contentDescription": "",
        "slug": ""
      });
      await fetchContent();

      ref.current.click();


    } else if ([400].includes(responseNewContent?.status)) {
      return (<h2>Rule All ready Present </h2>)
    }
    else if (responseNewContent.status === 401) {
      localStorage.clear();
      localStorage.clear();
      ref.current.click();
      navigate('/admin/login');
    }
  }

  //Edit handler
  const editHandler = (id, title, contentDescription, slug) => {
    
    // console.log({ ...formstate, id, title, contentDescription, slug })
    setFormstate({ ...formstate, id, title, contentDescription, slug });
    setContentDescription(contentDescription);
    setType("edit");

  }

  useEffect(()=>{console.log('formstate', formstate)},[formstate])

  const handleChange = (html)=> {
    // console.log('html b')
    setContentDescription(html);
  	// setFormstate({...formstate, contentDescription: html });
  }

  //Delete handler 

  const deleteHandler = async (id) => {

    const headers = { ...config.headers };
    headers['Authorization'] = localStorage.getItem('Admin-Authorization');
    const deleteRuleResp = await fetcher.delete(apiMapper.DELETE_CONTENT + `/${id}`, {}, config.host, headers);

    if ([200, 201].includes(deleteRuleResp.status)) {
      fetchContent();
    }


  }

  
  const EditorModules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
      {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }
  /* 
  * Quill editor formats
  * See https://quilljs.com/docs/formats/
  */
  const Editorformats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]

  /* 
  * PropType validation
  */
  // const EditorpropTypes = {
  //   placeholder: PropTypes.string,
  // }

  const popupCreateEdit = (type = "add") => {
    return <div className="modal fade" id="addNewRuleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{type === "add" ? "Added Rule" : "Edit Rule"}</h5>
            <button ref={ref} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <form onSubmit={(e) => { saveChangeHandler(e, type) }}>
            <div className="modal-body">
              <div className="row">
                <div className="mb-3 mt-3">
                  <div className="mb-3">
                    <input onChange={(e) => { setFormstate({ ...formstate, title: e.target.value }) }}
                      value={formstate.title} type="text" className="form-control" id="title" name="title" placeholder="title" required />
                  </div>
                  <div className="mb-3">
                    {/* <input  onChange={(e) => { setFormstate({ ...formstate, contentDescription: e.target.value }) }}
                      value={formstate.contentDescription} type="text" className="form-control" id="contentDescription"
                       name="contentDescription" placeholder="Content" required /> */}
                       <ReactQuill 
                        theme="snow"
                        onChange={handleChange}
                        value={contentDescription}
                        modules={EditorModules}
                        formats={Editorformats}
                        // bounds={'.app'}
                        // placeholder={this.props.placeholder}
                      />
                  </div>
                  <div className="mb-3">
                    <input onChange={(e) => { setFormstate({ ...formstate, slug: e.target.value }) }}
                      value={formstate.slug} type="text" className="form-control" id="slug" name="slug" placeholder=" slug" required />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="submit" className="btn btn-primary">{type === "add" ? "Create" : "Update"}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  }
  return (
    <>
      <div className="container-lg mt-3">
        <div className="table-responsive">
          <div className="table-wrapper">
            <div className="table-title">
              <div className="row">
                <div className="col-sm-8"><h2>Content Details</h2></div>
                <div className="col-sm-4 text-center">
                  <button type="button" className="main-btn" data-bs-toggle="modal" data-bs-target="#addNewRuleModal" ><i className="bi bi-plus"></i> Add New</button>
                </div>
              </div>
            </div>
            <table className="table table-bordered mt-2">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Title</th>
                  <th>Content Description</th>
                  <th>Status</th>
                  <th>Slug</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {getContent.map((ele, i) => {
                  return (<>
                    <tr>
                      <td>{i + 1}</td>
                      <td>{ele.title}</td>
                      <td>{ele.contentDescription.slice(0,30)}</td>
                      <td>{ele.status}</td>
                      <td>{ele.slug}</td>
                      <td>
                        <a onClick={() => { editHandler(ele._id, ele.title, ele.contentDescription, ele.slug) }} className="btn px-3 " data-bs-toggle="modal" data-bs-target="#addNewRuleModal" title="Edit" href="#/"><i className="bi bi-pencil"></i></a>
                        <a className="px-3" title="Delete" href="#/" onClick={() => deleteHandler(ele._id)}><i className="bi bi-trash"></i></a>
                      </td>
                    </tr>
                  </>)
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* ----Modal---- */}
      {popupCreateEdit(type)}
    </>
  )
}
export default AdContent