

const Spinner = ()=> {
    return <div className="  container " style={{"height":"80vh"}}>
            <div className="h-100 row pt-5 pb-5  text-center  " >
            <div className=" h-100 col-5"></div>
            <div className=" h-100 col-2 d-flex align-items-center" >
                <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            <div className="col-5"></div>
            </div>
        </div>
}

export default Spinner;