import React, { useCallback, useState } from 'react';
import { message, Tooltip } from 'antd';
import "./css/ShareAndSaveButton.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as faBookmark2 } from '@fortawesome/free-regular-svg-icons';
import config from '../utils/config';
import common from '../utils/common';
import fetchFunction from '../utils/fetch';
import apiMapper from '../utils/apiMapper';
import { useNavigate } from 'react-router-dom';

const ShareAndSaveButton = ({ownerData, IsFavorite}) => {
  const [isSaved, setIsSaved] = useState(ownerData?.favorite || false);
  const [isFavoriteProgress, setIsFavoriteProgress] = useState(false)
  const adId = ownerData?._id
  const navigate = useNavigate()
  const metaTitleTag = document.querySelector('meta[property="og:title"]');
  async function copyToClipboard(text) {
      try {
          await navigator.clipboard.writeText(text);
          message.info("Share url copied successfully!")
      } catch (e) {
          console.error(e.message);
          message.error("Share url not copied successfully!")
      }
  }
  const handleShare = async () => {
    let url;
    if (ownerData?.userType === 'owner') url = config.appUrl + common.ownerPath + ownerData?._id;
    else url = config.appUrl + common.tenantPath + ownerData?._id;
    if (url && navigator.canShare) {
      try {
        const shareData = {url}
        if (metaTitleTag?.content) shareData['title'] = metaTitleTag.content;
        await navigator.share(shareData)
        message.info('Shared successfully!');
      }
      catch(e){
        console.error(e.message);
        message.error("Share not successful!");
      }
    }
    else{
      await copyToClipboard(url)
    }
  };

  const handleDelete = async () => {
    const url = apiMapper.CREATE_DELETE_FAVORITE + adId
    const deleteAdResponse = await fetchFunction.delete(url,{}, config.host, common.getHeaders());
    if([200,201].includes(deleteAdResponse?.status)){
      message.info('Removed from favorite!');
      setIsSaved(!isSaved);
      if (IsFavorite) navigate(0)
    }
    else{
      message.error('Something went wrong while removing favorite');
    }
  }

  const handleSave = useCallback(async () => {
    const url = apiMapper.CREATE_DELETE_FAVORITE;
    // Check if the user is logged in
    if (!common.isLoggedIn()) {
      message.info("Please login to save as favorite");
      return;
    }

    // Set progress state before making any requests
    setIsFavoriteProgress(true);

    try {
      if (isSaved) {
        await handleDelete();
      } else {
        const createResp = await fetchFunction.post(
          url,
          { adId },
          config.host,
          common.getHeaders()
        );

        if ([200, 201].includes(createResp?.status)) {
          message.info("Saved to favorite!");
          setIsSaved(!isSaved);
        } else if ([401].includes(createResp?.status)) {
          message.info("Please login to save as favorite");
          localStorage.clear();
        } else {
          message.error("Something went wrong while saving favorite");
        }
      }
    } catch (error) {
      message.error("An error occurred while processing your request");
    } finally {
      setIsFavoriteProgress(false);
    }
  }, [isSaved, adId]);

  return (
    <div className='share-container'>
      {IsFavorite ? 
      <Tooltip title='Delete favorite'>
        <button className='share-icon-Button' onClick={handleDelete}>
        <FontAwesomeIcon className='share-icon-size' icon={faTrash}/>
        </button>
      </Tooltip>: <Tooltip title={isSaved ? 'Remove from favorite' : 'Save to favorite'}>
        <button className='share-icon-Button' onClick={handleSave}>
        {isSaved ? <FontAwesomeIcon className='share-icon-size' icon={faBookmark}/>:
        <FontAwesomeIcon className='share-icon-size' icon={faBookmark2}/>}
        </button>
      </Tooltip>}
      <Tooltip title="Share">
        <button className='share-icon-Button' onClick={handleShare}>
        <i className="bi bi-share share-icon-size"></i>
        </button>
      </Tooltip>
    </div>
  );
};


export default ShareAndSaveButton;
