import React, { useEffect, useState } from 'react';
import './css/geomap.css'
import { AutoComplete, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

const GeoMap = ({ geoDataHandler, userEditData, showMap=true, searchHandler=null, enterSearchHandler }) => {
    const [selected, setSelected] = useState({
        lat: 35.125801,
        lng: -117.9859038
    });

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_API_KEY,
        libraries: ["places"],
    });

    useEffect(() => {
        if (Object.keys(userEditData || {}).length) {
            if (userEditData?.location?.lat) setSelected(
                {
                    lat: parseFloat(userEditData?.location?.lat),
                    lng: parseFloat(userEditData?.location?.lng)
                }
            )
        }
    }, [userEditData]);

    const handler = async (value, option) => {
        const results = await getGeocode({ address: value });
        const { lat, lng } = await getLatLng(results[0]);
        setSelected({ lat, lng });
        if (geoDataHandler) geoDataHandler(value, lat, lng);
    };

    // Dynamically adjust container style based on screen size
    const containerStyle = {
        marginTop: "6%",
        width: '100%',
        height: '400px'
    };

    return (isLoaded) ? (
        <>
            <div className={showMap ? 'map-inputbox' : 'no-map-inputbox'}>
                <div className={showMap ? 'places-container' : 'no-map-inputbox'}>
                    <PlacesAutoComplete userEditData={userEditData} handler={handler} required searchHandler={searchHandler} enterSearchHandler={enterSearchHandler}/>
                </div>

               {showMap && <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={selected}
                    zoom={10}
                >
                    {selected && <Marker position={selected} />}
                </GoogleMap>}
            </div>
        </>
    ) : 
    <></>;
}

const PlacesAutoComplete = ({ userEditData, handler, searchHandler =null, enterSearchHandler = null }) => {
    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();
    const [isInputEmpty, setIsInputEmpty] = useState(true);

    const handleInputChange = (e) => {
        setValue(e.target.value);
        setIsInputEmpty(e.target.value.trim() === '');
    };


    return (
        <>
            <AutoComplete
                popupMatchSelectWidth={252}
                style={{
                    width: "100%",
                    height: "100%"
                }}
                options={status === "OK" && data.map(({ place_id, description }) => {
                    return {
                        place_id: place_id,
                        value: description,
                        label: (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <span>
                                    {description}
                                </span>
                            </div>
                        ),
                    }
                })}
                onSelect={(value, option) => { handler(value, option); clearSuggestions(); }}
                onSearch={(value) => { setValue(value) }}
                defaultValue={value || userEditData?.location?.address}
                size="large"
                id='address'
                disabled={!ready}
            >
                {
                !searchHandler ?
                <Input
                    size="large"
                    placeholder="Please Enter your Location"
                    name='address'
                    required
                    onChange={handleInputChange}
                /> :
                <Input.Search
                    size="large"
                    placeholder="Please Enter your Location"
                    name='address'
                    required
                    onChange={handleInputChange}
                    onSearch={searchHandler}
                    onPressEnter={enterSearchHandler}
                    enterButton={<Button type="primary">Find</Button>}
                    prefix={<SearchOutlined />}
                />
                }
            </AutoComplete>
        </>
    );
}

export default GeoMap;
