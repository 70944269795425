import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import "../components/css/distanceSearch.css";

const DistanceSearch = ({ combinedFilter, IsMobile, setCombinedFilter }) => {
  const [distance, setDistance] = useState(combinedFilter.distance);


  const debouncedRangeChange = useCallback(
    debounce((value) => {
      if (setCombinedFilter && value < 30) {
        setCombinedFilter(prev => ({ ...prev, distance: value, filters: { ...prev.filters, distance: Number.parseInt(value) } }));
      }
      else{
        setCombinedFilter(prev => ({ ...prev, distance: Number.parseInt(value) }));
      }
    }, 300), // 300ms debounce delay
    []
  );
  
  useEffect(() => {
    debouncedRangeChange(distance);
  }, [distance, debouncedRangeChange]);

  const handleRangeChange = (e) => {
    setDistance(e.target.value);
  };

  useEffect(() => {
    // reset Filter
    if(Object.keys(combinedFilter.filters).length === 0){
        setDistance(30)
    }
  }, [combinedFilter.filters])

  return (
    <>
    <div className='distanceSearch'>
      <input
        type="range"
        min="0"
        max="30"
        value={distance}
        onChange={handleRangeChange}
        className='distanceSearchInput'
      />
      <div className='distance-selected-text'>Selected Range: {distance} Miles</div>
    </div>
    <div className={IsMobile ? 'seperationBorderMobile' : 'seperationBorder'}></div>
    </>
  );
};

export default DistanceSearch;
