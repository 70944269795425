import { useState, useEffect } from "react"
import common from "../../utils/common"
import fetcher from "../../utils/fetch"
import config from "../../utils/config"
import { useNavigate } from "react-router-dom"
import apiMapper from "../../utils/apiMapper"
import moment from "moment";

const AdminOrder = () => {

  const [OrderData, setOrderData] = useState([]);
  const [selectTrail, setSelectStatus] = useState({ value: '' });
  const [selectOrderStatus, setSelectOrderStatus] = useState({ value: '' });
  const [totalCount, setTotalCount] = useState(1);
  const navigate = useNavigate();
  const limit = 5
  const [currentPage, setCurrentPage] = useState(1);
 
  const getAllOrders = async () => {

    let url = `?orderStatus=${selectOrderStatus.value}&isTrial=${selectTrail.value}&page=${currentPage - 1}&limit=${limit}`
    const headers = { ...config.headers };
    headers['Authorization'] = localStorage.getItem('Admin-Authorization');
    const getResponse = await fetcher.get(apiMapper.GET_ALL_ORDER + url, config.host, headers);
    const count = getResponse?.data?.totalCount;
    if ([200, 201].includes(getResponse?.status)) {
      setTotalCount(count);
      setOrderData(getResponse?.data?.data);

    } else if ([401].includes(getResponse?.status)) {
      localStorage.clear();
      localStorage.clear();
      navigate('/admin/login')
    }

  }

// console.log("oder data",OrderData);


  useEffect(() => {
    const isValid = common.isAdminLoggedIn();
    if (isValid) {

      getAllOrders();


    }
    else {
      localStorage.clear();
      localStorage.clear();
      navigate('/admin/login')
    }
  }, [currentPage, selectTrail, selectOrderStatus])


  const statushandleChange = (event) => {
   

    setSelectStatus({ ...selectTrail, value: event.target.value });
  };



  const orderhandleChange = (event) => {
    setSelectOrderStatus({ ...selectOrderStatus, value: event.target.value });
  };






  return OrderData.length === 0 ? (<><h2>No Record Found .......</h2></>) : (<>
    <div className="container-lg mt-3">
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-6"><h2>Order Details</h2></div>
              <div className="col-sm-3 px-5">
                <select class="main-btn" onChange={statushandleChange} value={selectTrail?.value}>
                  <option selected value=''>Trail</option>
                  <option value='true'>True</option>
                  <option value='false'>False</option>
                </select>
              </div>
              <div className="col-sm-3 px-5" >
                <select class="main-btn" onChange={orderhandleChange} value={selectOrderStatus?.value}>
                  <option selected value=''>Select-Order-Status</option>
                  <option value="PENDING">Pending</option>
                  <option value="SUCCESS">Success</option>
                  <option value="FAILED">Failed</option>
                </select>
              </div>
            </div>
          </div>

          <table className="table table-bordered mt-2">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>UserName</th>
                <th>Email</th>
                <th>Membership Status</th>
                <th>Membership Plan</th>
                <th>ReferenceId</th>
                <th>Trail Status</th>
                <th>Status</th>
                <th>OrderStatus</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {OrderData?.map((ele, i) => {
                return (<>
                  <tr key={ele?.userId?._id}>
                    <td>{(i + 1) + (currentPage - 1) * limit}</td>
                    <td>{ele?.userId?.name}</td>
                    <td>{ele?.userId?.email}</td>
                    <td>{ele?.userId?.membershipStatus?.toString()}</td>
                    <td>{ele?.membership?.name}</td>
                    <td>{ele?.referenceId}</td>
                    <td>{ele?.isTrial?.toString()}</td>
                    <td>{ele?.status}</td>
                    <td>{ele?.orderStatus}</td>
                    <td>{moment(ele?.updatedAt).format("LLL")}</td>
                  </tr>
                </>)
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* ---pagination----- */}
      <div className="row">
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <a className={"page-link " + ((currentPage <= 1 || OrderData.length === 0) ? 'disabled' : '')} href="#/" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>

              </a>
            </li>
            {totalCount > 0 && [...Array(Math.ceil(totalCount / limit)).keys()].slice(0, 3).filter((pgNumber, i) => {

              if (pgNumber + currentPage < Math.ceil(totalCount / limit) + 1) {
                return true;
              }


            }).map((pgNumber) => {
              return <li key={pgNumber + currentPage}
                className={`page-item ${currentPage === pgNumber + currentPage ? 'active' : ''} `} >
                <a onClick={() => setCurrentPage(pgNumber + currentPage)}
                  className='page-link'
                  href='#/'>
                  {pgNumber + currentPage}
                </a>
              </li>
            })}

            <li className="page-item">
              <a className={"page-link " + ((OrderData?.length < limit || OrderData.length === 0) ? 'disabled' : '')} href="#/" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </>
  )
}

export default AdminOrder