// src/SearchBar.js
import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import './css/SearchBarNew.css';
import GeoMap from './geomap';

const { TabPane } = Tabs;

const SearchBarNew = ({geoDataHandler, searchType, setSearchType, searchHandler, enterSearchHandler}) => {
  const changeSearchType = (e) =>{
    if(e === '1'){
      // find-rooms
      setSearchType('room')
    }
    else if(e === '2'){
      // find-tenants
      setSearchType('tenant')
    }
  }
  // console.log(searchType)
  return (
    <div className="search-bar-container">
      <Tabs defaultActiveKey="1" onChange={changeSearchType}>
        <TabPane tab={<span className="custom-tab">Find Room</span>} key="1" >
          <div className="search-input-container">
          <GeoMap 
            geoDataHandler={geoDataHandler}
            userEditData={""} 
            showMap={false}
            id="req"
            searchHandler={(e)=> searchHandler(searchType)}
            enterSearchHandler={(e)=> enterSearchHandler(e, searchType)}
          />
          </div>
        </TabPane>
        <TabPane tab={<span className="custom-tab">Find Tenant</span>} key="2">
          <div className="search-input-container">
          <GeoMap 
            geoDataHandler={geoDataHandler}
            userEditData={""} 
            showMap={false}
            id="req"
            searchHandler={(e)=> searchHandler(searchType)}
            enterSearchHandler={(e)=> enterSearchHandler(e, searchType)}
          />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SearchBarNew;
