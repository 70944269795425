
const ownerTypeInputBoxSchema = ['roomDeposit', 'roomRent'];
const schema = {

  ownerSchema: () => {
    return {
      city: { type: String, "id": "city" },
      locality: { type: String, "id": "locality" },
      profesion: { type: String, "id": "profesion" },
      organisation: { type: String, "id": "organisation" },
      // pgName: { type: String, "id": "pgName" },
      // operatingSince: { type: String, "id": "operatingSince" },
      // numberBed: { type: String, "id": "numberBed" },
      rent:{ type: String, "id": "rent" },
      deposit:{ type: String, "id": "deposit" },
      availableFrom: {type: String,"id":"availableFrom"},
      accomodates: {type: String,"id":"accomodates"},
      attachedBath: {type: String,"id":"attachedBath"},
      stayType: {type: String, alias:"stayType", values:[
        {"value":'long',"id":`stayType`,"meta":"Long"}, 
        {"value":'short',"id":`stayType`,"meta":"Short"},{"value":'any',"id":`stayType`,"meta":"Any"}]},
      ownerType:  { type: String, id: "ownerType", values: [ {value: "owner", id: 'ownerType', meta: "Owner"},{value: "tenant", id: 'ownerType', meta: "Tenant"}] },
      roomTypes: {
        type: Array, alias: "roomTypes", meta: "Room Type", values: [
          { "value": "room-shared-2",  "id": `roomTypes`, "meta": "3 Sharing", "img": "/images/img/icons/double-bed.svg" },
          { "value": "room-shared-1",  "id": `roomTypes`, "meta": "2 Sharing", "img": "/images/img/icons/bed1.svg" },
          { "value": "private-room",  "id": `roomTypes`, "meta": "Private Room", "img": "/images/img/icons/neighbour.svg" },
          { "value": "full-house", "id": `roomTypes`, "meta": "Full House", "img": "/images/img/icons/house.svg" },
          // { "value": "room/house-for-a-day",  "id": `roomTypes`, "meta": "Room/House For a day", "img": "/images/img/icons/sofa.svg" },
        ]
      },
      requiredLease: {
        type: String, alias: 'requiredLease', meta: "Lease Required", values: [
          { "value": "yes", "id": `requiredLease`, "meta": "Yes" },
          { "value": "no", "id": `requiredLease`, "meta": "No" },
        ]
      },
      foodServices: {
        type: Array, alias: 'foodServices', meta: "Food Service", values: [
          { "value": "breakfast", "id": "foodService", "meta": "Breakfast", "img": "/images/img/icons/lunch.png" },
          { "value": "lunch", "id": "foodService", "meta": "Lunch", "img": "/images/img/icons/lunch.png" },
          { "value": "dinner", "id": "foodService", "meta": "Dinner", "img": "/images/img/icons/lunch.png" }
        ]
      },
     
      furnishingTypes: {
        type: Array, alias: 'furnishingTypes', meta: "Furnishing Types", values: [
          { "value": "full-furnished", "id": `furnishingType`, "meta": "Fully Furnished", "img": "/images/img/icons/double-bed.svg" },
          { "value": "semi-furnished", "id": `furnishingType`, "meta": "Semi Furnished", "img": "/images/img/icons/double-bed.svg" },
          { "value": "unfurnished", "id": `furnishingType`, "meta": "Unfurnished", "img": "/images/img/icons/double-bed.svg" },

        ]
      },
      rules: {
        type: Array, alias: 'rules', meta:"Rules",values: [
          { "value": "Documents Proof Required", "id": `rule`, "meta": "Documents Proof Required" },
          { "value": "No Restriction on Entry", "id": `rule`, "meta": "No Restriction on Entry" },
          { "value": "Guest Not Allowed", "id": `rule`, "meta": "Guest Not Allowed" },
          { "value": "Drinking Not Allowed", "id": `rule`, "meta": "Drinking Not Allowed" },
          { "value": "Owner Not On Premises", "id": `rule`, "meta": "Owner Not On Premises" },
        ]
      },
      offerFor: {
        type: String, alias: 'offerFor', values: [
          { "value": "male", "id": `offerFor`, "meta": "Male Only" },
          { "value": "female", "id": `offerFor`, "meta": "Female Only" },
          { "value": "any", "id": `offerFor`, "meta": "Any" },
        ]
      },

      dietType: {
        type: Array, alias: 'dietType', meta: "Diet Type", values: [
          { "value": "Vegetarian Only", "id": `dietType`, "meta": "Vegetarian Only", "img": "/images/img/icons/Vegeterian.svg" },
          { "value": "Non-Vegetarian", "id": `dietType`, "meta": "Both (Flexible)", "img": "/images/img/icons/Vegeterian.svg" },
        ]
      },
      amenities: {
        type: Array, alias: 'amenities', meta: 'Amenities', values: [
          { "value": "sofa", "id": `amenity`, "meta": "Sofa", "img": "/images/img/icons/sofa.svg" },
          { "value": "microwave", "id": `amenity`, "meta": "Microwave", "img": "/images/img/icons/microwave.svg" },
          { "value": "gyser", "id": `amenity`, "meta": "Water Heater", "img": "/images/img/icons/water-heater.svg" },
          { "value": "gas-stove", "id": `amenity`, "meta": "Kitchen Stove", "img": "/images/img/icons/gas-stove.svg" },
          { "value": "mattress", "id": `amenity`, "meta": "Mattress", "img": "/images/img/icons/mattress.svg" },
          { "value": "cupboard", "id": `amenity`, "meta": "Cupboard", "img": "/images/img/icons/cupboard.svg" },
          { "value": "wi-fi", "id": `amenity`, "meta": "Wi-fi", "img": "/images/img/icons/wi-fi.svg" },
          { "value": "tv", "id": `amenity`, "meta": "TV", "img": "/images/img/icons/tv.svg" },
          { "value": "utensils", "id": `amenity`, "meta": "Utensils", "img": "/images/img/icons/utensils.svg" },
          { "value": "fridge", "id": `amenity`, "meta": "Fridge", "img": "/images/img/icons/fridge.svg" },
          { "value": "bed", "id": `amenity`, "meta": "Bed", "img": "/images/img/icons/bed.svg" },
          { "value": "shoerack", "id": `amenity`, "meta": "Shoerack", "img": "/images/img/icons/shoerack.svg" },
          { "value": "washing-machine", "id": `amenity`, "meta": "Washing Machine", "img": "/images/img/icons/washing-machine.svg" },
          { "value": "ac", "id": `amenity`, "meta": "AC", "img": "/images/img/icons/ac.svg" },
          // {"value":"shoerack",  "id":`amenitie`, "meta":"Shoerack", "img": "/images/img/icons/shoerack.svg"},

        ]
      },
      buildingAmenities: {
        type: Array, alias: 'buildingAmenities', meta: 'Building Amenities', values: [
          { "value": "security", "id": `buildingAmenity`, "meta": "Security", "img": "/images/img/icons/security.svg" },
          { "value": "elevator", "id": `buildingAmenity`, "meta": "Elevator", "img": "/images/img/icons/elevator.svg" },
          { "value": "parking", "id": `buildingAmenity`, "meta": "Parking", "img": "/images/img/icons/parking.svg" },
          { "value": "clubhouse", "id": `buildingAmenity`, "meta": "Clubhouse", "img": "/images/img/icons/clubhouse.svg" },
          { "value": "swimming-pool", "id": `buildingAmenity`, "meta": "Swimming Pool", "img": "/images/img/icons/swimming-pool.svg" },
          { "value": "gym", "id": `buildingAmenity`, "meta": "Gym", "img": "/images/img/icons/gym.svg" },
          { "value": "power-backup", "id": `buildingAmenity`, "meta": "Power Backup", "img": "/images/img/icons/power-backup.svg" },
        ]
      },
      description: { type: String, id: "description" },
    };
  },

  tenantschema: () => {
    return {
      city: { type: String, id: "city" },
      roomTypes: {
        type: Array,
        alias: "roomTypes",
        meta: "Room Types",
        values: [
          {
            value: "room-shared-2",
            id: `roomType`,
            meta: "Room Shared By 2 other",
            img: "/images/img/icons/double-bed.svg",
          },
          {
            value: "room-shared-1",
            id: `roomType`,
            meta: "Room Shared By 1 other",
            img: "/images/img/icons/bed1.svg",
          },
          {
            value: "private-room",
            id: `roomType`,
            meta: "Private Room",
            img: "/images/img/icons/neighbour.svg",
          },
          {
            value: "full-house",
            id: `roomType`,
            meta: "Full House",
            img: "/images/img/icons/house.svg",
          },
          {
            value: "room/house-for-a-day",
            id: `roomType`,
            meta: "Room/House For a day",
            img: "/images/img/icons/sofa.svg",
          },
        ],
      },
      profesion: { type: String, id: "profesion" },
      organisation: { type: String, id: "organisation" },
      furnishingTypes: {
        type: Array,
        alias: "furnishingTypes",
        meta: "Furnishing Types",
        values: [
          {
            value: "full-furnished",
            id: `furnishingType`,
            meta: "Fully Furnished",
            img: "/images/img/icons/double-bed.svg",
          },
          {
            value: "semi-furnished",
            id: `furnishingType`,
            meta: "Semi Furnished",
            img: "/images/img/icons/double-bed.svg",
          },
          {
            value: "unfurnished",
            id: `furnishingType`,
            meta: "Unfurnished",
            img: "/images/img/icons/double-bed.svg",
          },
        ],
      },
      description: { type: String, id: "description" },
    }


  },
  // filter schema 
  filter: {
    ownerSchema: () => {
      return {
        userType:{
          type: String, alias: "userType", meta: "User Types", values: [
            { "value": "owner", "id": `userType`, "meta": "Owner", },
            { "value": "tenant", "id": `userType`, "meta": "Tenant", },
            // { "value": "room/house-for-a-day", "id": `roomTypes`, "meta": "Room/House For a day", },
          ]
        },
        roomTypes: {
          type: Array, alias: "roomTypes", meta: "Room Type", values: [
            { value: "room-shared-2", id: "roomTypes", meta: "3 Sharing",  },
            { value: "room-shared-1", id: "roomTypes", meta: "2 Sharing",  },
            { value: "private-room", id: "roomTypes", meta: "Private Room",  },
            { value: "full-house", id: "roomTypes", meta: "Full House", },
          ]
        },
        requiredLease: {
          type: Array, alias: 'requiredLease', meta: "Lease Required", values: [
            { "value": "yes", "id": `requiredLease`, "meta": "Yes" },
            { "value": "no", "id": `requiredLease`, "meta": "No" },
          ]
        },
        furnishingTypes: {
          type: Array, alias: 'furnishingTypes', meta: "Furnishing Types", values: [
            { "value": "full-furnished", "id": `furnishingType`, "meta": "Fully Furnished", },
            { "value": "semi-furnished", "id": `furnishingType`, "meta": "Semi Furnished", },
            { "value": "unfurnished", "id": `furnishingType`, "meta": "Unfurnished", },

          ]
        },
        offerFor: {
          type: String, alias: 'offerFor', meta: 'Offer For', values: [
            { "value": "male", "id": `offerFor`, "meta": "Male Only" },
            { "value": "female", "id": `offerFor`, "meta": "Female Only" },
            { "value": "any", "id": `offerFor`, "meta": "Any" },
          ]
        },

        amenities: {
          type: Array, alias: 'amenities', meta: 'Amenities', values: [
            { "value": "sofa", "id": `amenity`, "meta": "Sofa", },
            { "value": "microwave", "id": `amenity`, "meta": "Microwave", },
            { "value": "gyser", "id": `amenity`, "meta": "Water Heater", },
            { "value": "gas-stove", "id": `amenity`, "meta": "Kitchen Stove", },
            { "value": "mattress", "id": `amenity`, "meta": "Mattress", },
            { "value": "cupboard", "id": `amenity`, "meta": "Cupboard", },
            { "value": "wi-fi", "id": `amenity`, "meta": "Wi-fi", },
            { "value": "tv", "id": `amenity`, "meta": "TV" },
            { "value": "utensils", "id": `amenity`, "meta": "Utensils", },
            { "value": "fridge", "id": `amenity`, "meta": "Fridge", },
            { "value": "bed", "id": `amenity`, "meta": "Bed", },
            { "value": "shoerack", "id": `amenity`, "meta": "Shoerack", },
            { "value": "washing-machine", "id": `amenity`, "meta": "Washing Machine", },
            { "value": "ac", "id": `amenity`, "meta": "AC", },


          ]
        },
        buildingAmenities: {
          type: Array, alias: 'buildingAmenities', meta: 'Building Amenities', values: [
            { "value": "security", "id": `buildingAmenity`, "meta": "Security", },
            { "value": "elevator", "id": `buildingAmenity`, "meta": "Elevator", },
            { "value": "parking", "id": `buildingAmenity`, "meta": "Parking", },
            { "value": "clubhouse", "id": `buildingAmenity`, "meta": "Clubhouse", },
            { "value": "swimming-pool", "id": `buildingAmenity`, "meta": "Swimming Pool", },
            { "value": "gym", "id": `buildingAmenity`, "meta": "Gym", },
            { "value": "power-backup", "id": `buildingAmenity`, "meta": "Power Backup", },
          ]
        },

      };
    },
    tenantSchema: () => {
      return {

        roomTypes: {
          type: Array, alias: "roomTypes", meta: "Room Types", values: [
            { "value": "room-shared-2", "id": `roomType`, "meta": "Room Shared By 2 other", },
            { "value": "room-shared-1", "id": `roomType`, "meta": "Room Shared By 1 other", },
            { "value": "private-room", "id": `roomType`, "meta": "Private Room", },
            { "value": "full-house", "id": `roomType`, "meta": "Full House", },
            { "value": "room/house-for-a-day", "id": `roomType`, "meta": "Room/House For a day", },
          ]
        },
        furnishingTypes: {
          type: Array, alias: 'furnishingTypes', meta: "Furnishing Types", values: [
            { "value": "full-furnished", "id": `furnishingType`, "meta": "Fully Furnished", },
            { "value": "semi-furnished", "id": `furnishingType`, "meta": "Semi Furnished", },
            { "value": "unfurnished", "id": `furnishingType`, "meta": "Unfurnished", },

          ]
        },
      };
    }
  }

}
export default schema;