
const apiMapper  = {
   REGISTRATION_OTP: "/api/v1/user/emailExistRegister",
   OTP_VERIFY:"/api/v1/user/verifyOtp",
   USER_REGISTER:"/api/v1/authentication/register",
   CONTACT_REGISTER:"/api/v1/user/registerContact",
   CONTACT_OTP_VERIFY:"/api/v1/adds/verifyContactOtp",
   GET_COUNTRYCODE:"/api/v1/location/Country",
   UPLOAD_FILE:"/api/v1/upload",
   Search_BY_city:"/api/v1/location/SearchCity?key=",
   GET_CITY_BY_ID:"/api/v1/location/city/",
   REGISTER:"/api/v1/authentication/register",
   LOGIN:"/api/v1/authentication/login",
   OWNER_PRODUCT:"/api/v1/adds/owner/product/",
   TENANT_PRODUCT:"/api/v1/adds/tenant/product/",
   GET_ALL_Tenant:"/api/v1/adds/tenants",
   GET_ALL_Owner:"/api/v1/adds/owners",
   GET_RULES:"/api/v1/rule/getall",
   GET_ADDS: "/api/v1/adds/",
   GET_MEMBERSHIP:"/api/v1/membership/getall",
   CREATE_LISTING_ADD:"/api/v1/adds/createAd",
   Delete_USER_ADD:"/api/v1/adds/delete/",

   // Get contactOwner
    GET_CONTACT : "/api/v1/adds/contact",
    GET_FAVORITE: "/api/v1/adds/favorite/getall",
    GET_RECENT_CONTACTED: "/api/v1/adds/recentcontacted/getall",
    CREATE_DELETE_FAVORITE: "/api/v1/adds/favorite/",

   //Forget Password
   FORGET_PASSWORD_OTP:"/api/v1/authentication/forgetpasswordOTP",
   FORGET_PASSWORD_VERIFY:"/api/v1/authentication/forgetpassword",


   // edit profile
   GET_PROFILE:"/api/v1/user/userDetail",
   EDIT_PROFILE:"/api/v1/user/userDetail",
   CONTACT_US:"/api/v1/user/contactus",
   SUBSCRIPTION:"/api/v1/user/subscription",

   // -----Admin APi -----
   // Rules
   ADMIN_LOGIN:"/api/v1/authentication/admin/login",
   CREATE_RULE:"/api/v1/rule",
   UPDATE_RULE:"/api/v1/rule",
   DELETE_RULE:"/api/v1/rule",

   //-----content---
    GETALL_CONTENT:"/api/v1/content/getContentBystatus",
    CREATE_CONTENT:"/api/v1/content/add",
    UPDATE_CONTENT:"/api/v1/content/edit",
    DELETE_CONTENT:"/api/v1/content/delete",

    //-----MemberShip admin Edit
     UPDATE_MEMBERSHIP_PLAN:"/api/v1/membership",

    //  ---Admin Order api ---
    GET_ALL_ORDER:"/api/v1/order/getall",

    // --Admin Flag-ads----
    GET_ALLADS:"/api/v1/adds/getall",
     
    // ---Admin disabled ads ----

    _ADS_DISABLED:'/api/v1/adds/',
    _ADS_DEACTIVATE: '/api/v1/adds/delete/',

    //--------messages
    CREATE_CHANNEL: "/api/v1/channel",
    GET_ALL_CHANNEL: "/api/v1/channel",
    GET_ALL_MESSAGE: "/api/v1/message/",
    SEND_MESSAGE: "/api/v1/message/",

    //------create order

    CREATE_ORDER: "/api/v1/order/",
    UPDATE_ORDER_STATUS: "/api/v1/order/",

    GET_NEW_ADDS: "/api/v1/newAdds/",


    //----Content Related api
    GET_CONTENTBYSLUG:"/api/v1/content/get/slug/",
    
    //ADMIN USERCONTROL
    GET_ALL_USER:"/api/v1/user/getall/",
    UPDATE_USER_ADMIN:"/api/v1/user/makeadmin",

    UPDATE_OWNER: "/api/v1/adds/updateOwner",

    UPDATE_TENANT: "/api/v1/adds/updateTenant",

     //admin add city
      REFRESH_CITYLIST:"/api/v1/location/RefreshCity",

      // get features
      GET_ALL_FEATURE:"/api/v1/feature/",
      CREATE_FEATURE:"/api/v1/feature/",
      UPDATE_FEATURE:"/api/v1/feature/"
}


export default apiMapper;