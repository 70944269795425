import { createSlice } from "@reduxjs/toolkit";

// Get initial state from localStorage if available
const initialState = {
  token: localStorage.getItem("Authorization") || null,
  name: localStorage.getItem("name") || "",
  email: localStorage.getItem("email") || "",
  profileImg: localStorage.getItem("profileImg") || "",
  gender: localStorage.getItem("gender") || "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { token, name, email, profileImg, gender } = action.payload;

      // Set in state
      state.token = token;
      state.name = name;
      state.email = email;
      state.profileImg = profileImg;
      state.gender = gender;

      // Set in localStorage
      localStorage.setItem("Authorization", token);
      localStorage.setItem("name", name);
      localStorage.setItem("email", email);
      localStorage.setItem("profileImg", profileImg);
      localStorage.setItem("gender", gender);
    },
    clearUser: (state) => {
      // Clear user state
      state.token = null;
      state.name = "";
      state.email = "";
      state.profileImg = "";
      state.gender = "";

      // Remove from localStorage
      localStorage.removeItem("Authorization");
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      localStorage.removeItem("profileImg");
      localStorage.removeItem("gender");
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
