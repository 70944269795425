import React from 'react';
import './css/CallButton.css';
import { useMediaQuery } from 'react-responsive';

const CallButton = ({ PhoneNumber }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    // console.log("Phone", PhoneNumber);
    const handlePhoneCall = () => {
        window.location.href = `tel:${PhoneNumber?.replace('-','')}`;
    };

    const handleWhatsAppCall = () => {
        window.location.href = `https://wa.me/${PhoneNumber?.replace('-','')?.replace('+','')}`;
    };

    return (
        isMobile &&
        <div className='row mb-2 mt-3 justify-content-end'>
            <img
                src={process.env.PUBLIC_URL + "/images/phone-icon.svg"}
                alt="Call"
                className="call-button"
                onClick={handlePhoneCall}
            />
            <img
                src={process.env.PUBLIC_URL + "/images/whatsapp-icon.svg"}
                alt="Call via WhatsApp"
                className="call-button"
                onClick={handleWhatsAppCall}
            />
        </div>
    );
};

export default CallButton;
