import { Link } from "react-router-dom";
import React from 'react';


const Footer = () => {

  const footerMenu = [{ "name": "Home", "link": "#/", }, { "name": "About Us", "link": "#/","slug":"about" },
  { "name": "FAQ", "link": "#/","slug":"faq"  },
  { "name": "Preview", "link": "#/","slug":"preview"}, { "name": "Terms & Conditions", "link": "#/","slug":"termCondition"  },
  { "name": "Privacy Policy", "link": "#/", "slug": "privacy-policy" }, { "name": "Refund & Cancellation Policy", "link": "#/","slug":"refundPolicy"},
  //{ "name": "Advertise With Us", "link": "#/","slug":"add"  },
    { "name": "Contact Us", "link": "/contactus","slug":""  }]


  const footerMenuJsx = footerMenu.map((ele, i) => {
    return (<>
      <Link key={i} to = {ele.name ==="Home"? '/':(ele?.slug === '' ? ele?.link:  `/content/${ele?.slug}`)}>
        <li className="nav-item" key={i}><a href={ele.link} className="nav-link px-2 " key={ele.name} >{ele.name}</a></li>
      </Link>
    </>
    )
  })

  
  
  return (
    <>
      <footer className="footer ">
        <div className="footer py-5">
          <div className="container">
            <div className="row">
              <ul className="nav justify-content-center  pb-3 mb-3">
                {footerMenuJsx}
              </ul>

              <div className="col-sm-12" >

                <div className="footer-social pt-4 text-center">
                  <a href="https://www.facebook.com/people/EkSarathi/61551116189275/?mibextid=LQQJ4d">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="https://twitter.com/eksarathi?s=11&t=LJ9r9vkghCdThorz60BVQQ">
                    {/* <img src="/images/img/icons/x.svg" alt="/images/img/icons/x.svg" /> */}
                    <i class="bi bi-twitter-x"></i>
                  </a>
                  <a href="https://www.linkedin.com/in/eksarathi-llc-b177b02a5/">
                    <i className="bi bi-linkedin"></i>
                  </a>
                  <a href="https://www.instagram.com/eksarathi/?igsh=OGQ5ZDc2ODk2ZA%3D%3D">
                    <i className="bi bi-instagram"></i>
                  </a>


                </div>
              </div>
              <div className="col-sm-12">
                <div className="footer-copy">
                  <div className="copy-right text-center pt-5">
                  <p className="">
                    © 2024. <a href="https://www.eksarathi.com">EkSarathi</a>
                  </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
