import { useEffect, } from "react";
import fetchFunction from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import { useState } from "react";
import Contactme from "./Contactme";
import Shimmeruitenant from "./Shimmeruitenant";
import "../components/css/commonlisting.css";
import Filter from "./Filter";
import { useParams, Link, useSearchParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { Buffer } from 'buffer';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { SkeletonCommonListing } from "./SkeletonCommonListing";

import _ from 'lodash';
import haversineDistanceMiles from "../utils/calculateDistance";
import DistanceSearch from "./DistanceSearch";
import { SEOCommon } from "./SEOCommon";
import ShareAndSaveButton from "./ShareAndSaveButton";
import common from "../utils/common";
import { ResetFilterButton } from "./ResetFilterButton";

const Commonlisting = ({ pageType, isMembershipEnabled }) => {

  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const ownerType = 'owner';
  // const pageType = (ownerType);
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [limit] = useState(6);  
  // const { city } = useParams();

  const queryParams = new URLSearchParams(window.location.search)
  const longitude = queryParams.get('longitude')
  const latitude = queryParams.get('latitude')
  const address = queryParams.get("address")
  const [isLoading, setLoading] = useState(true)

  const [combinedFilter, setCombinedFilter] = useState({
    page: 1,
    sort: 'relevance',
    filters: {},
    membershipStatus: 'false',
    distance: 30,
    filterCount: 0,
  });

  useEffect(() => {
    let totalFilterCount = 0;
    Object.keys(combinedFilter.filters).forEach((key) => {
      if (Array.isArray(combinedFilter.filters[key]) && combinedFilter.filters[key].length > 0) {
        totalFilterCount += combinedFilter.filters[key].length;
      } else if (typeof combinedFilter.filters[key] === 'number') {
        totalFilterCount += 1;
      }
    });
    setCombinedFilter(prev => ({ ...prev, filterCount: totalFilterCount }));
  }, [combinedFilter.filters]);

  async function getAllRecords() {
    setLoading(true)
    let filterEncoded = ""
    let url = `?page=${combinedFilter.page - 1}&limit=${limit}&userid=${common.getUserId()}&sort=${combinedFilter.sort}&membershipStatus=${combinedFilter.membershipStatus}&distance=${combinedFilter.distance}&longitude=${longitude}&latitude=${latitude}`
    if (Object.keys(combinedFilter.filters).length) {
      const filterCopy = { ...combinedFilter.filters }; // copy the filter
      delete filterCopy['distance']; // remove distance in the filter
      filterEncoded = Buffer.from(JSON.stringify(filterCopy)).toString('base64');

      url = url + `&filters=${filterEncoded}`;
    }

    let mapperUrl = (pageType === ownerType) ? (apiMapper.GET_ALL_Owner) : (apiMapper.GET_ALL_Tenant);
    const response = await fetchFunction.get(mapperUrl + url);
    if(response?.status === 200){
      setLoading(false)
    }
    const finalresponse = response?.data?.data || []
    const count = response?.data?.totalCount;
    setTotalCount(count);
    setPageData(finalresponse);
    
  }

  //console.log("pageData", pageData);

  useEffect(() => {
    getAllRecords()

  }, [combinedFilter]);


  function capitalizeFirstLetter(string) {
    // Check if the string is null, undefined, or empty after trimming
      if (!string || typeof string !== "string" || string.trim() === "") {
          return ""; // Return an empty string if the input is null, undefined, or empty
      } else {
          return string.charAt(0).toUpperCase() + string.slice(1);
      }
  }

  const resetFilterHandler = () => {
    // reset the filter data
    setCombinedFilter(prev => ({...prev, filters: {} , page: 1, filterCount:0, distance:30})) // reset the page number to 1
  };
  
  return (
    <>
    <div>
    <SEOCommon dynamicData={{ogTitle: 'Tenant/owner in ' + address}} />
    </div>
      <div className="container-fluid min-vh-100 commonlisting">
        <div className="container commonlistingContainer">
          {/* <div className="row headingTitle pt-5 pt-md-0">
            <div className="headingcontent">{ownerType === 'owner' ? (address ?  `Room Result for ${(address)}`: 'Room Results') :
             `Tenant Results for ${address}`} </div>
          </div> */}
          <div className="row pt-3">

            {/* <!-Mobile - Modal --> */}
            <div class="modal fade" id="findmoreModal" tabindex="-1" aria-labelledby="findmoreModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div className=" row rounded-3" style={{ backgroundColor: "white" }}>
                      <section>
                        <div className="row filter1">
                        <h3 className="filter-text-h3 d-inline-flex flex-column justify-content-between gap-1">
                          <span className="filter-border">Filter</span>
                          <span><ResetFilterButton combinedFilter={combinedFilter} resetFilterHandler={resetFilterHandler}/></span>
                        </h3>
                        </div>
                      </section>
                      <Filter combinedFilter={combinedFilter} userType={pageType} setCombinedFilter={setCombinedFilter}/>
                      <DistanceSearch combinedFilter={combinedFilter} setCombinedFilter={setCombinedFilter} IsMobile={isMobile}/>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                  </div>
                </div>
              </div>
            </div>
            {/* ----Filter---- */}
            {isMobile && (<>
              <div className="container filter">
                <div className="row shadow-sm adjustment" style={{ backgroundColor: "white" }}>
                  {isMembershipEnabled ? <div className="col-5 py-3">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="membership" 
                       onClick={()=>{setCombinedFilter({...combinedFilter, membershipStatus: 
                       combinedFilter.membershipStatus === "true" ? "false": "true"})}} />
                      <label class="form-check-label" for="membership">
                        <img src="/images/placeholder/premium.png" height={20} width={50} alt='premium-member' />
                      </label>
                    </div>
                  </div>:''}
                  {/* <div className=" col-2">
                    <div className="verticalLine"></div>   
                  </div> */}

                  <div style={{marginLeft:"-0.30rem"}} className="col-8">
                    <button className="btn btn-light m-2" data-bs-toggle="modal" data-bs-target="#findmoreModal" >
                      <i class="bi bi-list" style={{ fontSize: "22px" }}></i></button><b>Filter</b> 
                  </div>
                </div>
              </div>
            </>)}

            {isDesktop && <div className="col-md-4 rounded-3" style={{ backgroundColor: "white" }}>

              <div className="row filter1">
                <h3 className="filter-text-h3 d-inline-flex flex-column justify-content-between gap-1">
                  <span className="filter-border">Filter</span>
                  <span><ResetFilterButton combinedFilter={combinedFilter} resetFilterHandler={resetFilterHandler}/></span>
                </h3>
                {isMembershipEnabled ?<div className="row">
                  <div class="px-5 form-check">
                    <input class="form-check-input" type="checkbox" value="" id="membership" onClick={()=>
                      {setCombinedFilter({...combinedFilter, membershipStatus: combinedFilter.membershipStatus === "true" ? "false": "true"})}} />
                    <img src="/images/placeholder/premium.png" height={35} width={100} alt='premium-member' />
                  </div>
                </div>:''}
              </div>

              <Filter combinedFilter={combinedFilter} userType={pageType} setCombinedFilter={setCombinedFilter}/>
              <DistanceSearch combinedFilter={combinedFilter} setCombinedFilter={setCombinedFilter}/>
            </div>}
            {/* -----Main---- */}
            <div className="col-12 col-md-8 main">
              <div className="container">
                <div className="row my-1" style={{ backgroundColor: "white" }}>
                  <div>{`Showing ${pageData.length === 0 ? 0:(combinedFilter.page-1) * limit + 1}-${(combinedFilter.page-1) * limit + pageData?.length} of over ${totalCount} ${pageType === ownerType ? ("room"):("tenant")} listings in `}<span className="span-address-bold">{address}</span> </div>
                  <div className="row sortinghead">
                    <ul>
                      {/* <li><strong>sort By</strong></li> */}
                      <li><a href='#/' className={combinedFilter.sort === "relevance" ? "sortingSubHeading":'' } onClick={()=>{setCombinedFilter({...combinedFilter, sort: "relevance"})}}>Relevance</a></li>
                      <li><a href='#/' className={combinedFilter.sort === "newest" ? "sortingSubHeading":'' }  onClick={()=>{setCombinedFilter({...combinedFilter, sort: "newest"})}}>Newest</a></li>
                      <li><a href='#/' className={combinedFilter.sort === "oldest" ? "sortingSubHeading":'' }  onClick={()=>{setCombinedFilter({...combinedFilter, sort: "oldest"})}}>Oldest</a></li>
                    </ul>
                  </div>
                </div>
                {isLoading ? <SkeletonCommonListing/>:(pageData?.length === 0 ? (<div style={{margin:'10% 0', textAlign:'center', fontWeight:'bold'}}>No results found</div>) : (<>
                  {pageData?.map((ele) => {
                    return (
                      <>
                        <div className="row mt-2 py-2 rounded-3 rounded-top-0 mb-2 border-2 shadow-sm commonlisting-card commonlisting-card-image" style={{ backgroundColor: "white" }}>
                          <div className="col-12 col-md-3 p-4 text-center">
                            {isMobile && <Link to={(pageType === ownerType) ? (common.ownerPath + ele._id) : (common.tenantPath + ele._id)}>
                              <img
                                 src={ele?.photos[0] || 
                                 `/images/placeholder/${ele?.userType === "owner" ? 'owner' : 
                                 (ele?.userId?.gender === "male" ? 'tenantM' : 'tenantF')}.jpg`
                                 }
                               
                                alt="user image"
                                className="imagebgMobile" />
                            </Link>}
                            {/* change in deployment */}
                            {isDesktop && <Link to={(pageType === ownerType) ? (common.ownerPath + ele._id) : (common.tenantPath + ele._id)}>
                              <img
                                 src={ele?.photos[0] || 
                                 `/images/placeholder/${ele?.userType === "owner" ? 'owner' : 
                                 (ele?.userId?.gender === "male" ? 'tenantM' : 'tenantF')}.jpg`
                                 }
                               
                                alt="user image"
                                className="imagebg" />
                            </Link>}
                            {/* {isDesktop && <Link to={(pageType === ownerType) ? (common.ownerPath + ele._id) : (common.tenantPath + ele._id)}>
                              <img
                              // change in deployment
                                 src={
                                  (!ele.photos[0] || ele.photos[0] === "")
                                    ? `/images/placeholder/male.jpeg`
                                    : ele.photos[0]
                                }
                                // src={
                                //   ele?.photos && ele?.photos.length > 0
                                //   ? `http://localhost:6167/${ele.photos[0]}`
                                //   : `/images/placeholder/${ele?.userId?.gender || "male"}.jpg`
                                // }
                               
                                alt="user image"
                                className="imagebg" />
                            </Link>} */}
                          </div>
                          <div className=" col-12 col-md-6">
                            <div className="row maincontent">
                              <Link to={(pageType === ownerType) ? (common.ownerPath + ele._id) : (common.tenantPath + ele._id)}>
                                <a href={null}>{(pageType === ownerType) ? (`${(ele?.roomTypes === 'room-shared-2' ? '3 Sharing' : '') || (ele?.roomTypes === 'room-shared-1' ? '2 Sharing' : '') || (ele?.roomTypes === 'private-room' ? 'Private' : '')} Room in ${ele?.location?.address}`) : `Looking for room in ${ele?.location?.address}`}</a>
                              </Link>
                            </div>
                            <div className="row px-2 liststyle">
                              <ul className="listingdisplay my-2 px-4">
                                <li><span className="subheading">Room Type</span>: {ele?.roomTypes && ele.roomTypes.length > 0 ? [
                                  ele?.roomTypes.some(r => r.roomTypes === 'room-shared-2') ? '3 Sharing' : '',
                                  ele?.roomTypes.some(r => r.roomTypes === 'room-shared-1') ? '2 Sharing' : '',
                                  ele?.roomTypes.some(r => r.roomTypes === 'private-room') ? 'Private room' : '',
                                  ele?.roomTypes.some(r => r.roomTypes === 'full-house') ? 'Full House' : ''
                                ].filter(Boolean).join(', '):'N/A'}</li>
                                <li><span className="subheading">Distance</span>: {haversineDistanceMiles(latitude, longitude,ele?.geoLocation.coordinates[1],ele?.geoLocation.coordinates[0])} Miles Approx</li>
                                {/* <li><span className="subheading">Location</span>: {capitalizeFirstLetter(ele?.city.name)}</li> */}
                                <li><span className="subheading">Name</span>: {ele?.userId?.name}</li>
                                <li><span className="subheading">{(ele?.userType === 'owner' ?'Available From' : 'Need From')}</span>: {moment(ele?.availableFrom).format('ll') || '--'}</li>
                                <li><span className="subheading">Rent</span>: {ele?.rent ? '$'+ele?.rent  : 'N/A'}</li>
                                {/* <li><span className="subheading">Offer For</span>: {ele?.offerFor}</li> */}
                                <li>
                                  <span className="subheading">Furnish Type</span>: {
                                    (ele?.furnishingTypes && ele.furnishingTypes.length > 0 ? 
                                      capitalizeFirstLetter(
                                        ele.furnishingTypes
                                          .map((furnishType) => furnishType?.furnishingType)
                                          .join("  ")
                                          .replace(/-/g, ' ') 
                                      )
                                      : 
                                      "N/A"
                                    )
                                  }
                                </li>
                                  <li><span className="subheading">Listing Type</span>: {ele?.userType === ownerType ? 'Room' : 'Tenant'}</li>
                                  {/* <li><span className="subheading">User Type</span>: {ele?.userType}</li> */}
                              </ul>
                            </div>
                          </div>
                          {/* find more column desktop */}
                          {isDesktop && (<div className="col-12 col-md-3 mt-2 mb-3 mb-md-0 findmore">
                            <div className="row">
                              <div className="col-6 col-md-12 my-2 my-md-0">
                                <Link to={(pageType === ownerType) ? (common.ownerPath + ele._id) : (common.tenantPath + ele._id)}>
                                  <button
                                    type="button"
                                    className="main-btn"
                                  >
                                    Find more...
                                  </button>
                                </Link>
                              </div>
                              <div className="col-6 col-md-12 text-start py-3 mt-md-5 py-md-3 mx-md-3">
                                {ele?.userId?.membershipStatus && isMembershipEnabled ? 
                                  <img src="/images/placeholder/premium.png" height={40} width={100} alt='premium-member' />:''}
                              </div>
                            </div>
                            <div className="col-6 col-md-12  py-3 mt-md-5 my-md-0">
                                <ShareAndSaveButton ownerData={ele}/>
                            </div>

                          </div>)}

                          {/* find more column mobile */}
                          {isMobile && (<div className="row">
                              <div className="d-flex justify-content-start col-8 mb-2">
                                <Link to={(pageType === ownerType) ? (common.ownerPath + ele._id) : (common.tenantPath + ele._id)}>
                                  <button
                                    type="button"
                                    className="main-btn"
                                  >
                                    Find more...
                                  </button>
                                </Link>
                              </div>
                              <div className={isMembershipEnabled ? "col-4 d-flex justify-content-end" : "d-none"}>
                                {ele?.userId?.membershipStatus && isMembershipEnabled ? 
                                  <img src="/images/placeholder/premium.png" height={40} width={100} alt='premium-member' />:''}
                              </div>
                            <div className={isMembershipEnabled ? "col-4 d-flex justify-content-start pt-4" : "col-4 d-flex justify-content-end pt-4"}>
                                <ShareAndSaveButton ownerData={ele}/>
                            </div>

                          </div>)}
                        </div>
                      </>
                    );
                  })}

                </>))}
              </div>

              {/* --------  pagination------- */}
                <div className="row mb-2">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <a className={"page-link " + (combinedFilter.page <= 1 ? 'disabled' : '')} href={null} aria-label="Previous" onClick={()=>{setCombinedFilter({...combinedFilter ,page: combinedFilter.page - 1 })}}>
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>

                      </a>
                    </li>
                    {totalCount > 0 && [...Array(Math.ceil(totalCount/limit) ).keys()].slice(0,3).filter((pgNumber, i) => {
                    
                      if(pgNumber + combinedFilter.page < Math.ceil(totalCount/limit) +1){
                        return true;
                      }
                      

                    }).map((pgNumber)=>{
                      return <li key={pgNumber + combinedFilter.page }
                          className={`page-item ${combinedFilter.page === pgNumber + combinedFilter.page  ? 'active' : ''} `} >

                          <a onClick={() => setCombinedFilter({...combinedFilter ,page:pgNumber + combinedFilter.page })}
                            className='page-link'
                            href={null}>

                            {pgNumber + combinedFilter.page}
                          </a>
                        </li>
                    })}

                    <li className="page-item">
                      <a className={"page-link " + (pageData?.length < limit ? 'disabled' : '')} href={null} aria-label="Next" onClick={()=>{setCombinedFilter({...combinedFilter ,page: combinedFilter.page + 1 })}} >
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Commonlisting;





