import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

// Updated SEO data mapping with additional fields
const seoData = {
  about: {
    title: "About Eksarathi - Affordable Rooms and Apartments for Rent in the USA",
    description: "Eksarathi invites you to an immersive community experience, a gateway designed to connect and support individuals on their unique journeys...",
    keywords: "Eksarathi, About, Community, Rooms, Apartments",
    ogSiteName: "Eksarathi",
    ogTitle: "About Eksarathi - Affordable Rooms and Apartments for Rent in the USA",
    ogUrl: "https://www.eksarathi.com/content/about",
    ogDescription: "Discover our community at Eksarathi.",
    ogImage: "https://www.eksarathi.com/images/logo3blue.png",
  },
  advertise: {
    title: "Advertise With Eksarathi - Reach a Wide Audience for Room and Apartment Rentals",
    description: "Advertise with Eksarathi for rooms, apartments, flats, and tenants for rent.",
    keywords: "Advertise, Eksarathi, Rentals",
    ogSiteName: "Eksarathi",
    ogTitle: "Advertise With Eksarathi - Affordable Rentals",
    ogUrl: "https://www.eksarathi.com/content/advertise",
    ogDescription: "Reach a wide audience and explore our listings for your ideal rental space.",
    ogImage: "https://www.eksarathi.com/images/logo3blue.png",
  },
  
  default: {
    title: "Eksarathi - Affordable Rooms and Apartments for Rent in the USA",
    description: "Find affordable rooms and apartments for rent at Eksarathi.",
    keywords: "Rooms, Apartments, Affordable",
    ogSiteName: "Eksarathi",
    ogTitle: "Eksarathi - Affordable Rooms and Apartments for Rent in the USA",
    ogUrl: window.location.href,
    ogDescription: "Explore our listings and book your ideal accommodation.",
    ogImage: "https://www.eksarathi.com/images/logo3blue.png",
  }
};

export const SEOCommon = ({ dynamicData }) => {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[2]; // about and advertise
  let currentSEO;
  if (currentPath) {
    currentSEO = seoData[currentPath] || seoData.default
  }
  else {
    currentSEO = seoData.default;
  }

  // Merge dynamic data with default SEO data
  const finalSEO = {
    title: dynamicData?.title || currentSEO.title,
    description: dynamicData?.description || currentSEO.description,
    keywords: dynamicData?.keywords || currentSEO.keywords,
    ogSiteName: dynamicData?.ogSiteName || currentSEO.ogSiteName,
    ogTitle: dynamicData?.ogTitle || currentSEO.ogTitle,
    ogUrl: dynamicData?.ogUrl || currentSEO.ogUrl,
    ogDescription: dynamicData?.ogDescription || currentSEO.ogDescription,
    ogImage: dynamicData?.ogImage || currentSEO.ogImage,
  };

  return (
    <Helmet>
      {/* Meta tags for SEO */}
      <meta name="description" content={finalSEO.description} />
      <meta name="keywords" content={finalSEO.keywords} />
      <meta property="og:site_name" content={finalSEO.ogSiteName} />
      <meta property="og:title" content={finalSEO.ogTitle} />
      <meta property="og:description" content={finalSEO.ogDescription} />
      <meta property="og:image" content={finalSEO.ogImage} />
      <meta property="og:url" content={finalSEO.ogUrl} />
      <meta property="og:type" content="website" />

      {/* Twitter Card meta tags for Twitter sharing */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={finalSEO.title} />
      <meta name="twitter:description" content={finalSEO.description} />
      <meta name="twitter:image" content={finalSEO.ogImage} />

      {/* Page title */}
      <title>{finalSEO.title}</title>
    </Helmet>
  );
};
