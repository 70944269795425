import { useEffect, useState } from "react"
import "./css/membership.css"
import { useNavigate } from "react-router-dom"
import common from "../utils/common"
import fetcher from "../utils/fetch"
import apiMapper from "../utils/apiMapper"
import config from "../utils/config"
import {loadStripe} from '@stripe/stripe-js';
import fetchFunction from "../utils/fetch"
import Spinner from "./spinner"

const Membership = () => {

    const [loading, setLoading] = useState(true);
    const [membershipPlan, setMembershipPlan] = useState({})
    const navigate = useNavigate();


    const getMemberShipPlan = async () => {
        setLoading(false);
        const headers = { ...config.headers };
        headers['Authorization'] = localStorage.getItem('Authorization');
        const getResponse = await fetcher.get(apiMapper.GET_MEMBERSHIP, config.host, headers);
        
        if ([200, 201].includes(getResponse.status)) {
            setMembershipPlan(getResponse.data.data.message);
        } else if ([401].includes(getResponse.status)) {
            localStorage.clear();
            localStorage.clear();
            navigate('/')
        }
    }
    useEffect(() => {
        const isValid = common.isLoggedIn();
        if(!isValid ){
            localStorage.clear();
            localStorage.clear();
            navigate('/');
            navigate(0);
            alert('Please login to see membership.')
            return;
        }
        if (common.isMember() &&  common.isMember() === 'true') {
            alert('Looks like you Already have MemberShip.');
           
            navigate('/')
        }
       
        getMemberShipPlan();
    }, [])

    const checkoutMembership = async (memberShipId, isTrial)=>{
        // console.log(memberShipId)
              
        if(memberShipId === "654b0728dc71f2c8adc6c932")
        {
            if(membershipPlan[0]?.trial && (!localStorage.getItem('membershipExpiryDate')  || localStorage.getItem('membershipExpiryDate') === 'null')){
                isTrial = true
            }
            else{
                isTrial = false
            }
        }else{
            isTrial = false
        }
        // console.log(!localStorage.getItem('membershipExpiryDate') , localStorage.getItem('membershipExpiryDate') === 'null')
        // console.log(isTrial)
        // return false
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        const payload = {
            membership: memberShipId,
            isTrial
        }

        const orderObj =  await fetchFunction.post(apiMapper.CREATE_ORDER, payload, config.host, common.getHeaders() )
        if([200,201].includes(orderObj?.status)){
            if(isTrial){
                alert('Your Trial membership has started.');
                localStorage.setItem("membershipStatus", true)
                navigate('/');
                navigate(0);
            } else {
                if  (orderObj?.data?.data?._id) localStorage.setItem('orderId', orderObj?.data?.data?._id);
                const result = stripe.redirectToCheckout({
                    sessionId: orderObj?.data?.data?.referenceId
                }) 
            }
            
        } else if (orderObj?.status === 401) {
            localStorage.clear();
            localStorage.clear();
            navigate('/');
            navigate(0);
        }
        else{
            alert('Oops! Something wrong. Try after sometime.');
            navigate('/');
            navigate(0);
        }
    };

    return loading ? <Spinner/>: (membershipPlan.lenth===0 ? (<><Spinner/></>): (
        <><div className="membership min-vh-100">
            <div className="container-fluid">
                <div className="demo">
                    <div className="container ">
                        <div className="row text-center my-lg-2 mt-5 membership-text">
                            <h2>Get a Membership</h2>
                        </div>
                        <div className="row subContent">
                          <p><b>Please take any membership in order to have a visibility of your advertisement to the other users and to be able contact you.</b></p>
                        </div>
                        <div className="row containermembership">
                            <div className="col-md-4 col-sm-12">
                                <div className="pricingTable">
                                    <div className="pricingTable-header">
                                        <i className="fa fa-adjust"></i>
                                        <div className="price-value"> ${(membershipPlan[0]?.trial && (!localStorage.getItem('membershipExpiryDate')  || localStorage.getItem('membershipExpiryDate') === 'null' )  ) ? '0' :membershipPlan[0]?.discountPriceUSD} <span className="month">per month</span> </div>
                                    </div>
                                    <h3 className="heading">{membershipPlan[0]?.name}</h3>
                                    <div className="pricing-content">
                                        <ul>
                                            <li><b>{membershipPlan[0]?.description}</b></li>         
                                            <li className="text-decoration-line-through"><b>${membershipPlan[0]?.priceUSD}</b>{' '}USD</li>
                                            <li><h3><b>{(membershipPlan[0]?.trial && (!localStorage.getItem('membershipExpiryDate')  || localStorage.getItem('membershipExpiryDate') === 'null' )  ) ? '0' :membershipPlan[0]?.discountPriceUSD}</b>{' '}USD</h3></li>
                                            <li><b>{membershipPlan[0]?.duration} Days</b> Duration</li>
                                        </ul>
                                    </div>
                                    <div className="pricingTable-signup">
                                        <a href="#/" onClick={()=>{checkoutMembership(membershipPlan[0]?._id, membershipPlan[0]?.trial )}}>{(membershipPlan[0]?.trial && (!localStorage.getItem('membershipExpiryDate')  || localStorage.getItem('membershipExpiryDate') === 'null' )  ) ? "Start Free Trial" :"sign up"}</a>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="pricingTable green">
                                    <div className="pricingTable-header">
                                        <i className="fa fa-briefcase"></i>
                                        <div className="price-value"> ${(membershipPlan[1]?.trial && (!localStorage.getItem('membershipExpiryDate')  || localStorage.getItem('membershipExpiryDate') === 'null' )  ) ? '0' :membershipPlan[1]?.discountPriceUSD}<span className="month">2 month</span> </div>
                                    </div>
                                    <h3 className="heading">{membershipPlan[1]?.name}</h3>
                                    <div className="pricing-content">
                                    <ul>
                                            <li><b>{membershipPlan[1]?.description}</b></li>         
                                            <li className="text-decoration-line-through"><b>${membershipPlan[1]?.priceUSD}</b>{' '}USD</li>
                                            <li><h3><b>{(membershipPlan[1]?.trial && (!localStorage.getItem('membershipExpiryDate')  || localStorage.getItem('membershipExpiryDate') === 'null' )  ) ? '0' :membershipPlan[1]?.discountPriceUSD}</b>{' '}USD</h3></li>
                                            <li><b>{membershipPlan[1]?.duration} Days</b> Duration</li>
                                        </ul>
                                    </div>
                                    <div className="pricingTable-signup">
                                    <a href="#/" onClick={()=>{checkoutMembership(membershipPlan[1]?._id, membershipPlan[1]?.trial )}}>{(membershipPlan[1]?.trial && (!localStorage.getItem('membershipExpiryDate')  || localStorage.getItem('membershipExpiryDate') === 'null' )  ) ? "Start Free Trial" :"sign up"}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="pricingTable blue">
                                    <div className="pricingTable-header">
                                        <i className="fa fa-diamond"></i>
                                        <div className="price-value"> ${(membershipPlan[2]?.trial && (!localStorage.getItem('membershipExpiryDate')  || localStorage.getItem('membershipExpiryDate') === 'null' )  ) ? '0' :membershipPlan[2]?.discountPriceUSD} <span className="month">3 month</span> </div>
                                    </div>
                                    <h3 className="heading">{membershipPlan[2]?.name}</h3>
                                    <div className="pricing-content">
                                    <ul>
                                            <li><b>{membershipPlan[2]?.description}</b></li>         
                                            <li className="text-decoration-line-through"><b>${membershipPlan[2]?.priceUSD}</b>{' '}USD</li>
                                            <li><h3><b>{(membershipPlan[2]?.trial && (!localStorage.getItem('membershipExpiryDate')  || localStorage.getItem('membershipExpiryDate') === 'null' )  ) ? '0' :membershipPlan[2]?.discountPriceUSD}</b>{' '}USD</h3></li>
                                            <li><b>{membershipPlan[2]?.duration} Days</b> Duration</li>
                                        </ul>
                                    </div>
                                    <div className="pricingTable-signup">
                                    <a href="#/" onClick={()=>{checkoutMembership(membershipPlan[2]?._id, membershipPlan[2]?.trial )}}>{(membershipPlan[2]?.trial && (!localStorage.getItem('membershipExpiryDate')  || localStorage.getItem('membershipExpiryDate') === 'null' )  ) ? "Start Free Trial" :"sign up"}</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div></>
    ))
}

export default Membership