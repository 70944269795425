import CallButton from "./CallButton";
import "./css/contactpageModal.css"

function Contactpagemodal({ contactDetailsData }) {

    

    const { _id, email, phone, name, profileImg, gender } = contactDetailsData;
    const phoneWithCountrycode = phone?.split('-')?.slice(0,2)?.join('-');
    // console.log("Phone", phoneWithCountrycode)
    return (
        <>

            {/* 
<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal1">
  Launch demo modal
</button> */}


            <div className="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" data-bs-config={{"backdrop":true}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel1">Contact</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row mb-2 product">
                                <div className=" contactimagesection">
                                    <img className="contactimg" src={profileImg || `/images/placeholder/${gender === 'male' ? 'tenantM':'tenantF' }.jpg`} alt={_id} />
                                </div>
                            </div>
                            <div className="row mb-2 mt-3">
                                <div className="col-4 offset-1 text-center"> Name:-</div>
                                <div className="col-4"> <b>{name}</b></div>                         
                            </div>
                            <div className="row mb-2 mt-3 ">
                                <div className="col-4 offset-1 text-center"> Email:-</div>
                                <div className="col-4 wrapingword"> <b>{email}</b></div>                         
                            </div>
                            <div className="row mb-2 mt-3">
                                <div className="col-4 offset-1 text-center"> phone:-</div>
                                <div className="col-4"> <b>{phoneWithCountrycode}</b></div>                         
                            </div>
                            <CallButton PhoneNumber={phoneWithCountrycode}/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>    )
}


export default Contactpagemodal

