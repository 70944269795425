import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import common from "../../utils/common";
import Chart from "./Chart";
// uncomment the below if want to increase the left write margin
// import "./Adminbody.css"; 

const Adminbody = () => {
  const navigate = useNavigate();
  const [adminLoggedIn, setAdminLoggedIn] = useState(false);
  const hoverStyles = {
    backgroundColor: "lightblue", // Set your desired background color on hover
    // Add other hover styles as needed
  };
  const barChartData = {
    labels: [
      "Category 1",
      "Category 2",
      "Category 3",
      "Category 4",
      "Category 5",
    ],
    datasets: [
      {
        label: "Example Dataset",
        data: [10, 20, 15, 25, 30],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const isValid = common.isAdminLoggedIn();
    if (isValid) {
      navigate("/admin");
    } else {
      localStorage.clear();
      localStorage.clear();
      navigate("/admin/login");
    }
  }, []);

  return (
    <>
      {/* <div className="col py-3">
                <h3>Admin Portal</h3>
                <h5 className="lead">
                    This is admin Panel.</h5>
                <ul className="list-unstyled">
                    <li><h6>You can navite to the link in the Right Menu.</h6> </li>
                </ul>
            </div> */}

      <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
        <a
          href="/"
          className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
        >
          {/* <span className="fs-5 d-none d-sm-inline bg-black">Menu</span> */}
        </a>
        <ul
          className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
          id="menu"
        >
          {/* <li className="nav-item">
                        
                        <Link to='/admin'>
                            <a href="#/" className="nav-link align-middle px-0">
                                <i className="bi bi-house"></i> <span className="ms-1 d-none d-sm-inline">Home</span>
                            </a>
                        </Link>
                    </li> */}
          {/* <li>
                        <a href="#submenu1" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                            <i className="fs-4 bi-speedometer2"></i> <span className="ms-1 d-none d-sm-inline">Dashboard</span> </a>
                        <ul className="collapse show nav flex-column ms-1" id="submenu1" data-bs-parent="#menu">
                            <li className="w-100">
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 1 </a>
                            </li>
                            <li>
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 2 </a>
                            </li>
                        </ul>
                    </li> */}
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-xl-2">
                <Link to="/admin/rules">
                  <div
                    class="card bg-c-blue order-card"
                    style={{ height: "120px", width: "160px" }}
                  >
                    <div class="card-block">
                      <h2 class="text-right text-yellow">
                        {" "}
                        <i className="fa fa-code-fork"></i>
                        <span className="ms-1 d-none d-sm-inline ">Rules</span>
                      </h2>
                      {/* <h6 class="m-b-20">Rules for EkSarthi</h6> */}

                      {/* <p class="m-b-0">Completed Orders<span class="f-right">351</span></p> */}
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-6 col-xl-2">
                <Link to="/admin/content">
                  <div
                    class="card bg-c-green order-card"
                    style={{ height: "120px", width: "160px" }}
                  >
                    <div class="card-block">
                      <h2 class="text-right">
                        {" "}
                        <i className="fa fa-database"></i>
                        <span className="ms-1 d-none d-sm-inline">Content</span>
                      </h2>
                      {/* <h6 class="m-b-20">Content Details</h6> */}

                      {/* <p class="m-b-0">Completed Orders<span class="f-right">351</span></p> */}
                    </div>
                  </div>

                  {/* <a href="#/" className="nav-link px-0 align-middle"> */}
                </Link>
              </div>
              <div className="col-md-6 col-xl-2">
                <Link to="/admin/order">
                  {/* <a href="#/" className="nav-link px-0 align-middle "> */}
                  <div
                    class="card bg-c-pink order-card"
                    style={{ height: "120px", width: "160px" }}
                  >
                    <div class="card-block">
                      <h2 class="text-right">
                        <i className="fa fa-cart-plus f-left"></i>
                        <span className="ms-1 d-none d-sm-inline font-bold">
                          Orders
                        </span>
                      </h2>
                      {/* <h6 class="m-b-20">Orders Received</h6> */}

                      {/* <p class="m-b-0">Completed Orders<span class="f-right">351</span></p> */}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-6 col-xl-2">
                <Link to="/admin/usercontrol" nav-link px-0 align-end>
                  {/* <a href="#/" class="nav-link px-0 align-middle"> */}
                  <div
                    class="card bg-c-yellow order-card"
                    style={{ height: "120px", width: "160px" }}
                  >
                    <div class="card-block">
                      <h2>
                        {" "}
                        <i class="bi bi-person-lock"></i>
                        <span class="ms-1 d-none d-sm-inline font-bold">
                          User
                        </span>
                      </h2>
                      {/* <h6 class="m-b-20">EkSarthi Users</h6> */}
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-6 col-xl-2">
                <Link to="/admin/citybrowse">
                  {/* <a href="#/" className="nav-link px-0 align-middle "> */}
                  <div
                    class="card bg-c-red order-card"
                    style={{ height: "120px", width: "160px" }}
                  >
                    <div class="card-block">
                      <h2 class="text-right">
                        <i class="bi bi-geo-alt"></i>
                        <span class="ms-1 d-none d-sm-inline">City</span>
                      </h2>
                      {/* <h6 class="m-b-20">Orders Received</h6> */}

                      {/* <p class="m-b-0">Completed Orders<span class="f-right">351</span></p> */}
                    </div>
                  </div>
                </Link>
              </div>

              {/* <li  className="col-md-6 mb-3">
                   
                        <Link to='/admin/flag-ads'> */}
              {/* <a href="#/" class="nav-link px-0 align-middle"> */}
              {/* <div className="card bg-success text-white" style={{height:"50px",width:"200px" }}>
<div className="card-body">
                            <i class="bi bi-badge-ad"></i><span class="ms-1 d-none d-sm-inline">Flag Ads</span>
                            </div>
                                </div>
                        </Link>
                        
                    </li>  */}

              <div className="col-md-3 col-xl-2">
                <Link to="/admin/flag-ads" nav-link px-0 align-end>
                  {/* <a href="#/" class="nav-link px-0 align-middle"> */}
                  <div
                    class="card bg-c-purple order-card"
                    style={{ height: "120px", width: "160px" }}
                  >
                    <div class="card-block">
                      <h2 class="text-right">
                        <i class="bi bi-geo-fill"></i>
                        <span class="ms-0 d-none d-sm-inline">FlagAds</span>
                      </h2>

                      {/* <h6 class="m-b-15">Flag Ads</h6> */}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-0 col-xl-6">
                <Chart />
              </div>

              <Chart />
            </div>
          </div>

          {/* <li>
                        <a href="#submenu2" data-bs-toggle="collapse" className="nav-link px-0 align-middle ">
                            <i className="fs-4 bi-bootstrap"></i> <span className="ms-1 d-none d-sm-inline">Bootstrap</span></a>
                        <ul className="collapse nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                            <li className="w-100">
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 1</a>
                            </li>
                            <li>
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 2</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#submenu3" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                            <i className="fs-4 bi-grid"></i> <span className="ms-1 d-none d-sm-inline">Products</span> </a>
                            <ul className="collapse nav flex-column ms-1" id="submenu3" data-bs-parent="#menu">
                            <li className="w-100">
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Product</span> 1</a>
                            </li>
                            <li>
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Product</span> 2</a>
                            </li>
                            <li>
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Product</span> 3</a>
                            </li>
                            <li>
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Product</span> 4</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#/" className="nav-link px-0 align-middle">
                            <i className="fs-4 bi-people"></i> <span className="ms-1 d-none d-sm-inline">Customers</span> </a>
                    </li> */}
        </ul>
        <hr />
        {/* {adminLoggedIn && (
          <div className="dropdown pb-4 ">
            <a
              href="#/"
              className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
              id="dropdownUser1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={userDetail?.adminProfileImg}
                alt="admin"
                width="30"
                height="30"
                className="rounded-circle"
              />
              <span className="d-none d-sm-inline mx-1 bg-black">
                {userDetail?.adminName}
              </span>
            </a>
            <ul
              className="dropdown-menu dropdown-menu-dark text-small shadow"
              aria-labelledby="dropdownUser1"
            >
              <li>
                <a className="dropdown-item" href="#/" onClick={logoutHandler}>
                  Sign out
                </a>
              </li>
            </ul>
          </div>
        )} */}
      </div>
    </>
  );
};

export default Adminbody;