import React, { useEffect, useState } from 'react';
import { AutoComplete, Input } from 'antd';
import fetchFunction from '../utils/fetch';
import apiMapper from '../utils/apiMapper';


const searchResult = async (query) => {
    const response = await fetchFunction.get(apiMapper.Search_BY_city+encodeURI(query));
    const cities  = response.data.data.message;
    // 

    const setMap = new Set();
    const finalList = [];
    cities.map((city, idx) => {
        const cityTitle = city.name[0].toUpperCase() + city.name.slice(1, city.name.length);
        const category = `${cityTitle}, ${city.stateCode}, ${city.countryCode}`;
        // console.log(category)
        if (!setMap.has(category) ){
          finalList.push({
            key: city._id,
            value: category,
            label: (
              <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                }} >
                <span> {category} </span>
              </div>
            ),
          });
          setMap.add(category);
        }
         
      });
    return finalList;
  };



const Searchbar = ({handler, defaultValue,keydownEventFire=null}) => {
   const [options, setOptions] = useState([]);
   const [isEmpty, setIsEmpty] = useState(true);

  const handleSearch = async (value) => {
    setIsEmpty(value.trim() === '');
    setOptions([]);
    value = value.toLowerCase()
    const searchData = await searchResult(value);
    
    setOptions(searchData.length ? searchData : []);
  };

  const onSelect = (value, option) => {
   
    handler({cityId:option.key,cityValue:value});
  
  };


  const key_downHandler = (e)=>{
      if(keydownEventFire) keydownEventFire
      ({key:e.key,keyCode:e.keyCode});
   
  
  };
  
  return (
    <AutoComplete
      popupMatchSelectWidth={252}
      style={{
        width: "100%",
        height:"100%",
        // borderColor:isEmpty ? '1px solid red' : '', // Set border color to red if empty
      }}
      options={options}
      onSelect={(value,option)=> onSelect(value, option)}
      onSearch={handleSearch}
      defaultValue = {defaultValue || ""}
      size="large"
      id='city'
      onKeyDown={key_downHandler}  
      >
        
      <Input size="large" placeholder="search city" name='city'
      required
      // style={{ borderColor: isEmpty ? 'red' : '',
      //           borderWidth: '2px'}}
      onChange={(e) => setIsEmpty(e.target.value.trim() === '')}
      />
    </AutoComplete>
  );
};
export default Searchbar;