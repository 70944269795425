import { useEffect, useState } from "react"
import fetcher from "../../utils/fetch";
import apiMapper from "../../utils/apiMapper";
import config from "../../utils/config";
import { useNavigate } from "react-router-dom";


const AdmincityBrowse = () => {

    const [allcity, setAllCity] = useState([]);
    const [city, setCity] = useState('')
    const navigate = useNavigate();

    const getAllCity = async () => {
        const response = await fetcher.get(apiMapper.GET_COUNTRYCODE)

        // console.log("response", response);
        if ([200, 201].includes(response?.status)) {
            setAllCity(response?.data?.data?.message)
        }
    }

    useEffect(() => {
        getAllCity();

    }, []);

    const addCity = async() => {

        // console.log("city", [city])
        const headers = { ...config.headers };
        headers['Authorization'] = localStorage.getItem('Admin-Authorization');
        // console.log("headers",headers);
        const addCityResponse = await fetcher.post(apiMapper.REFRESH_CITYLIST, { cities: [city] }, config.host,headers);
        // console.log("addCityResponse",addCityResponse);
        if ([200, 201].includes(addCityResponse?.status)) {
             alert("Please wait half an hour your city will be added soon...");
        }else if ([401].includes(addCityResponse?.status)) {
            localStorage.clear();
            localStorage.clear();
            navigate('/admin/login')
          }
    }
    

    return (
        <>
            <div className="container pt-2">
                <div className="row">
                    <h2>Add cities for a selected country :-</h2>
                </div>
                <div className="row mt-3">
                    <div className="col-4 offset-2">

                        <select class="btn btn-light" onChange={(e) => { setCity(e.target.value) }} name='city' id='city'>
                            <option selected value='' disabled>Select Country</option>
                            {
                                allcity?.map((ele, i) => {
                                    return <option value={ele?.isoCode}>{ele?.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-2">
                        <button className="main-btn" onClick={addCity}>update</button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AdmincityBrowse