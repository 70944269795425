import { useState, useEffect, useRef } from "react";
import apiMapper from "../utils/apiMapper";
import fetcher from "../utils/fetch";
import { useNavigate } from "react-router-dom";
import Spinner from "./spinner";
import "../components/css/registeradd.css";
// import Searchbar from "./Serchbar";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import fetchFunction from "../utils/fetch";
import schema from "../utils/schema";
import moment from 'moment';
import { useMediaQuery } from "react-responsive";
import config from "../utils/config";
import common from "../utils/common";
import GeoMap from "./geomap";
import dayjs from 'dayjs';
import { DatePicker, Space } from 'antd';
import { addSlash, removeHostUrl } from "../utils/helper";


const Addlisting = ({ userAddData, isMembershipEnabled }) => {

  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const maxColDesktop = 6;
  const maxColMobile = 2;

  const [userAdType, setUserAdType] = useState('')
  const [loading, setLoading] = useState(false);
  const [roomTypeCheck, setRoomTypeCheck] = useState(userAddData === undefined ? {'private-room':true}:{});
  const [furnishingTypeCheck, setFurnishingTypeCheck] = useState(userAddData === undefined ? {'unfurnished':true}:{});
  // const [prevShowRules, setPrevShowRules] = useState(false);
  // const [selectedRadioValue, setSelectedRadioValue] = useState('');
  const [checkboxData, setCheckboxData] = useState({});
  const [previewOpen, setPreviewOpen] = useState(false);
  // const [selectedUserType, setSelectedUserType] = useState('');
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [rules, setRules] = useState([]);
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();
  // const selectRef = useRef();
  const [userEditData, setUserEditData] = useState({});
  // const [userAdType, setUserAdType] = useState('')
  const [location, setLocation] = useState(null);
  const [attachBath, setAttachBath] = useState({ value: 'false' });
  // const [city, setCity] = useState();
  const [preloading, setpreLoading] = useState(true);

  const [query, setQuery] = useState('');
  const [debounced, setDebounced] = useState('');
  const [loggedinData, setLoggedinData] =  useState({});

  const [active, setActive] = useState("");
  const [showRules, setShowRules] = useState(false);

   // function to handle toggle between List a Room and Find a Room
  const actvieHandler = (userType) => {
    setActive(userType);
    setShowRules(userType === 'owner');
    
    // Reset roomTypeCheck & furnishingTypeCheck based on userType
    if (userType === 'owner') {
      setRoomTypeCheck({ 'private-room': true });
      setFurnishingTypeCheck({ 'unfurnished': true });
    } else {
      // In 'tenant' mode (Find a Room), keep 'private-room' selected as default
      setRoomTypeCheck({ 'private-room': true });
      setFurnishingTypeCheck({ 'unfurnished': true });
    }
  };

   useEffect(() => {
    if (!userEditData?.userType || userEditData?.userType === "N/A" || userEditData.length < 1) {
      setActive("owner");
      setShowRules(true); // default is set to "owner"
    } else {
      setActive(userEditData.userType);
      setShowRules(userEditData.userType === "owner");
    }
  }, [userEditData?.userType]);

  useEffect(()=> {
    if (localStorage.getItem('Authorization')){
      const userKeys = ["name", "gender", "email", "profileImg", "id", "userType" ];
      const userData = {};
      userKeys.map((key)=> {
        if (localStorage?.[key]) userData[key] = localStorage[key];
      
      })
      
      if (userData != {}){
        setLoggedinData(userData);
      }
    }
    
  }, [])
  // const loginDataHandler = (respData)=> {
    
  //   setLoggedinData(respData);
  // };
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      if(query !== ''){
        if(query.file?.status ==="removed"){
          setDebounced([query.file]);
        } else {
          setDebounced(query.fileList);

        }

      }
    }, 200);
    return () => { clearTimeout(timeout) }
  }, [query])

  //owner schema
  const ownerSchema = schema.ownerSchema();


  // DatePicker
  const dateFormatList = ['YYYY/MM/DD'];


  // photo upload code
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(removeHostUrl(file.url) || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        Upload
      </div>
    </div>
  );
  const beforeUpload = (file, fileList) => {
    // Access file content here and do something with it
    // console.log('beforeUpload', file)

    // Prevent upload
    return false
  }
  const handleFileUpload = async (fileL) => {

  
    const imagePriviewDataList = [];
    const formData = new FormData();
    let toUploadCheck =  false;
    for (let index = 0; index < fileL.length; index++) {
      const file = fileL[index];
      if (file?.status !== 'done' && file?.originFileObj) {
        
        formData.append("file", file?.originFileObj);
        toUploadCheck = true;
        // make a POST request
        
      } else if (file?.status === 'removed') {
        const imageUrl = removeHostUrl(file?.url, true);
        const deletePayload = { "files": [imageUrl] }
        const deleteImageResp = await fetchFunction.delete(apiMapper.UPLOAD_FILE, deletePayload);
        if (deleteImageResp?.status === 200) {
          setFileList(fileList.filter((e) => {
            if (e.url !== addSlash(imageUrl)) return true;
          }))
        }
  
      }

    }
    if (toUploadCheck){
      const uploadResponse = await fetchFunction.upLoadFile(
        apiMapper.UPLOAD_FILE,
        formData
      );

      if ([200, 201].includes(uploadResponse?.status)) {
        const respFiles = uploadResponse.data.files;

        for (let index = 0; index < respFiles.length; index++) {
          const image = respFiles[index];

          const imagePriviewData = {
            uid: image.split("/").reverse()[0].split(".")[0],
            name: image.split("/").reverse()[0],
            status: "done",
            url: removeHostUrl(image),
          };

          imagePriviewDataList.push(imagePriviewData);
        }
        
      }

      setFileList([...fileList, ...imagePriviewDataList]);

    }
    
    
  };

  useEffect(() => {
    handleFileUpload(debounced);
  }, [debounced]);

  // const handleChange = ({ fileList: newFileList }) => {
  //   setFileList(newFileList)
  // };
  async function getRule() {
    const ruleData = await fetcher.get(apiMapper.GET_RULES);

    if ([200, 201].includes(ruleData?.status)) {

      if (ruleData?.data?.data) setRules(ruleData?.data?.data);
    }
  }

  async function getAdds() {

    const addsResp = await fetcher.get(apiMapper.GET_ADDS, config.host, common.getHeaders());
      
    if ([200, 201].includes(addsResp?.status)) {
      if (Object.keys(addsResp?.data?.data || {}).length) {
        if (!Object.keys(userEditData).length > 0 && !userAddData) {
          alert("Looks Like you already have listed advertisement!!");
          navigate('/adds/')
        }
       
      }else{
      
        setpreLoading(false);
      }

    }
  }
  // console.log("Object.keys(userEditData).length", Object.keys(userEditData).length)
  const listingValidation = () => {
    (function () {


      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.querySelectorAll('.required-validation')
        ;
      // Loop over them and prevent submission
      Array.prototype.slice.call(forms)
        .forEach(function (form) {
          form.addEventListener('submit', function (event) {
            if (!form.checkValidity()) {
              event.preventDefault()
              event.stopPropagation()
            }
            form.classList.add('was-validated')

          }, false);

        })

    })()
  }
  useEffect(() => {

    const isValid = common.isLoggedIn();
    if (!isValid) {
      localStorage.clear();
      localStorage.clear();
      navigate('/')
      navigate(0);
    }

    if (userAddData) {
      setUserAdType(userAddData.userAdType);
    }
    if (userAddData) {
      setUserEditData(userAddData?.data || {});
    }

    getAdds();
    getRule();
    listingValidation();

  }, [])

  async function updateEditValuesOwner() {
    setpreLoading(false);
    let schemaLocal = null;
    schemaLocal = await schema.ownerSchema();
    if(userEditData.rent<=0 || userEditData===""){
      userEditData.rent=0
    }
    if (Object.keys(userEditData).length) {

      var forms = document.querySelectorAll(`.ownerFormSelector`);
      if (forms.length) {
        const form = forms[0];
        (Object.keys(schemaLocal) || []).map((key) => {
          if (key === 'city') {
            return
          }

          if (key === 'rules') {
            return
          }
          if (schemaLocal[key]?.values) {
            const values = schemaLocal[key]?.values || [];

            values.map((subArray, i) => {
              const value = subArray.value;
              const subkey = subArray.id;
              if (subArray?.inputArray) {
                // input array code here
                const matchedData = userEditData?.[key].filter((e) => {
                  if (e?.[subkey] === value) return true
                })
                if (matchedData.length) {
                  if (['radio', 'checkbox'].includes(form?.[subkey + (i + 1)]?.type)) {
                    form[subkey + (i + 1)].click();
                  }
                  (subArray?.inputArray || []).map((inputField) => {
                    form[inputField + (i + 1)].value = matchedData[0][inputField];
                  })
                }

                // console.log(userEditData.rent)
              } else {
                if (typeof ((userEditData?.[key])) === 'object') {
                  const matchedData = userEditData?.[key].filter((e) => {
                    if (e?.[subkey] === value) return true
                  })
                  if (matchedData.length) {
                    if (['radio', 'checkbox'].includes(form?.[subkey + (i + 1)]?.type)) {
                      form[subkey + (i + 1)].click();
                    }
                  }
                } else if (userEditData?.[key] === value) {
                  if (['radio', 'checkbox'].includes(form?.[subkey + (i + 1)]?.type)) {
                    form[subkey + (i + 1)].click();
                  }
                }
              }
            })
          } else if (schemaLocal[key].id) {

            const subkey = schemaLocal[key].id;

            if (userEditData?.[subkey] && form?.[subkey]) form[subkey].value = subkey === 'attachedBath' ? String(userEditData?.[subkey]) : userEditData?.[subkey];

          }

        })

        //location
        if (userEditData?.location) {
          setLocation(userEditData?.location);
        }

        // city
        // if (userEditData?.city) {
        //   setCity(userEditData?.city._id);
        //   // form.city.value = `${userEditData?.city.name}, ${userEditData.city.countryCode}`
        // }
        // photos
        const photos = [];
        (userEditData?.photos || []).map((e) => {
          const imagePriviewData = {
            uid: e.split("/").reverse()[0].split(".")[0],
            name: e.split("/").reverse()[0],
            status: "done",
            url: e,
          };
          photos.push(imagePriviewData);

        })
        if (photos.length) setFileList(photos);

      }
    }

  }

  useEffect(() => {

    if (Object.keys(userEditData).length) updateEditValuesOwner();
  }, [userEditData])

  useEffect(() => {
    // rules

    var forms = document.querySelectorAll('.ownerFormSelector');
    if (forms.length) {
      const form = forms[0];
      const ruleLength = (rules || []).length;
      (userEditData?.rules || []).map((e) => {
        Array.apply(null, Array(ruleLength)).map((j, i) => {
          if (form['rule' + (i + 1)]?.value === e._id) {
            form['rule' + (i + 1)].click();
          }
        })

      })
    }



  }, [rules])


  const formDataParser = (schema, event) => {
    const finalData = {};
    for (var key in schema) {
      if (schema[key].type === String) {
        finalData[key] = event.target?.[key]?.value;
      } else if (schema[key].type === Array) {
        const arrayElements = schema[key]?.values || [];
        finalData[key] = [];

        for (let index = 0; index < arrayElements.length; index++) {
          const element = arrayElements[index];
          const elementId = element.id + (index + 1);

          if (event.target.elements?.[elementId]?.checked) {
            const elementData = {};
            elementData[element.id] = event.target.elements?.[elementId]?.value;
            finalData[key].push(elementData);
          }
        }
      }
    }
    finalData.userType = active;
    // console.log("final Data", finalData)
    return finalData;
  };

  const submitRegistrationForm = async (event) => {

    // setLoading(true);
    var finalData = null;
    let photos = [];
    fileList.map((ele, i) => {
      photos.push(removeHostUrl(ele.url));
      return null;
    });
    finalData = formDataParser(ownerSchema, event);
    // finalData.cityId = city;
    if (location) finalData.location = location;
    finalData.photos = photos;

    finalData.rules = (finalData.rules || []).map((e) => { return e.rule });
    if (Object.keys(userEditData).length && userAdType) {
      if (userAdType === 'owner' || userAdType === 'tenant') {
        // console.log(userEditData)
        // console.log("finaldata",finalData)
        const listingReg_Response = await fetcher.put(apiMapper.UPDATE_OWNER, finalData, config.host, common.getHeaders());
        if ([200, 201].includes(listingReg_Response?.status) && listingReg_Response?.data?.status === "SUCCESS") {
          setLoading(true);
          setTimeout(() => {
            navigate(0);
            navigate("/adds/");
          }, 2000);
        } else {
          setLoading(false);
        }
        if ([400, 401].includes(listingReg_Response?.status)) {
          localStorage.clear();
          localStorage.clear();
          navigate('/')
          navigate(0);
        }
      }
    } else {
      const listingUpdateReg_Response = await fetcher.post(apiMapper.CREATE_LISTING_ADD, finalData, config.host, common.getHeaders());
      if ([200, 201].includes(listingUpdateReg_Response?.status) && listingUpdateReg_Response?.data?.status === "SUCCESS") {
        setLoading(true);
        setTimeout(() => {
          if(isMembershipEnabled){
            if (localStorage.getItem('membershipStatus') === 'true')  {
              navigate("/adds");
            } else{
              navigate("/membership");
            }
          }
          else{
            navigate("/adds");
          }
          navigate(0);
        }, 2000);
      } else {
        setLoading(false);
      }
      if ([400, 401].includes(listingUpdateReg_Response?.status)) {
        localStorage.clear();
        localStorage.clear();
        navigate('/')
        navigate(0);
      }
    }
    ;

  };


  // owner handlerRoomTypeCheck

  // Modify handlerRoomTypeCheck to support single and multiple selection modes
  const handlerRoomTypeCheck = (event) => {
    const { value, checked } = event.target;

    if (showRules) { // 'List a Room' mode (single selection)
      setRoomTypeCheck({ [value]: checked }); // Reset the room type when selected
    } else { // 'Find a Room' mode (multiple selections)
      setRoomTypeCheck(prevState => ({ ...prevState, [value]: checked }));
    }
  }

  // Modify handlerFurnishingCheck to support single and multiple selection modes
  const handlerFurnishingCheck = (event) => {
    const { value, checked } = event.target;

    if (showRules) { // 'List a Room' mode (single selection)
      setFurnishingTypeCheck({ [value]: checked }); // Reset the furnishing type when selected
    } else { // 'Find a Room' mode (multiple selections)
      setFurnishingTypeCheck(prevState => ({ ...prevState, [value]: checked }));
    }
  }

  // useEffect(() => {
  //   // Room type will clear if user type change
  //   // clearSelectedRoomTypes();
  //   setPrevShowRules(showRules);
  // }, [showRules]);

  // const clearSelectedRoomTypes = () => {
  //   setRoomTypeCheck(userAddData === undefined ? {'private-room':true}:{});
  // };

  const handlerAmenitiesClick = (event) => {

    const data = { ...checkboxData };

    if (event.target.checked) {
      data[event.target.id] = true;
    } else {
      data[event.target.id] = false;
    }
    setCheckboxData({ ...data });
  };


  //location searchbar Handler

  // const locationSearchHandler = (searchvalue) => {

  //   if (searchvalue.cityId) setCity(searchvalue.cityId);
  // };

  // onSubmit Owner Form
  const onSubmitRegisterAddOwnerHandler = async (event) => {
    event.preventDefault();
    if(active===""){
      alert("please choose either List a Room or Find a Room")
      return
    }
    await submitRegistrationForm(event);
  };

  const selectHandler = (event) => {
    if (event.target.className.includes('active')) {
      event.target.className = event.target.className.replace('active', '').trim();
    } else {
      event.target.className = event.target.className.trim() + ' active';
    }
  };

  const geoDataHandler = (address, lat, lng) => { setLocation({ address, lat, lng }) }

  const attachbathhandleChange = (event) => {
    setAttachBath({ ...attachBath, value: event.target.value });
  };

  function capitalizeFirstLetter(string) {
    // Check if the string is null, undefined, or empty after trimming
      if (!string || typeof string !== "string" || string.trim() === "") {
          return ""; // Return an empty string if the input is null, undefined, or empty
      } else {
          return string.charAt(0).toUpperCase() + string.slice(1);
      }
  }

  return preloading ? <Spinner /> : ((!common.isLoggedIn()) ? (<Spinner />) : (
    <>
      {loading ? (<div className="container success-message pt-4 pt-md-0">
        <div className="thanks-message text-center py-4 my-md-0" id="text-message"> <img src="https://i.imgur.com/O18mJ1K.png" width="100" alt="message" className="mb-4" />
          <h3>{Object.keys(userEditData).length ? (<><span>Updated successfully!!</span> <p>Put Your Advertisement on EkSarathi</p></>) : <><span>Listing Added!!</span> {isMembershipEnabled ? localStorage.getItem('membershipStatus') === 'true' ? <p>You will be redirected to Home screen.</p> :<p>Please avail the Membership, so that potential users can contact you.</p>: ''} </>}</h3>
        </div>
      </div>) : (<div className=" container-fluid  min-vh-100 px-0 registerADD">
        <div className="container registeraddcontainer" style={{ backgroundColor: "white" }}>
          <div className="mt-md-0 mt-5">
            <div className="row ">
              <h3 className="text-center mt-1 mainheading">Put Your Advertisement on EkSarathi:</h3>
            </div>

            <div className="owner-registration" >
              <form onSubmit={onSubmitRegisterAddOwnerHandler} className="ownerFormSelector ">


                <div className="col-md-9 offset-md-1 col-9 offset-1 mt-lg-3">
                  {/* Map  */}
                  <div className="container toggler-tenant-owner">
                    <div className="col-lg-6 col-12">
                      <div className="tab row myradius">
                          <div className={"col-md-6 owner " + (active === "owner" ? "active" : "inactive")}>
                              <a href="#!" onClick={() => { actvieHandler("owner"); }} className="text-lg" style={{fontSize:"95%"}}>
                                  List a Room
                              </a>
                          </div>
                          <div className={"col-md-6 tenant " + (active === "tenant" ? "active" : "inactive")}>
                              <a href="#!" onClick={() => { actvieHandler("tenant"); }} className="text-lg" style={{fontSize:"95%"}}>
                                  Find a Room
                              </a>
                          </div>
                      </div>
                    </div>

                  </div>

                  <div className="heading mt-3">Location*</div>
                  <div className="group-inputs  " >
                     <GeoMap geoDataHandler={geoDataHandler} userEditData={userEditData} id="req"/>
                  </div>


                  {/* Location  */}
                  {/* <div className="row mt-4">
                    <div className="heading">Location*</div>
                    <div className=" col-md-4 col-6 ">
                      <div className="city-search-input">
                        {(Object.keys(userAddData || {}).length > 0 && userEditData?.city) && <Searchbar handler={locationSearchHandler}
                          defaultValue={userEditData?.city ? `${capitalizeFirstLetter(userEditData?.city.name)}, ${userEditData?.city.stateCode}, ${userEditData.city.countryCode}` : ""} />
                        }
                        {!(Object.keys(userAddData || {}).length > 0 && userEditData?.city) && <Searchbar handler={locationSearchHandler} 
                        defaultValue="" />
                        }          

                      </div>
                    </div> */}
                    {/* <div className=" col-md-4 col-6 ">
                      <div className="group-inputs">
                        <label htmlFor="locality">Area</label>
                        <input
                          type="text"
                          className="form-control"
                          id="locality"
                          name="locality"
                          aria-describedby="areaHelp"
                        /> */}
                          {/* <input
                          type="text"
                          className="form-control"
                          id="userType"
                          name="userType"
                          value={(loggedinData?.userType).split(' ')[0]}
                          style={{ display: 'none' }}
                        /> */}
                      {/* </div>
                    </div>
                  </div> */}

                  {/* ---ownerRoomtype----- */}
                  {showRules ? (
                    <>
                    <div className="row mt-4">
                    <div className="heading">
                      {(showRules ? "Sharing type you are Providing*": "")}
                    </div>
                    <div className={"row row-cols-2 amenities" + (isDesktop ? "" : " row-cols-2")}>
                      {ownerSchema?.roomTypes?.values?.map((element, i) => {
                        return (
                          <>
                            <div key={i} className="amenties-main col-6 col-md-2 text-center" >
                              <div className="form-check form-check-inline pt-2">
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name={element.id}
                                  id={element.id + (i + 1)}
                                  value={element.value}
                                  autoComplete="off"
                                  onChange={handlerRoomTypeCheck}
                                  required
                                  checked={roomTypeCheck[element.value] || false}
                                />
                                <label className="btn form-check" htmlFor={element.id + (i + 1)}>
                                  <div>
                                    {" "}
                                    <img className={"icon-class" + (roomTypeCheck?.[element.value] ? "-checked" : "")}
                                      src={element.img}
                                      alt={element.meta}
                                    ></img>
                                  </div>
                                  <p className={"icon-class-text" + (roomTypeCheck?.[element.value] ? "-checked" : "")}>
                                    {element.meta}
                                  </p>
                                </label>

                              </div>
                            </div>
                          </>
                        );
                      })}
                      <div className="invalid-feedback">
                        Roomtype is required
                      </div>
                    </div>
                  </div>
                    </>
                    ) : (
                      <>
                      <div className="row mt-4">
                      <div className="heading">
                        {(showRules ? "": "Sharing type I am looking For")}
                      </div>
                      <div className={"row row-cols-2 amenities" + (isDesktop ? "" : " row-cols-2")}>
                        {ownerSchema.roomTypes.values.map((element, i) => {
                          return (
                            <>
                              <div key={i} className="amenties-main col-6 col-md-2 text-center" >
                                <div className="form-check form-check-inline pt-2">
                                  <input
                                    type="checkbox"
                                    className="btn-check"
                                    name={element.id}
                                    id={element.id + (i + 1)}
                                    value={element.value}
                                    autoComplete="off"
                                    onChange={handlerRoomTypeCheck}
                                    checked={roomTypeCheck[element.value] || false}
                                    // required
                                  />
                                  <label className="btn form-check" htmlFor={element.id + (i + 1)}>
                                    <div>
                                      {" "}
                                      <img className={"icon-class" + (roomTypeCheck?.[element.value] ? "-checked" : "")}
                                        src={element.img}
                                        alt={element.meta}
                                      ></img>
                                    </div>
                                    <p className={"icon-class-text" + (roomTypeCheck?.[element.value] ? "-checked" : "")}>
                                      {element.meta}
                                    </p>
                                  </label>

                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      </div>
                      </>
                    )
                  }
                  

                  {/* --ownerprofession and organisation---- */}
                  <div className="row mt-4 mt-md-3">
                    <h3 className="pb-2 heading">Profession & Organization</h3>
                    <div className="col-md-4 col-6">
                      <div className="group-inputs">
                        <label htmlFor={ownerSchema.profesion.id}>
                          <p>Profession</p>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id={ownerSchema.profesion.id}
                          name={ownerSchema.profesion.id}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-6 ">
                      <div className="group-inputs">
                        <label htmlFor={ownerSchema.organisation.id}>Organization</label>
                        <input
                          type="text"
                          className="form-control"
                          id={ownerSchema.organisation.id}
                          name={ownerSchema.organisation.id}
                        />
                      </div>
                    </div>
                  </div>

                  {/* owner furnishing types */}

                  <div className="row mt-4 ">
                    <div className="heading">Furnishing Type</div>
                    <div className="row amenities">
                      {ownerSchema.furnishingTypes.values.map(
                        (element, i) => {
                          return (
                            <>
                              <div className={"amenties-main col-6 col-md-2 text-center"}>
                                <div className="form-check form-check-inline pt-2">
                                  <input
                                    type={showRules ? "radio":"checkbox"}
                                    className="btn-check"
                                    id={element.id + (i + 1)}
                                    name={element.id}
                                    value={element.value}
                                    autoComplete="off"
                                    onChange={handlerFurnishingCheck}
                                    checked={furnishingTypeCheck[element.value] || false}
                                  />
                                  <label className="btn form-check" htmlFor={element.id + (i + 1)}>
                                    <div>
                                      {" "}
                                      <img className={"icon-class" + (furnishingTypeCheck?.[element.value] ? "-checked" : "")}
                                        src={element.img}
                                        alt={element.meta}
                                      ></img>
                                    </div>
                                    <p className={"icon-class-text" + (furnishingTypeCheck?.[element.value] ? "-checked" : "")}>
                                      {element.meta}
                                    </p>
                                  </label>
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                    </div>
                  </div>

                  {/* room rent Info */}
                  <div className="row mt-4 mt-md-3">
                  {showRules ? (
                    <>
                      <h3 className="pb-2 heading">Room Rent Info*</h3>
                      <div className="col-md-4 col-6">
                        <div className="group-inputs has-validation">
                          <label htmlFor={ownerSchema.rent.id}>
                            <p>Rent*</p>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id={ownerSchema.rent.id}
                            name={ownerSchema.rent.id}
                            placeholder="$"
                            required
                          />
                          <div className="invalid-feedback">
                            Rent is required*
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <h3 className="pb-2 heading">Room Rent Upto</h3>
                      <div className="col-md-4 col-6">
                        <div className="group-inputs has-validation">
                          <label htmlFor={ownerSchema.rent.id}>
                            <p>Rent</p>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id={ownerSchema.rent.id}
                            name={ownerSchema.rent.id}
                            placeholder="$"
                          />
                        </div>
                      </div>
                    </>
                  )}

                    
                    {(showRules ? 
                      <div className="col-md-4 col-6 ">
                        <div className="group-inputs has-validation">
                          <label htmlFor={ownerSchema.deposit.id}>Deposit*</label>
                          <input
                            type="number"
                            className="form-control"
                            id={ownerSchema.deposit.id}
                            name={ownerSchema.deposit.id}
                            placeholder="$"
                            required
                          />
                          <div className="invalid-feedback">
                            Deposit is required
                          </div>
                        </div>
                      </div>
                      : 
                      <div className="col-md-4 col-6 ">
                        <div className="group-inputs has-validation">
                          <label style={{ display: 'none' }} htmlFor={ownerSchema.deposit.id }>Deposit*</label>
                          <input
                            type="number"
                            className="form-control"
                            id={ownerSchema.deposit.id}
                            name={ownerSchema.deposit.id}
                            placeholder="$"
                            value="0"
                            style={{ display: 'none' }}
                            required
                          />
                          <div className="invalid-feedback">
                            Deposit is required
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* --accomodates-- attachedBath-- */}
                  <div className="row mt-4 mt-md-4">
                    <h3 className="pb-2 heading">Accomodates & Attached Bath</h3>
                    <div className="col-md-4 col-6">
                      <div className="group-inputs">
                        <label htmlFor={ownerSchema.accomodates.id}>
                          <p>Accomodates</p>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id={ownerSchema.accomodates.id}
                          name={ownerSchema.accomodates.id}
                          placeholder="Number"

                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-6 ">
                      <div className="group-inputs">
                        <label htmlFor={ownerSchema.attachedBath.id}>Attach Bath</label>
                        <select id={ownerSchema.attachedBath.id} className="form-control form-select" onChange={attachbathhandleChange} >
                          {/* <option value=''>Select-AttachBath</option> */}
                          <option value='true' >Yes</option>
                          <option value='false' selected>No</option>
                        </select>

                      </div>
                    </div>
                  </div>
                  {/* --availableFrom-- */}

                  <div className="row mt-4 mt-md-4">
                  
                    <h3 className="pb-2 heading">{showRules ? "Available From": "Need From"}</h3>
                    <div className="col-md-4 col-6">
                      <div className="group-inputs">
                        {(Object.keys(userAddData || {}).length > 0 && userEditData?.availableFrom) && <Space direction="vertical" size={12}>
                          <DatePicker defaultValue={userEditData?.availableFrom ? dayjs(userEditData?.availableFrom, dateFormatList[0]) : ''}
                            format={dateFormatList}
                            id={ownerSchema?.availableFrom.id}
                            style={{
                              height: "53px",
                              width: "100%",
                              cursor: "pointer",
                              fontSize: "17px",
                              margin: "0px",
                              padding: "15px",
                              color: "black"
                            }} />
                        </Space>
                        }
                        {!(Object.keys(userAddData || {}).length > 0 && userEditData?.availableFrom) && <Space direction="vertical" size={12}>
                          <DatePicker defaultValue={dayjs(moment().format('YYYY/MM/DD'), dateFormatList[0])}
                            format={dateFormatList}
                            id={ownerSchema?.availableFrom.id}
                            style={{
                              height: "53px",
                              width: "100%",
                              cursor: "pointer",
                              fontSize: "17px",
                              margin: "0px",
                              padding: "15px",
                              color: "black"
                            }} />
                        </Space>
                        }
                      </div>
                    </div>
                  </div>

                  {/* --staytype-- */}
                  <div className="row mt-3">
                    <div className="heading my-3">Stay Type</div>

                    {ownerSchema.stayType.values.map((ele, i) => {
                      return (
                        <>
                          <div className={isDesktop ? "col-3" : "col-4"}>

                            <label
                              className=" select-label"
                              htmlFor={ele.id + (i + 1)}
                            >
                              <input
                                type="radio"
                                className="btn-check"
                                name={ele.id}
                                id={ele.id + (i + 1)}
                                value={ele.value}
                                defaultChecked={ele.value === "any"}
                              />
                              <span>{ele.meta}</span>

                            </label>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  {/* owner Offer For types */}
                  <div className="row mt-3">
                  <div className="heading my-3">{(showRules ? "Offering for": "Required for")}</div>

                    {ownerSchema.offerFor.values.map((ele, i) => {
                      return (
                        <>
                          <div className={isDesktop ? "col-3" : "col-4"}>

                            <label
                              className=" select-label"
                              htmlFor={ele.id + (i + 1)}
                            >
                              <input
                                type="radio"
                                className="btn-check"
                                name={ele.id}
                                id={ele.id + (i + 1)}
                                value={ele.value}
                                defaultChecked={ele.value === "any"}
                              />
                              <span>{ele.meta}</span>

                            </label>
                          </div>
                        </>
                      );
                    })}
                  </div>

                  {/* owner Lease Required types for Owner usertype only*/}
                  {(showRules && <div className="row mt-3">
                  <div className="heading my-3">Lease Required</div>

                    {ownerSchema.requiredLease.values.map((ele, i) => {
                      return (
                        <>
                          <div className={isDesktop ? "col-3" : "col-4"}>

                            <label
                              className=" select-label"
                              htmlFor={ele.id + (i + 1)}
                            >
                              <input
                                type="radio"
                                className="btn-check"
                                name={ele.id}
                                id={ele.id + (i + 1)}
                                value={ele.value}
                                defaultChecked={ele.value === "no"}
                              />
                              <span>{ele.meta}</span>

                            </label>
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}

                  {/* House Rule For types */}
                  {(showRules && (
                    <div className="row mt-4 ">
                      <div className="heading my-2">House Rules</div>
                      <div className="conatainer mx-2">
                        {rules.map((element, i) => {
                          return (
                            <>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={element._id}
                                  name={ownerSchema.rules.values[0].id}
                                  id={ownerSchema.rules.values[0].id + (i + 1)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={ownerSchema.rules.values[0].id + (i + 1)}
                                >
                                  {element.name}
                                </label>

                                {/* <div className="form-check form-check-inline pt-2">
                                  <input
                                    type="checkbox"
                                    className="btn-check"
                                    id={element.id + (i + 1)}
                                    name={element.id}
                                    value={element.value}
                                    autoComplete="off"
                                  />
                                  <label
                                    className="btn btn-outline-primary form-check"
                                    htmlFor={element.id + (i + 1)}
                                  >
                                    {element.meta}
                                  </label>
                                </div> */}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>)
                  )}
                  {/* Food type For types */}
                  <div className="row mt-4">
                    <div className="heading my-3">Diet Type</div>

                    {ownerSchema.dietType.values.map((ele, i) => {
                      return (
                        <>
                          <div className={isDesktop ? "col-3" : "col-6"}>

                            <label
                              className=" select-label"
                              htmlFor={ele.id + (i + 1)}
                            >
                              {(Object.keys(userAddData || {}).length > 0 && userEditData?.dietType) && <input
                                type="radio"
                                className="btn-check"
                                name={ele.id}
                                id={ele.id + (i + 1)}
                                value={ele.value}

                              />}
                              {(!Object.keys(userAddData || {}).length > 0 || !userEditData?.dietType) && <input
                                type="radio"
                                className="btn-check"
                                name={ele.id}
                                id={ele.id + (i + 1)}
                                value={ele.value}
                                defaultChecked={ele.value === 'Non-Vegetarian'}
                              />}
                              <span>{ele.meta}</span>

                            </label>
                          </div>
                        </>
                      );
                    })}
                  </div>

                  {/* --FoodService--
                  <div className="row mt-4 foodservice">
                    <div className="heading my-3">Food Service</div>

                    {ownerSchema.foodServices.values.map((ele, i) => {
                      return (
                        <>
                          <div className={isDesktop ? "col-3" : "col-4"}>

                            <label
                              className=" select-label"
                              htmlFor={ele.id + (i + 1)}
                            >
                              <input
                                type="checkbox"
                                className="btn-check"
                                name={ele.id}
                                id={ele.id + (i + 1)}
                                value={ele.value}
                              />
                              <span>{ele.meta}</span>

                            </label>
                          </div>
                        </>
                      );
                    })}
                  </div> */}

                  {/* Amenties For types */}
                  <div className="row mt-4">
                    <div className="heading my-1">Amenities</div>

                    {Array.apply(
                      null,
                      Array(
                        parseInt(ownerSchema.amenities.values.length / (isDesktop ? maxColDesktop : maxColMobile) + 1)
                      )
                    ).map((e, rowNumber) => {
                      return (
                        <>
                          <div className="row amenities">
                            {ownerSchema.amenities.values
                              .slice(rowNumber * (isDesktop ? maxColDesktop : maxColMobile), (rowNumber + 1) * (isDesktop ? maxColDesktop : maxColMobile))
                              .map((element, i) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        "amenties-main col-6 col-md-2 text-center"
                                      }
                                    >
                                      <div className="form-check form-check-inline pt-2">
                                        <input
                                          type="checkbox"
                                          className="btn-check"
                                          id={
                                            element.id +
                                            (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                          }
                                          name={element.id}
                                          value={element.value}
                                          autoComplete="off"
                                          onChange={handlerAmenitiesClick}
                                        />
                                        <label
                                          className="btn  form-check"
                                          htmlFor={
                                            element.id +
                                            (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                          }
                                        >
                                          <div>
                                            {" "}
                                            <img
                                              className={
                                                "icon-class" +
                                                (checkboxData?.[
                                                  element.id +
                                                  (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                                ]
                                                  ? "-checked"
                                                  : "")
                                              }
                                              src={element.img}
                                              alt={element.meta}
                                            ></img>
                                          </div>
                                          <p
                                            className={
                                              "icon-class-text" +
                                              (checkboxData?.[
                                                element.id +
                                                (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                              ]
                                                ? "-checked"
                                                : "")
                                            }
                                          >
                                            {element.meta}
                                          </p>
                                        </label>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </>
                      );
                    })}
                  </div>

                  {/* Building Amenties For types */}
                  <div className="row mt-4">
                    <div className="heading my-1">Building Amenities</div>

                    {Array.apply(
                      null,
                      Array(
                        parseInt(
                          ownerSchema.buildingAmenities.values.length / (isDesktop ? maxColDesktop : maxColMobile) + 1
                        )
                      )
                    ).map((e, rowNumber) => {
                      return (
                        <>
                          <div className="row amenities">
                            {ownerSchema.buildingAmenities.values
                              .slice(rowNumber * (isDesktop ? maxColDesktop : maxColMobile), (rowNumber + 1) * (isDesktop ? maxColDesktop : maxColMobile))
                              .map((element, i) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        "amenties-main col-6 col-md-2 text-center"
                                      }
                                    >
                                      <div className="form-check form-check-inline pt-2">
                                        <input
                                          type="checkbox"
                                          className="btn-check"
                                          id={
                                            element.id +
                                            (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                          }
                                          name={element.id}
                                          value={element.value}
                                          autoComplete="off"
                                          onChange={handlerAmenitiesClick}
                                        />
                                        <label
                                          className="btn  form-check"
                                          htmlFor={
                                            element.id +
                                            (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                          }
                                        >
                                          <div>
                                            {" "}
                                            <img
                                              className={
                                                "icon-class" +
                                                (checkboxData?.[
                                                  element.id +
                                                  (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                                ]
                                                  ? "-checked"
                                                  : "")
                                              }
                                              src={element.img}
                                              alt={element.meta}
                                            ></img>
                                          </div>
                                          <p
                                            className={
                                              "icon-class-text" +
                                              (checkboxData?.[
                                                element.id +
                                                (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                              ]
                                                ? "-checked"
                                                : "")
                                            }
                                          >
                                            {element.meta}
                                          </p>
                                        </label>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </>
                      );
                    })}
                  </div>

                  {/* ----Browse  property pic----- */}

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group  mt-3">
                        <label htmlFor="addphoto" className="form-label">
                          {showRules ? "Add Photos Of Property": "My Picture"}
                        </label>

                        <Upload
                          // action= {`${config.host}${apiMapper.UPLOAD_FILE}`}
                          accept='image/png, image/jpeg, image/jpg'

                          listType="picture-card"
                          fileList={fileList}
                          beforeUpload={beforeUpload}
                          onPreview={handlePreview}
                          onChange={(e)=>{setQuery(e)}}
                          multiple={true}
                          maxCount={10}
                        >
                          {fileList.length > 8 ? null : uploadButton}
                        </Upload>
                        <Modal
                          open={previewOpen}
                          title={previewTitle}
                          footer={null}
                          onCancel={handleCancel}
                        >
                          <img
                            alt="example"
                            style={{
                              width: "100%",
                            }}
                            src={previewImage}
                          />
                        </Modal>
                      </div>
                    </div>
                  </div>

                  {/* Description */}
                  <div className="row mt-4">
                    <label htmlFor="floatingTextarea2" className="heading">Description*</label>
                    <div className="col-md-10 pt-1">
                      <div className="form-floating">
                        <textarea
                          className="form-control pt-1"
                          placeholder="Leave a comment here"
                          id="description"
                          style={{ height: "100px" }}
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  
                  <button type='submit' className="main-btn mb-3 mt-2" >
                    Submit
                  </button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>)}
    </>
  )
)
};
// (<div className="mt-md-0 mt-5 py-2"><h3 className="text-center">Please Login !!</h3></div>)
export default Addlisting