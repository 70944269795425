import { useEffect, useState } from "react";
import fetcher from '../utils/fetch';
import Tenantproductpage from "./Tenantproductpage";
import Ownerproductpage from "./Ownerproductpage";
import common from "../utils/common";
import './css/userAdds.css';
import apiMapper from "../utils/apiMapper";
import config from "../utils/config";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "./spinner";

function UserAdds() {

    const [addsData, setAddsData] = useState({});
    const navigate = useNavigate();
    const [isAdd, setIsAdd] = useState();

    async function getAdds() {
        const sessionToken = localStorage.Authorization;
        const headers = { ...config.headers };
        if (sessionToken) {
            headers['Authorization'] = sessionToken;
            const addsResp = await fetcher.get(apiMapper.GET_ADDS, config.host, headers);
            // console.log('adsData', addsResp)
            if ([200, 201].includes(addsResp?.status)) {
               
                if (Object.keys(addsResp?.data?.data || {}).length) {
                    setIsAdd(1)
                    setAddsData(addsResp?.data?.data)
                } else {
                    setIsAdd(2)
                }
            } else if (addsResp?.status === 401) {
                localStorage.clear();
                localStorage.clear();
                navigate('/');
                navigate(0);
            }
        } else {
            navigate('/');
            navigate(0);
        }
    }

    useEffect(() => {
        getAdds();
    }, [])


    if (isAdd === 1 ){
        return  (!common.isLoggedIn()) ? (<Spinner/>) :  (<div className="container-fluid usersadds">
        {addsData?.tenant && <Tenantproductpage data={addsData.tenant} />}
        {Object.keys(addsData).length && <Ownerproductpage data={addsData} />}
    </div>) ;
    }

    if (isAdd === 2 ){
        return  (!common.isLoggedIn()) ? (<Spinner/>) : (<div className=" container-fluid  min-vh-100 px-0 useradd">
        <div className="container useraddcontainer" style={{ backgroundColor: "white" }}>
            <div className="mt-md-0 mt-5" ><div className="row  text-center py-2">
                <h3 className="adv-text">You have not posted an advertisement yet</h3>
                <h5>You can add advertisement by below link!!</h5>
                <Link to={'/add-listing'}>
                    <a href={null} className="heading">Add-listing</a>
                </Link>
            </div>
            </div>
        </div>
    </div>
    ) ;
    }
   
    return  <>
    {(!common.isLoggedIn()) && (<Spinner/>)}
    </> 
}
export default UserAdds;