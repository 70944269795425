import { useParams, useNavigate } from "react-router-dom"
import { useEffect, useState, useRef } from "react"
import fetcher from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import "../components/css/productpage.css"
import schema from "../utils/schema";
import Registered from "./registerAdd";
import moment from 'moment';
import config from "../utils/config"
import common from "../utils/common";
import Contactpagemodal from "./Contactpagemodal";
import { useMediaQuery } from 'react-responsive';

function Tenantproductpage({ data, isMembershipEnabled }) {

    const [registeredStatus, setRegisteredStatus] = useState(false)
    const [tenantProductDetails, setTenantProductDetails] = useState({});
    const [isDescription, setIsdescription] = useState(true);
    const [contactDetails, setContactDetails] = useState({});
    const { tenantId } = useParams();
    const ref = useRef();
    const contactRef = useRef();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const descriptionHandler = () => {
        setIsdescription(!isDescription);
    }


    const getContactTenant = async () => {
        const headers = { ...config.headers };
        headers['Authorization'] = localStorage.getItem('Authorization');
        const contactResponse = await fetcher.get(apiMapper.GET_CONTACT + `/${tenantId}?addtype=tenant`, config.host, headers);
        // 
        if ([200, 201].includes(contactResponse?.status)) {
            setContactDetails(contactResponse?.data?.data);
        }

    }

    async function getTenantProductDetails() {
        if (!Object.keys(data || {}).length) {
            const tenantProductresponse = await fetcher.get(apiMapper.TENANT_PRODUCT + tenantId);

            if (!tenantProductresponse) {
                return <h1> No Internet or Api service isuue</h1>
            }
            setTenantProductDetails(tenantProductresponse?.data?.data);
        } else {

            setTenantProductDetails(data);

        }


    }

    const tenantSchema = schema.tenantschema();
    const arrayFields = [tenantSchema.furnishingTypes, tenantSchema.roomTypes];


    // 

    useEffect(() => {
        
        getTenantProductDetails();
    }, [])

    // ---amenities---render---
    const housePropertiesBlock = (ele, startIndex, endIndex) => {

        return (tenantProductDetails?.[ele.alias] || []).slice(startIndex, endIndex).map((aliasValue) => {

            return <div className="col-6 owneramenities">
                <div className="form-check form-check-inline pt-2">
                    <div> <img className="icon-class-active" src={(ele?.values || []).filter((subEle) => { if (subEle.value === aliasValue?.[ele?.values?.[0]?.id]) return subEle.img })?.[0]?.img} alt={aliasValue?.[ele?.values?.[0]?.id]}></img></div>
                    <p className={"icon-class-text"}> {tenantSchema[ele.alias].values.filter((e, i) => {
                        if (e.value === aliasValue?.[ele?.values?.[0]?.id]) return true

                    })?.[0]?.meta}</p>

                </div>
            </div>

        })
    }

    const handlerShowMore = (event) => {
        if (event.target.innerText === "show more") {
            event.target.innerText = "show less";
        } else {
            event.target.innerText = "show more";
        }

    }

    //contact Handler
    const contactHandler = async () => {
        const isValid = common.isLoggedIn();
        if (!isValid) {
            localStorage.clear();
            localStorage.clear();
            navigate('/');
            navigate(0);
            return;
        }
        if(isMembershipEnabled){
            if ((common.isMember() && common.isMember() === 'true') || (tenantProductDetails?.userId?.membershipStatus === true)) {
                await getContactTenant();
                contactRef?.current?.click();

            } else {
            
                alert('Only member is allowed')
                navigate('/membership');
            }
        }
        else{
            await getContactTenant();
            contactRef?.current?.click();
        }
    }

    const editHandler = (data) => {
        // console.log("edithandlercall", data)
         setRegisteredStatus(true);
    }

    const messageHandler = async () => {
        if(common.isLoggedIn()){
            const payload = {
                "participant": tenantProductDetails.userId._id
            }
            const messageResp = await fetcher.post(apiMapper.CREATE_CHANNEL, payload, config.host, common.getHeaders());
            if([200,201].includes(messageResp?.status)){
                const data = messageResp?.data?.data;
                // console.log('message data', data);
                await localStorage.setItem('defaultChannel', data._id);
                navigate('/message/');
            }
             else{
                alert('Something Wrong try after sometime.')
            }
        } else {
            alert('Login required to send message.');
        }
    }
    function capitalizeFirstLetter(string) {
        // Check if the string is null, undefined, or empty after trimming
        if (!string || typeof string !== "string" || string.trim() === "") {
            return ""; // Return an empty string if the input is null, undefined, or empty
        } else {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }
    return registeredStatus ? (<><Registered userAddData={{ userAdType: "tenant", data: data }} /></>) :  (!Object.keys(tenantProductDetails || {}).length ? (<><h2 className="mt-5">rendering</h2></>) : (
        <div className="container-fluid min-vh-100  px-0 productpage">
            <div className="container productconatiner">
                <div className="row">
                    <div className="col-md-7 offset-md-1 offset-sm-0 px-md-2 px-0">
                        <div className="productdetails">

                            <div className="row productheading">
                                <div className="row lastsection px-3 py-3" ><span>{"Last updated at: " + moment(tenantProductDetails.createdAt).format("MMM DD, YYYY")}</span></div>
                                <div className="tiitle">
                                    <h3>
                                        <span>
                                            Looking For  {
                                                tenantSchema.roomTypes.values.filter((e, i) => {
                                                    if (e.value === tenantProductDetails?.roomTypes?.[0]?.roomType) return true


                                                })?.[0]?.meta
                                            }
                                            {' '}in,{tenantProductDetails?.city?.name}, {tenantProductDetails?.city?.countryCode}
                                        </span>
                                    </h3>
                                </div>
                            </div>

                        </div>


                        <div className="productoverview" id="property_feature">
                            <div className="propertycard">


                                {arrayFields?.map((ele, i) => {

                                    return (<>
                                        <div className="houseProperties" key={i}>
                                            <div className="row  pt-2 mb-2">
                                                <div className="heading">{ele.meta}</div>
                                            </div>

                                            <div className="row row-cols-2 propertyName text-center mb-2">
                                                {housePropertiesBlock(ele, 0, 2)}

                                            </div>

                                            <div id={ele.alias} className="collapse">
                                                <div className="row row-cols-2 propertyName text-center mb-2">
                                                    {housePropertiesBlock(ele, 2, (tenantProductDetails?.[ele.alias] || []).length)}
                                                </div>
                                            </div>
                                            <div className="row text-center">
                                                <a onClick={handlerShowMore} ref={ref} href="#/" data-bs-toggle="collapse" id={ele.values[0].id} data-bs-target={'#' + (ele.alias || '')} aria-expanded="false" aria-controls={ele.alias}>
                                                    show more
                                                </a>
                                            </div>
                                        </div>
                                    </>)
                                })
                                }

                                <div className="row mb-2 mt-2 Otherdetails">
                                    <div className="heading">
                                        <span>Other Details</span>
                                    </div>
                                    <ul className="list-unstyled">
                                        <li className="px-4 subHeading">
                                            <div className="row">
                                                <div className="col-6">
                                                    Organization
                                                </div>
                                                <div className="col-6">
                                                    <span className="subdataText">{tenantProductDetails.organisation}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="px-4 subHeading">
                                            <div className="row">
                                                <div className="col-6">
                                                    Profession
                                                </div>
                                                <div className="col-6 ">
                                                    <span className="subdataText">{tenantProductDetails.profesion}</span>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </div>



                                {/* ---description-- */}
                                <div className="description">
                                    <div className="row  mb-1">
                                        <div className="heading"> Descriptions</div>
                                    </div>

                                    {isDescription ? <div className="row descriptionContent" >
                                        {(tenantProductDetails?.description || '').slice(0, 180)}

                                    </div> : <div className="row descriptionContent" >
                                        {tenantProductDetails?.description}

                                    </div>
                                    }
                                    <div className="row">
                                        <a onClick={descriptionHandler} href='#/'><p className="text-end">{isDescription ? "see more" : "see less"}</p></a>
                                    </div>
                                </div>

                                {/* -----contact us for mobile ------   */}
                                {(!Object.keys(data || {}).length) ? (isMobile && <div className="contactUsmobile fixeedPstionmobile">
                                    <div className="row">
                                        <div className="col-6 ">
                                            <div className="row  mb-4 mt-4 mx-3">
                                                <button   className="main-btn"  onClick={contactHandler}>Contact</button>
                                                <button  ref={contactRef} className="d-none" data-bs-toggle="modal" data-bs-target="#exampleModal1">dummyButton</button>
                                            </div>
                                        </div>
                                        <div className="col-6 " >
                                            <div className="row  mb-4 mt-4 mx-3">
                                                <button className="main-btn " onClick={messageHandler}>Message</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>) : (isMobile && <div className="contactUsmobile fixeedPstionmobile">
                                    <div className="row">
                                        <div className="col-12 ">
                                            <div className="row  mb-4 mt-4 mx-3">
                                                <button className="main-btn" onClick={() => editHandler(data)}>Edit</button>
                                            </div>
                                        </div>
                                        <div className="col-6 " >
                                        <div className="row  mb-4 mt-4 mx-3">
                                            <button className="main-btn">Message</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>)}


                            </div>
                        </div>
                    </div>


                    <Contactpagemodal contactDetailsData={contactDetails} />
                    {/* ----contatct us ----- */}
                    {isDesktop && <div className="col-md-2 px-md-2 product">
                        <div className="ownerContactus">
                            <div className="row  contactusHeading">
                                <span>Tenant details</span>
                            </div>
                            <hr />
                            <div className="row mb-2">
                                <div className="contactimagesection">
                                    <img className="contactimg" src={tenantProductDetails.userId.profileImg || "/images/ownercontactus.png"} alt="ownercontactid" />
                                </div>
                            </div>
                            <div className="row title" pt-2>
                                <span>By {tenantProductDetails.userId.name}</span>
                                <p className="subtext">{capitalizeFirstLetter(tenantProductDetails.city.name)} </p>
                            </div>
                            {(!Object.keys(data || {}).length) ? <>
                                <div className="row   mb-4 mt-4 mx-3">
                                <button className="main-btn" onClick={contactHandler}>Contact Tenant</button>
                                <button  ref={contactRef} className="d-none" data-bs-toggle="modal" data-bs-target="#exampleModal1">dummyButton</button>
                            </div>
                            <div className="row  mb-4 mt-4 mx-3">
                                <button className="main-btn" onClick={messageHandler} >Message Tenant</button>
                            </div>
                            </> 
                            :(<>
                                <div className="row   mb-4 mt-4 mx-3">
                                    <button className="main-btn" onClick={() => editHandler(data)}>Edit</button>
                                </div></>) }
                            
                            <div className="row  shedualAvilable mt-3 mx-2">
                                <div className="col-2 p-3 ">
                                    <i className="bi bi-calendar" style={{ "font-size": "180%", color: "blue" }} ></i>
                                </div>
                                <div className="col-8 p-3">
                                    <div className="row p-1 calender-title ">
                                        <span>Contact soon</span>
                                    </div>

                                </div>
                            </div>
                            <div className="row shedualvisit mx-2">
                                <div className="col-2 p-3 ">
                                    <i className="bi bi-stopwatch" style={{ "font-size": "180%", color: "blue" }} ></i>
                                </div>
                                <div className="col-8 p-3">
                                    <div className="row p-1 visit-title ">
                                        <span>Most of the listing likely fill out in very first week.</span>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    }
                </div>

            </div>
        </div>)

    )
}





export default Tenantproductpage