import { useEffect } from "react";
import { useSearchParams } from "react-router-dom"
import fetchFunction from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import config from "../utils/config";
import common from "../utils/common";
import { useNavigate } from "react-router-dom"

const PaymentStatus =  ()=> {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const status = searchParams.get('status');
    
    async function  getPaymentStatus() {
        const paymentStatus =  await fetchFunction.put(apiMapper.UPDATE_ORDER_STATUS + localStorage.getItem('orderId'), {} , config.host, common.getHeaders()  )
        if ([200, 201].includes(paymentStatus?.status)){
            localStorage.setItem('membershipStatus', 'true');
            localStorage.removeItem('orderId');
            alert("Payment is Successful.");
            navigate('/')
            
        } else {
            alert("Payment Canceled.");
            navigate('/')
        }
    }
    useEffect(()=>{
        getPaymentStatus()
    }, [])

    return <>
    {status}
   
    </>
}
export default PaymentStatus;