export const ResetFilterButton = ({ combinedFilter, resetFilterHandler }) => {
  return (
    <button 
      className="reset-filter-btn filter-text-h3" 
      onClick={resetFilterHandler}
      style={{ display: 'inline-flex', alignItems: 'center',margin:"0px" }}
      title="Reset Filters"
      hidden={combinedFilter.filterCount === 0}
    >
      {combinedFilter.filterCount > 0 ? `Reset Filters (${combinedFilter.filterCount})` : 'Reset Filters'} 
    </button>
  );
};