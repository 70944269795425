import React, { useState, useRef } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import "./css/RegisterContactOTP.css"; // Import custom CSS
import { maskEmail } from "../utils/helper";
import fetchFunction from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import { useDispatch } from "react-redux";
import { setUser } from "./featureStore/user/userSlice";

const { Item } = Form;

const RegisterContactOTP = ({
  setRegisterContactSuccess, // visibility of modal
  registerContactInfo, // contact info from the user
  registerContactSuccess, // visibility of modal default false
  setRegisterContactOtpSuccess, // use to show contact info modal
  setContactDetails,
  ownerProductDetails,
}) => {
  const [form] = Form.useForm();
  const [code, setCode] = useState(["", "", "", ""]);
  const inputsRef = useRef([]);
  const maskedEmail = maskEmail(registerContactInfo?.email);
  const [count, setCount] = useState(0);
  const [otpError, setOtpError] = useState(null); // State to hold OTP error message
  const dispatch = useDispatch();

  async function handleRegisterContactOTP(data){
    const contactResponse = await fetchFunction.post(
        apiMapper.CONTACT_OTP_VERIFY,
        data
      );

      if ([200, 201].includes(contactResponse?.status)) {
        const resData = contactResponse?.data?.data
        setContactDetails(resData?.addInfo)
        setRegisterContactOtpSuccess(true)
        if (resData?.tokenData && resData.tokenData?.userInfo) {
            dispatch(setUser({
              token: contactResponse?.data?.data?.tokenData.token,
              name: resData?.tokenData?.userInfo?.name,
              email: resData?.tokenData?.userInfo?.email,
              profileImg: resData?.tokenData?.userInfo?.profileImg,
              gender: resData?.tokenData?.userInfo?.gender,
            }));
        }
        setRegisterContactSuccess(false); // Close modal on submit
      }
      else if([400].includes(contactResponse?.status)){
        setOtpError('Incorrect OTP. Please try again.');
      }
  }

  const handleChange = (e, index) => {
    const value = e.target.value;
    const newCode = [...code];
    newCode[index] = value.slice(0, 1); // Ensure single character input
    setCode(newCode);

    // Focus next input if any
    if (value && index < 3) {
      inputsRef.current[index + 1].focus();
    }

    // Validate if all fields are filled
    if (newCode.every((digit) => digit.length === 1)) {
      form.setFieldsValue({ otp: newCode.join("") });
    }
  };

  const handleKeyDown = (e, index) => {
    // Handle backspace to focus previous input
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const onFinish = async (e) => {
    const data = {
        "email": registerContactInfo?.email,
        "addType": "owner",
        "addId": ownerProductDetails?._id,
        "otp" : code.join('')
    }
    handleRegisterContactOTP(data)
  };

  const closeModal = async () => {
    setRegisterContactSuccess(false);
  };

  const handleResend = async () => {
    if(count >= 3){
      message.info("OTP Resend limit reached")
      return
    }
    const resendData = {...registerContactInfo, resend:true}
    const contactResponse = await fetchFunction.post(
        apiMapper.CONTACT_REGISTER,
        resendData
      );

      if ([200, 201].includes(contactResponse?.status)) {
          message.info("Otp sent successfully")
          setCount(prev => prev + 1);
      }
  };

  return (
    <div>
      <Modal
        open={registerContactSuccess}
        onCancel={closeModal}
        destroyOnClose={true} // Destroy modal content on close
        centered // Center modal vertically and horizontally
        title={
          <div className="modal-title">
            Please enter the one-time password to verify your account
          </div>
        } // Updated title
        footer={null} // Remove default footer to customize
      >
        <p style={{ textAlign: "center", marginBottom: "20px" }}>
          A code has been sent to {maskedEmail} email.
        </p>

        <Form
          form={form}
          id="otpForm"
          layout="vertical"
          onFinish={onFinish}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Item
            name="otp"
            rules={[
              { required: true, message: "Please input your 4-digit code!" },
            ]}
            validateStatus={otpError ? 'error' : ''}
            help={otpError}
            style={{ width: "100%", textAlign: "center" }}
          >
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              {code.map((digit, index) => (
                <Input
                  key={index}
                  ref={(el) => (inputsRef.current[index] = el)}
                  value={digit}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  maxLength={1}
                  type="number"
                  style={{
                    width: "40px",
                    height: "40px",
                    textAlign: "center",
                    fontSize: "16px",
                    borderRadius: "4px",
                  }}
                  autoFocus={index === 0}
                />
              ))}
            </div>
          </Item>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button type="primary" onClick={handleResend}>Resend OTP</Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default RegisterContactOTP;
