import { useEffect, useState ,useRef} from "react";
import "./css/contentBySlug.css";
import { useParams } from "react-router-dom";
import fetcher from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import Spinner from "./spinner";


const ContentBySlug = () => {

    const [contentData, SetContentData] = useState({});
    const { slug } = useParams();
    const myRef = useRef(null);

    const getContent = async () => { 

        const response = await fetcher.get(apiMapper.GET_CONTENTBYSLUG+`${slug}`);
        // console.log("response",response)
       
        if([200,201].includes(response?.status)){

            SetContentData(response?.data?.data);
        }
    }

    useEffect(()=>{
        myRef.current.innerHTML = contentData?.contentDescription || <Spinner/>;
    },[contentData])

    // useEffect(()=>{
    //     getContent();

    // },[])

    useEffect(()=>{
        getContent();

    },[slug])

    return (
        <>
            <div className="container-fluid contentBySlug min-vh-100">
                <div className="container">
                    <div className="contentContainer">
                    <div className="row title">
                        <div className="col-8 offset-2 text-center">
                            <h1>{contentData?.title}</h1>
                        </div>
                    
                    </div>
                    <div className="row ">
                        <div className="col-12 contentdetails rounded-4">
                            <div ref={myRef}>Content</div>
                        </div>
                   
                    </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default ContentBySlug