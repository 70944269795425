import { useState, useEffect } from "react";
import common from "../../utils/common";
import { Link, useNavigate } from "react-router-dom";
import apiMapper from "../../utils/apiMapper";
import config from "../../utils/config";
import fetcher from "../../utils/fetch";

const Adminflagads = () => {
  const [adsdata, setAdsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [searchAds, setSearchAds] = useState('');

  const limit = 10;
  const navigate = useNavigate();

  const getAllAds = async (page = 1, adid = '') => {
    let url = `?page=${page - 1}&limit=${limit}&addId=${adid}`;
    const headers = { ...config.headers };
    headers['Authorization'] = localStorage.getItem('Admin-Authorization');

    try {
      const getAdsResponse = await fetcher.get(apiMapper.GET_ALLADS + url, config.host, headers);
      const count = getAdsResponse?.data?.totalCount;

      if ([200, 201].includes(getAdsResponse?.status)) {
        setTotalCount(count);
        setAdsData(getAdsResponse?.data?.data);
      } else if ([401].includes(getAdsResponse?.status)) {
        localStorage.clear();
        navigate('/admin/login');
      }
    } catch (error) {
      console.error("Error fetching ads:", error);
    }
  };

  const disabledAdsHandler = async (adsId) => {
    if (window.confirm('Are you sure want to Delete this Ads?')) {
      const headers = { ...config.headers };
      headers['Authorization'] = localStorage.getItem('Admin-Authorization');
      try {
        const adsDeleteResponse = await fetcher.delete(apiMapper._ADS_DISABLED + adsId, {}, config.host, headers);
        // console.log("adsResponse", adsDeleteResponse);
        if ([200, 201].includes(adsDeleteResponse?.status)) {
          getAllAds(currentPage, searchAds);
        } else if ([401].includes(adsDeleteResponse?.status)) {
          localStorage.clear();
          navigate('/admin/login');
        }
      } catch (error) {
        console.error("Error deleting ads:", error);
      }
    }
  };
  const deactivateAdsHandler = async (adsId) => {
    if (window.confirm('Are you sure you want to deactivate this Ad?')) {
      const headers = { ...config.headers };
      headers['Authorization'] = localStorage.getItem('Admin-Authorization');
      const requestBody = {
        status: 'inactive', 
      };
  
      try {
        const adsDeactivateResponse = await fetcher.delete(apiMapper._ADS_DEACTIVATE + adsId, requestBody, config.host, headers);
        // console.log("adsDeactivateResponse", adsDeactivateResponse);
        if ([200, 201].includes(adsDeactivateResponse?.status)) {
          getAllAds(currentPage, searchAds); 
        } else if (adsDeactivateResponse?.status === 401) {
          localStorage.clear();
          navigate('/admin/login'); 
        }
      } catch (error) {
        console.error("Error deactivating ads:", error);
      }
    }
  };

  const searchByAds = async (adsValue) => {
    try {
      setSearchAds(adsValue);

      if (adsValue.trim() !== '') {
        const filteredAds = adsdata.filter(
          (ele) =>
            ele?.userId?.email.includes(adsValue.trim()) ||
            ele?._id.includes(adsValue.trim())
        );
        setAdsData(filteredAds);
      } else {
        getAllAds();
      }
    } catch (error) {
      console.error("Error in searchByAds:", error);
    }
  };

  useEffect(() => {
    const isValid = common.isAdminLoggedIn();
    if (isValid) {
      getAllAds(currentPage, searchAds);
    } else {
      localStorage.clear();
      navigate('/admin/login');
    }
  }, [currentPage, searchAds]);

  return (
    <>
      <div className="container-lg mt-3">
        <div className="table-responsive">
          <div className="table-wrapper">
            <div className="table-title">
              <div className="row">
                <div className="col-sm-6"><h2>Flag Ads</h2></div>
              </div>
            </div>

            <table className="table table-bordered mt-2">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>UserId</th>
                  <th>
                    AdsId
                    <input
                      type="text"
                      onChange={(e) => setSearchAds(e.target.value)}
                      onBlur={(e) => searchByAds(e.target.value)}
                      value={searchAds}
                      className="form-control"
                      placeholder="search ads by email..."
                    />
                  </th>
                  <th>UserName</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Membership Status</th>
                  <th>Delete Ads</th>
                  <th>Flag Ads</th>
                </tr>
              </thead>
              <tbody>
                {adsdata?.map((ele, i) => (
                  <tr key={ele?.userId?._id}>
                    <td>{(i + 1) + (currentPage - 1) * limit}</td>
                    <td>{ele?.userId?._id}</td>
                    <td>
                      <Link to={ele?.userType === 'owner' ? common.ownerPath + ele?._id : common.tenantPath + ele?._id} target="_blank">
                        {ele?._id}
                      </Link>
                    </td>
                    <td>{ele?.userId?.name}</td>
                    <td>{ele?.userId?.email}</td>
                    <td>{ele?.status}</td>
                    <td>{ele?.membershipStatus === true ? 'yes' : 'No'}</td>
                    <td>
                      <button className="btn btn-danger" onClick={() => disabledAdsHandler(ele?._id)}>Delete Ads</button>
                    </td>
                    <td>
                      <button className="btn btn-danger" onClick={() => deactivateAdsHandler(ele?._id)}>Flag Ads</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* ---pagination----- */}

        <div className="row">
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className="page-item">
                <a className={"page-link " + ((currentPage <= 1 || adsdata.length === 0) ? 'disabled' : '')} href="#/" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
              {totalCount > 0 && [...Array(Math.ceil(totalCount / limit)).keys()].slice(0, 3).filter((pgNumber, i) => {
                if (pgNumber + currentPage < Math.ceil(totalCount / limit) + 1) {
                  return true;
                }
              }).map((pgNumber) => (
                <li key={pgNumber + currentPage} className={`page-item ${currentPage === pgNumber + currentPage ? 'active' : ''} `}>
                  <a onClick={() => setCurrentPage(pgNumber + currentPage)} className='page-link' href='#/'>
                    {pgNumber + currentPage}
                  </a>
                </li>
              ))}
              <li className="page-item">
                <a className={"page-link " + ((adsdata?.length < limit || adsdata?.length === 0) ? 'disabled' : '')} href="#/" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Adminflagads;