import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import common from "../../utils/common";
import apiMapper from "../../utils/apiMapper";
import fetcher from "../../utils/fetch";
import config from "../../utils/config";

const Rule = () => {

    const [getRules, setGetRules] = useState([]);
    const [type, setType] = useState("add");

    const [formstate, setFormstate] = useState({
        "rule": ""
    });
    const ref = useRef();
    // const [editRule,setEditRule] = useState({})
    const navigate = useNavigate();

    async function getAllRules() {
        const response = await fetcher.get(apiMapper.GET_RULES);

        if (response?.status  === 200) {
            setGetRules(response?.data?.data || []);
        } else if (response?.status === 401) {
            localStorage.clear();
            localStorage.clear();
            navigate('/admin/login');
            navigate(0);
        }
       
    }



    useEffect(() => {
        const isValid = common.isAdminLoggedIn();
        if (isValid) {
           
            setFormstate({
                "rule": ""
            })
            getAllRules();

        }
        else {
            localStorage.clear();
            localStorage.clear();
            navigate('/admin/login')
        }
    }, [])


  

    // Add Rules
    // on saveChanges new Rules
    const saveChangeHandler = async (event, type='add') => {
        event.preventDefault();
        
        const ruleName = event.target.rule.value
        
        const headers = {...config.headers};
        headers['Authorization'] = localStorage.getItem('Admin-Authorization');
        // console.log('headers',headers)
        let responseNewRule = null;
        if(type === "add"){
            responseNewRule = await fetcher.post(apiMapper.CREATE_RULE, { name: ruleName }, config.host ,headers);
        } else{
            // console.log('id',formstate.id ,type)
            responseNewRule = await fetcher.put(apiMapper.UPDATE_RULE + `/${formstate.id}`, { name: ruleName }, config.host ,headers);

        }

      
        if([200, 201].includes(responseNewRule?.status)){

            setFormstate({rule:""});
            await getAllRules();

            ref.current.click();
            

        } else if([400].includes(responseNewRule?.status)){
             return (<h2>Rule All ready Present </h2>)
        }
         else if (responseNewRule.status === 401){
            localStorage.clear();
            localStorage.clear();
            ref.current.click();
            navigate('/admin/login');
        }
    }


    const popupCreateEdit = (type ="add")=> {
        return <div className="modal fade" id="addNewRuleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{type === "add" ? "Added Rule": "Edit Rule"}</h5>
                    <button ref={ref} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <form onSubmit={(e)=>{saveChangeHandler(e,type)}}>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 mt-3">
                                <label htmlFor="rule" className="form-label">Rule</label>
                                <input onChange={(e)=>{setFormstate({...formstate, rule: e.target.value})}} value={formstate.rule} type="text" className="form-control" id="rule" name="rule" placeholder=" add rule" required />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary">{type === "add" ? "Create": "Update"}</button>
                </div>
                </form>

                
            </div>
        </div>
    </div>
    }



    //Edit handler
    const editHandler = (id, rule) => {
        
        setFormstate({...formstate, id, rule});
        setType("edit");

    }

    //Delete handler 

    const deleteHandler = async (id) => {
        const headers = {...config.headers};
        headers['Authorization'] = localStorage.getItem('Admin-Authorization');
        const deleteRuleResp = await fetcher.delete(apiMapper.UPDATE_RULE + `/${id}`, { }, config.host ,headers);
        if([200,201].includes(deleteRuleResp.status) ){
            getAllRules();
        }
        

    }

    return (
        <>
            <div className="container-lg mt-3">
                <div className="table-responsive">
                    <div className="table-wrapper">
                        <div className="table-title">
                            <div className="row">
                                <div className="col-sm-8"><h2>Rules Details</h2></div>
                                <div className="col-sm-4 text-center">
                                    <button  type="button" className="main-btn" data-bs-toggle="modal" data-bs-target="#addNewRuleModal" ><i className="bi bi-plus"></i> Add New</button>
                                </div>
                            </div>
                        </div>
                        <table className="table table-bordered mt-2">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getRules.map((ele, i) => {
                                    return (<>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{ele.name}</td>
                                            <td>{ele.status}</td>
                                            <td>
                                            {/* onClick={(e)=>{editHandler(ele.id)}} */}
                                                <a onClick={()=>{editHandler(ele._id, ele.name)}}  className="btn px-3 " data-bs-toggle="modal" data-bs-target="#addNewRuleModal"  title="Edit" href="#/"><i className="bi bi-pencil"></i></a>
                                                <a className="px-3" title="Delete" href="#/" onClick={()=>deleteHandler(ele._id)}><i className="bi bi-trash"></i></a>
                                            </td>
                                        </tr>
                                    </>)
                                })}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* ----Modal- addnewRule--- */}
            {popupCreateEdit(type)}
            
        </>
    )
}

export default Rule