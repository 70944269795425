import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import common from "../../utils/common";
import apiMapper from "../../utils/apiMapper";
import fetcher from "../../utils/fetch";
import config from "../../utils/config";

const Feature = () => {

    const [getFeatures, setGetFeatures] = useState([]);
    const [type, setType] = useState("add");

    const [formstate, setFormstate] = useState({
        "name": "",
        "description": "",
        "status": "active"
    });
    const ref = useRef();
    const navigate = useNavigate();

    async function getAllFeatures() {
        const response = await fetcher.get(apiMapper.GET_ALL_FEATURE);

        if (response?.status  === 200) {
            setGetFeatures(response?.data?.data || []);
        } else if (response?.status === 401) {
            localStorage.clear();
            localStorage.clear();
            navigate('/admin/login');
            navigate(0);
        }
    }

    useEffect(() => {
        const isValid = common.isAdminLoggedIn();
        if (isValid) {
            setFormstate({
                "name": "",
                "description": "",
                "status": "active"
            })
            getAllFeatures();
        }
        else {
            localStorage.clear();
            localStorage.clear();
            navigate('/admin/login')
        }
    }, [])

    // Add or Update Features
    const saveChangeHandler = async (event, type='add') => {
        event.preventDefault();
        
        const { name, description, status } = formstate;
        const headers = {...config.headers};
        headers['Authorization'] = localStorage.getItem('Admin-Authorization');
        
        let responseNewFeature = null;
        if(type === "add"){
            responseNewFeature = await fetcher.post(apiMapper.CREATE_FEATURE, { name, description, status }, config.host, headers);
        } else {
            responseNewFeature = await fetcher.put(apiMapper.UPDATE_FEATURE + `/${formstate.id}`, { name, description, status }, config.host, headers);
        }

        if([200, 201].includes(responseNewFeature?.status)){
            setFormstate({ name: "", description: "", status: "active" });
            await getAllFeatures();
            ref.current.click();
        } else if([400].includes(responseNewFeature?.status)){
            return (<h2>Feature Already Present </h2>)
        } else if (responseNewFeature.status === 401){
            localStorage.clear();
            localStorage.clear();
            ref.current.click();
            navigate('/admin/login');
        }
    }

    const popupCreateEdit = (type ="add") => {
        return (
            <div className="modal fade" id="addNewFeatureModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{type === "add" ? "Add Feature" : "Edit Feature"}</h5>
                            <button ref={ref} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={(e) => { saveChangeHandler(e, type) }}>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="mb-3 mt-3">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input onChange={(e) => { setFormstate({ ...formstate, name: e.target.value }) }} value={formstate.name} type="text" className="form-control" id="name" name="name" placeholder="Add name" required />
                                    </div>
                                    <div className="mb-3 mt-3">
                                        <label htmlFor="description" className="form-label">Description</label>
                                        <input onChange={(e) => { setFormstate({ ...formstate, description: e.target.value }) }} value={formstate.description} type="text" className="form-control" id="description" name="description" placeholder="Add description" required />
                                    </div>
                                    <div className="mb-3 mt-3">
                                        <label htmlFor="status" className="form-label">Status</label>
                                        <select onChange={(e) => { setFormstate({ ...formstate, status: e.target.value }) }} value={formstate.status} className="form-control" id="status" name="status" required>
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">{type === "add" ? "Create" : "Update"}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const editHandler = (id, name, description, status) => {
        setFormstate({ id, name, description, status });
        setType("edit");
    }

    const deleteHandler = async (id) => {
        const headers = {...config.headers};
        headers['Authorization'] = localStorage.getItem('Admin-Authorization');
        const deleteFeatureResp = await fetcher.delete(apiMapper.UPDATE_FEATURE + `/${id}`, {}, config.host, headers);
        if([200, 201].includes(deleteFeatureResp.status)){
            getAllFeatures();
        }
    }

    return (
        <>
            <div className="container-lg mt-3">
                <div className="table-responsive">
                    <div className="table-wrapper">
                        <div className="table-title">
                            <div className="row">
                                <div className="col-sm-8"><h2>Feature Details</h2></div>
                                <div className="col-sm-4 text-center">
                                    <button type="button" className="main-btn" data-bs-toggle="modal" data-bs-target="#addNewFeatureModal"><i className="bi bi-plus"></i> Add New</button>
                                </div>
                            </div>
                        </div>
                        <table className="table table-bordered mt-2">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getFeatures.map((ele, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{ele.name}</td>
                                            <td>{ele.description}</td>
                                            <td>{ele.status}</td>
                                            <td>
                                                <a onClick={() => { editHandler(ele._id, ele.name, ele.description, ele.status) }} className="btn px-3" data-bs-toggle="modal" data-bs-target="#addNewFeatureModal" title="Edit" href="#/"><i className="bi bi-pencil"></i></a>
                                                <a className="px-3" title="Delete" href="#/" onClick={() => deleteHandler(ele._id)}><i className="bi bi-trash"></i></a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {popupCreateEdit(type)}
        </>
    )
}

export default Feature;
