import './css/messageList.css'
import "react-chat-elements/dist/main.css"
import { MessageList, Input, Button, ChatList } from "react-chat-elements"
import { useEffect, useState, useRef } from 'react';
import fetchFunction from '../utils/fetch';
import apiMapper from '../utils/apiMapper';
import config from '../utils/config';
import common from '../utils/common';
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
// import { components } from 'react-select';

const MessageListContainer = ()=> {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const defaultChannel =  localStorage.getItem('defaultChannel');
    const bottomRef = useRef(null); 
    const navigate = useNavigate();
    const [chathead, setchatHead] = useState([]);
    const [messageBody, setMessagebody] = useState([]);
    const [refreshClick, setRefreshClick] = useState(false);

    const [currentChannelId, setCurrentChannelId] = useState({});
    const [inputMessage, setInputMessage] = useState('');
    const [chatView, setChatView] = useState(true);

    const chatClickHandler = (event)=>{
        
        setChatView(false);
        setMessagebody([]);
        setCurrentChannelId(event);

    };

    function getHeaders(){
        const  headers = config.headers;
        if (localStorage.getItem('Authorization')){
            headers['Authorization'] = localStorage.getItem('Authorization');
        }
        return headers;
    }


    async function getChannels(){
        const chatList = []

        const channelDataResp = await fetchFunction.get(apiMapper.GET_ALL_CHANNEL, config.host, getHeaders() );
       
        if ([201,200].includes(channelDataResp?.status)){
            
            const userId = common.getUserId();
            
            (channelDataResp?.data?.data || []).map((e)=>{
                let otherUser = {}
                // console.log('userId comp', userId,  e.participant1._id)
                if (e.participant1._id === userId){
                    otherUser = e.participant2
                } else{
                    otherUser = e.participant1
                }
                
                
                chatList.push({
                    userId: otherUser._id,
                    channelId: e._id,
                    title: otherUser.name,
                    alt: otherUser.name.replace(' ', '_'),
                    subtitle: e.lastMessage,
                    avatar: otherUser.profileImg || `/images/placeholder/${otherUser.gender === 'male' ? 'tenantM':'tenantF'}.jpg` ,
                    date:moment(e.updatedAt).toDate()
                })
            })
            
            setchatHead(chatList);
            if(!Object.keys(currentChannelId || {}).length){
                // console.log('defaultChannel', defaultChannel)
                if(defaultChannel){
                    const defaultChannelhead = chatList.filter((e)=> {
                        if(e.channelId === defaultChannel) return true;
                    })?.[0];

                    setCurrentChannelId(defaultChannelhead);
                }else if(chatList?.[0]) setCurrentChannelId(chatList[0]);
                
            }

        }else if (channelDataResp?.status === 401){
            localStorage.clear();
            localStorage.clear();
            navigate('/');
            navigate(0);
        }
        
    }

    useEffect(()=>{
        if(!refreshClick) bottomRef.current?.scrollIntoView({behavior: 'smooth'});
        setRefreshClick(false);
    }, [messageBody])

    async function getMessages(channelId, timestamp = null){
        
        const mList = []
        if (!timestamp){
            timestamp = moment(new Date).valueOf();
        } else {
            
            timestamp = moment(timestamp).valueOf();
            
        }
        
        const messageDataResp = await fetchFunction.get( `${apiMapper.GET_ALL_MESSAGE}${channelId}?timestamp=${timestamp}`  , config.host, getHeaders() );
       
        if ([201,200].includes(messageDataResp?.status)){

            const userId = common.getUserId();
            const senderData = chathead.filter((e)=>{if(e.channelId === channelId) return true})?.[0] || {};
            if (Object.keys(senderData).length){
                (messageDataResp?.data?.data || []).map((e)=>{
                    // console.log('e.senderId === userId', e.senderId === userId, e.senderId, userId  )
                    const data = {
                        position: e.senderId === userId ? "right" : "left",
                        type: "text",
                        title: e.senderId === userId ? localStorage.getItem('name') : senderData.title ,
                        text: e.message,
                        date: moment(e.updatedAt).toDate()
                        };
                    mList.push(data);
                })
                
                setMessagebody( _.sortBy([...mList, ...messageBody ], ['date']));
            }
            

        } else if (messageDataResp?.status === 401){
            
            // localStorage.clear();
            // localStorage.clear();
            // navigate('/');
            // navigate(0);
        }
    }

    useEffect(()=>{
        if (!common.isLoggedIn()){
            navigate('/');
            navigate(0);
            return;
        }

        if (defaultChannel){
            setChatView(false);
        }
        
        getChannels()

    }, [])

    useEffect(()=>{
        if (Object.keys(currentChannelId || {}).length){
            getMessages(currentChannelId.channelId);
        }
    }, [currentChannelId])

    const onScrollHandlerMessage = (event)=>{
        
        if (event.currentTarget.scrollTop === 0){
            setRefreshClick(true);
            
            getMessages(currentChannelId.channelId, messageBody.reverse()[0].date);
            
        }
    };

    const sendMessageHandler = async (event)=> {
        
        const payload = {
            channelId: currentChannelId.channelId,
            message: inputMessage
        }
        const postMessageResp = await fetchFunction.post(apiMapper.SEND_MESSAGE, payload, config.host, getHeaders());
        
        if([200,201].includes(postMessageResp?.status)){
            const respData = postMessageResp.data.data;
            const data = {
                position: "right",
                type: "text",
                title: localStorage.getItem('name'),
                text: respData.message,
                date: moment(respData.updatedAt).toDate()
                };
            setMessagebody(_.sortBy([ ...messageBody, data,], ['date']));
            setInputMessage('');

        }
        

    }

    return <>

        <div className="messageList">
            <div className="container">
                <div className="message">
                    <div className="row">
                        <div className={ isDesktop ? "col-3 ":( chatView ?  "col" : "col d-none")}>
                            <div className="chat-list-body" style={{width:"105%",marginTop:"5%"}}>
                            
                                {chathead.length ? <ChatList
                                    onClick={chatClickHandler}
                                    className='chat-list'
                                    dataSource={chathead} /> : <p>No messages to show</p>}
                            </div>
                        </div>
                        <div className={ isDesktop ? "col-9 ": (!chatView  ?  "col" : "col d-none")}>
                            <div class="chatInfoDiv px-0 mt-2 ">
                                    <div className="row">
                                        {isMobile&& <div className="col-2">
                                        <div class="rce-avatar-container circle xlarge">
                                            <button className='backButton' onClick={()=>{ setChatView(true)}}>
                                                <i class="bi bi-arrow-left"></i></button>
                                        </div>
                                        </div>}
                                        <div className={isDesktop ? "col-1" :"col-2"}>
                                            <div class="rce-avatar-container circle xlarge ">
                                                {currentChannelId?.avatar && <img  alt="avatar" src={currentChannelId.avatar}
                                                class="rce-avatar"/>}
                                            </div>
                                        </div>
                                        <div className="col-auto mt-3">
                                        <div class="chatInfoArea_bQhy">
                                        <label class="nameLabel_HgHa">{currentChannelId?.title || ""}</label>
                                    </div>
                                        </div>
                                    </div>
                                    
                                    
                            </div>
                            <div className="message-body ">
                            {/* { (messageBody?.length && messageBody?.length >= 9 ) ? <div className="text-center">
                                <button className='load-history' onClick={onScrollHandlerMessage}>
                                    <i class="bi bi-arrow-clockwise"></i></button></div> :"" } */}
                                    <MessageList className='message-list'
                                        lockable={true}
                                        toBottomHeight={'100%'}
                                        dataSource={messageBody}
                                        
                                    />
                                
                                <div ref={bottomRef}></div>

                            </div>
                            {chathead.length > 0 && (
                                <div className="message-input" style={{paddingBottom: 0, marginBottom: 0 }}>
                                    <div className="d-flex flex-row">
                                        <Input
                                            id="search"
                                            onChange={(e) => setInputMessage(e.target.value)}
                                            placeholder="Type here..."
                                            value={inputMessage}
                                            onKeyPress={(e) => { if (e.code === 'Enter') sendMessageHandler() }}
                                            // minHeight={70}
                                            inputStyle={{ "border": "1px solid #D3D3D3", "height": "50px" }}
                                            rightButtons={
                                                <button className='send-button' onClick={sendMessageHandler}>
                                                    <i className="bi bi-send"></i>
                                                </button>
                                            }
                                        />
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
        </>
};

export default MessageListContainer;