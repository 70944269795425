import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import common from "../../utils/common";


const Columnnavbar = () => {

    const navigate = useNavigate();


    const logoutHandler = () => {
        localStorage.clear();
        localStorage.clear();
        navigate('/admin/login');
        navigate(0);
    }
    const [userDetail, setUserData] = useState({});

    const [adminLoggedIn, setAdminLoggedIn] = useState(false);

    useEffect(() => {
        setAdminLoggedIn(common.isAdminLoggedIn());
        if (common.isAdminLoggedIn()) {
            const data = {}
            data['adminEmail'] = localStorage.getItem("adminEmail");
            data['adminName'] = localStorage.getItem("adminName") || 'admin';
            data['adminProfileImg'] = localStorage.getItem("adminProfileImg") || `/images/placeholder/tenantM.jpg`;
            setUserData(data);
        }
    }, [])



    return (
        <>

             <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                {/* <a href="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span className="fs-5 d-none d-sm-inline">Menu</span>
                </a> */}
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li className="nav-item">
                        <Link to='/admin'>
                            <a href="#/" className="nav-link align-middle px-0 ">
                                <i className="bi bi-house text-white"></i> <span className="ms-1 d-none d-sm-inline text-white">Dashboard</span>
                            </a>
                        </Link>
                    </li>
                    {/* <li>
                        <a href="#submenu1" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                            <i className="fs-4 bi-speedometer2"></i> <span className="ms-1 d-none d-sm-inline">Dashboard</span> </a>
                        <ul className="collapse show nav flex-column ms-1" id="submenu1" data-bs-parent="#menu">
                            <li className="w-100">
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 1 </a>
                            </li>
                            <li>
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 2 </a>
                            </li>
                        </ul>
                    </li> */}
                     <li>
                        <Link to='/admin/rules'>
                            <a href="#/" className="nav-link px-0 align-middle">
                                <i className="bi bi-house-slash text-white"></i> <span className="ms-1 d-none d-sm-inline text-white">Rules</span></a>
                        </Link>
                    </li>
                    <li>
                        <Link to='/admin/content'>
                            <a href="#/" className="nav-link px-0 align-middle">
                                <i className="bi bi-shield-x text-white"></i>  <span className="ms-1 d-none d-sm-inline text-white">Content</span></a>
                        </Link>
                    </li>

                    <li>
                        <Link to='/admin/membership'>
                            <a href="#/" className="nav-link px-0 align-middle">
                                <i className="bi bi-person-lock text-white"></i><span className="ms-1 d-none d-sm-inline text-white">Membership</span></a>
                        </Link>
                    </li>
                    <li>
                        <Link to='/admin/order'>
                            <a href="#/" className="nav-link px-0 align-middle">
                                <i className="bi bi-cart-check text-white"></i><span className="ms-1 d-none d-sm-inline text-white">Orders</span></a>
                        </Link>
                    </li>
                    <li>
                        <Link to='/admin/usercontrol'>
                            <a href="#/" class="nav-link px-0 align-middle">
                            <i class="bi bi-person-lock text-white"></i><span class="ms-1 d-none d-sm-inline text-white">User</span></a>
                        </Link>
                    </li>
                    
                    <li>
                        <Link to='/admin/citybrowse'>
                            <a href="#/" class="nav-link px-0 align-middle">
                            <i class="bi bi-geo-alt text-white"></i><span class="ms-1 d-none d-sm-inline text-white">City</span></a>
                        </Link>
                    </li>
                    <li>
                        <Link to='/admin/flag-ads'>
                            <a href="#/" class="nav-link px-0 align-middle">
                            <i class="bi bi-badge-ad text-white"></i><span class="ms-1 d-none d-sm-inline text-white">Flag Ads</span></a>
                        </Link> 
                    </li> 
                    <li>
                        <Link to='/admin/features'>
                            <a href="#/" class="nav-link px-0 align-middle">
                            <i class="bi bi-badge-ad text-white"></i><span class="ms-1 d-none d-sm-inline text-white">Features Flag</span></a>
                        </Link> 
                    </li> 
                    {/* <li>
                        <a href="#submenu2" data-bs-toggle="collapse" className="nav-link px-0 align-middle ">
                            <i className="fs-4 bi-bootstrap"></i> <span className="ms-1 d-none d-sm-inline">Bootstrap</span></a>
                        <ul className="collapse nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                            <li className="w-100">
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 1</a>
                            </li>
                            <li>
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 2</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#submenu3" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                            <i className="fs-4 bi-grid"></i> <span className="ms-1 d-none d-sm-inline">Products</span> </a>
                            <ul className="collapse nav flex-column ms-1" id="submenu3" data-bs-parent="#menu">
                            <li className="w-100">
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Product</span> 1</a>
                            </li>
                            <li>
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Product</span> 2</a>
                            </li>
                            <li>
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Product</span> 3</a>
                            </li>
                            <li>
                                <a href="#/" className="nav-link px-0"> <span className="d-none d-sm-inline">Product</span> 4</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#/" className="nav-link px-0 align-middle">
                            <i className="fs-4 bi-people"></i> <span className="ms-1 d-none d-sm-inline">Customers</span> </a>
                    </li> 
          
                <hr /> */}
                   </ul> 
                {adminLoggedIn && <div className="dropdown pb-4 text-white">
                    <a href="#/" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src='/images/placeholder/tenantM.jpg' alt="admin" width="30" height="30"  color="white" className="rounded-circle" />
                        <span className="d-none d-sm-inline mx-1">{userDetail?.adminName}</span>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                        <li><a className="dropdown-item" href="#/" onClick={logoutHandler}>Sign out</a></li>
                    </ul>
                </div>}
           </div> 

        </>
    )
}

export default Columnnavbar