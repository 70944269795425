import { useState, useEffect, useCallback } from "react";
import { cityData } from "../utils/countryData";
import "./css/body.css";
import UserView from "./Userview";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import utils from '../utils/common';
import fetchFunction from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import { message } from "antd";
import { forAddress } from "../utils/helper";
import SearchBarNew from "./SearchBarNew";

const Body = ({isMembershipEnabled}) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [loginCheck, setLoginCheck] = useState(false);
  const [searchType, setSearchType] = useState('room');
  // const testingLocation = {address:'Kunj Vihar, Gwalior, Madhya Pradesh, India',lat : 26.22983000 ,lng:78.17337000}
  const [location, setLocation] = useState({});

  const navigate = useNavigate();
  //  const [active, setActive] = useState("");

  //  const actvieHandler = (userType) => {

  //    setActive(userType);
  //  };


  //Serch bar Handler
  // let city = "";

  // const inputSearchHandler = (inputValue) => {
  //   city = inputValue.cityId + '?city=' + inputValue.cityValue;
  //   console.log("cityName", city);
  // }

  const onClickSerchByCity =useCallback( (searchType) => {
    if (location?.address) {
      const forAdresss = forAddress(location?.address)
      const generateUrl = 'for-' + forAdresss + '/?latitude=' + location?.lat + '&longitude=' + location?.lng + '&address=' + location?.address
      if(searchType === "room") navigate('/find-rooms/' + generateUrl)
      else navigate("/find-tenants/" + generateUrl);
    } else {
      message.info('Please select a valid location.')
    }

  },[location?.address])
  const keydownHandler = (event, searchType) => {
    if (event.key === 'Enter' && event.keyCode === 13) {
      if (location?.address) onClickSerchByCity(searchType)
    } else {
      return null
    }
  }


  const handlerSubscription = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    if(email){
      const resp = await fetchFunction.post(apiMapper.SUBSCRIPTION, { email });
      if ([200, 201].includes(resp?.status)) {
        event.target.email.value = "";
        event.target.email.disabled = true;
        alert(`${email}, Successfully added to subscription list.`);
      } else {
        alert('Something Wrong Please try after sometime.');
      }
    } else{
      alert('Please enter Email.');
    }
    
  }

  useEffect(() => {
    setLoginCheck(utils.isLoggedIn())
  }, [onClickSerchByCity])

  const geoDataHandler = (address, lat, lng) => { setLocation({ address, lat, lng }) }

  return (
    <>
      <div className="homepage-main mt-md-5" >
        <div className="container-fluid px-0 pt-lg-4">
          <section id="home">
            <div className="container-fluid px-0 top-banner">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-lg-8 col-md-6 ">
                    <div className="container pt-lg-5">
                      <div className="row">
                        <h1 id="banner-main-text">BEST ROOM RENTAL PLATFORM</h1>
                        <h1 id="banner-text">FIND ROOMS AND TENANTS FASTER</h1>

                        {/* <h1 id="banner-text">GET YOUR ROOM RENTED FAST</h1> */}
                      </div>
                    </div>
                    
                    {/* { <div className="container toggler-tenant-owner">
                      <div className="col-lg-6 col-12">
                        <div className="tab row">
                          <div className={"col-6 owner " + (active === "owner" ? "active" : "")}>
                            <a href={null} onClick={() => { actvieHandler("owner"); }}>
                              List a Room </a>
                          </div>
                          <div className={"col-6 tenant " + (active === "tenant" ? "active" : "")}>
                            <a href={null} onClick={() => { actvieHandler("tenant"); }}>
                              Find a Room
                            </a>
                          </div>
                        </div>
                      </div>
                    </div> } */}
                    {/* Check box */}
                      <SearchBarNew
                        searchType={searchType} 
                        setSearchType={setSearchType} 
                        setLocation={setLocation} 
                        geoDataHandler={geoDataHandler}
                        searchHandler={onClickSerchByCity}
                        enterSearchHandler={keydownHandler}
                        />
                    {/* search button */}
                    
                  </div>
                  <div className="col-2"></div>
                </div>
              </div>
            </div>
          </section>
          
                    {/* 
  <     {/* USERVIEW  component*/}
          <UserView />

          {/* <!-- section-2 Member > */}
          <section id="counter">
            <section className="counter-section">
              <div className="container">
                {isMobile && <div className="row text-center">
                  {isMembershipEnabled ?<div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                    <h2>Free</h2>
                    <p>Register now to try us completely</p>
                    {localStorage.getItem('membershipStatus') === 'true'  ? ''
                      : 
                      <button onClick={() => { navigate('/membership') }} className="btn">BECOME A MEMBER</button>}
                  </div> : ''}
                  <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                    <h2>No. 1 Roommate Site in US</h2>
                    <p>Highest growing community with curated result</p>
                    <a href={loginCheck ? null : "/register"}>
                      <i className="bi bi-arrow-right-circle mx-1"></i>Register now
                    </a>
                  </div>

                  <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                    <h2>Highest Registered members</h2>
                    <p>Connecting to People over the web</p>
                    <a href="#/" onClick={() => onClickSerchByCity(searchType)}>
                      <i className="bi bi-arrow-right-circle mx-1"></i>Find rooms
                    </a>
                  </div>

                  <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                    <h2>Quick Help Click here for live support</h2>
                    <p>Get immediate assistance for any query here</p>
                    <a href="/contactus">
                      <i className="bi bi-arrow-right-circle mx-1"></i>Contact us
                    </a>
                  </div>
                </div>}

                {isDesktop && <>
                  <div className="row text-center counter-section-desktop-center">
                    {
                      isMembershipEnabled ? <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                      <h2>Free</h2>
                    </div> : ''
                    }
                    <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                      <h2>No. 1 Roommate Site in US</h2>
                    </div>
                    <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                      <h2>Highest Registered members</h2>
                    </div>
                    <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                      <h2>Quick Help Click here for live support</h2>
                    </div>
                  </div>
                  <div className="row text-center counter-section-desktop-center">
                    {isMembershipEnabled ? <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                      <p>Register now to try us completely</p>
                    </div>: ''}
                    <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                      <p>Highest growing community with curated result</p>

                    </div>
                    <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                      <p>Connecting to People over the web</p>

                    </div>
                    <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                      <p>Get immediate assistance for any query here</p>

                    </div>
                  </div>

                  <div className="row text-center counter-section-desktop-center">
                    {
                      isMembershipEnabled ? <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                      {               localStorage.getItem('membershipStatus') === 'true'  ? ''
   : <button onClick={() => { navigate('/membership') }} className="btn">BECOME A MEMBER</button>}
                      </div>:''
                    }
                    <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                      <a href={loginCheck ? null : "/register"}>
                        <i className="bi bi-arrow-right-circle mx-1"></i>Register now
                      </a>
                    </div>
                    <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                      <a href="#/" onClick={() => onClickSerchByCity(searchType)}>
                        <i className="bi bi-arrow-right-circle mx-1"></i>Find rooms
                      </a>
                    </div>
                    <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                      <a href="/contactus">
                        <i className="bi bi-arrow-right-circle mx-1"></i>Contact us
                      </a>
                    </div>
                  </div>
                </>}

              </div>
            </section>
          </section>

                
        
      {/* <!-- Section-5 testimonial--> */}
      {/* <section id="testimonial">
        <div className="wrapper testimonial-section">
          <div className="container text-center">
            <div className="text-center pb-4">
              <h3>Who are we</h3>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-10 offset-lg-1">
                <div
                  id="carouselExampleDark"
                  data-bs-ride="carousel"
                >
                  <div className="">
                    <div className="carousel-item active">
                      <div className="carousel-text">
                        <p>
                          We celebrate the vibrant spirit of the Desi community in USA and all around the world. We understand the unique blend of traditions, cultures, and aspirations that make our community special. Our mission is to bring desi folks together, fostering a sense of belonging and supporting each other in the journey of life abroad.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

          <div className="container-fluid BrowseCityMain">
            <section className="">
              <p className="d-flex justify-content-center  text-lg-start">
                <h3>BROWSE BY CITY</h3>
              </p>
            </section>

            <hr className="mb-4" />
            <div className="container p-4 pb-0">
              {/* <!-- Section: Links --> */}
              <section className="BrowseCity">
                <div className="container">
                  <div className="row  ">
                    {cityData.map((item, i) => {
                      return (
                        <>
                          <div
                            className={"mb-4 mb-md-0 text-center " + (isDesktop ? 'col-3' : 'col-6')}
                            key={i}
                          >
                            <ul className="list-unstyled country-name mb-0">
                              <li
                                className="ms-3"
                                style={{
                                  fontWeight: "600",
                                  fontFamily: "sans-serif",
                                  fontSize: "1.2rem",
                                }}
                              >
                                {" "}
                                {item.country}{" "}
                              </li>
                              {item.city.map((element, index) => {
                                const href = element?.[Object.keys(element)?.[0]]
                                const lat = href.longitude
                                const lng = href.latitude
                                const add = forAddress(href.address)
                                return (
                                  <>
                                    <li>
                                      <a
                                        href={href ? `/find-rooms/for-${add}/?latitude=${lat}&longitude=${lng}&address=${add}` : '#/'}
                                        className="city-name m-3"
                                        key={index}
                                      >
                                        {typeof (element) === 'object' ? Object.keys(element)?.[0] : element}
                                      </a>
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </section>
            </div>
          </div>

          {/* <!-- section-8 newslettar--> */}
          <section id="newslettar">
            <div className="newslettar wrapper newslettar-main">
              <div className="container ">
                <div className="row">
                  <div className="sol-sm-12">
                    <div className="text-content text-center pb-4">
                      <h2>
                        {loginCheck ? "Get Curated results for your search." : "Hurry up! Subscribe our newsletter and get latest offers"}
                      </h2>
                      <p>{"A Perfect partner for your house hunt."}</p>
                    </div>
                    {!loginCheck && <form onSubmit={handlerSubscription} className="newsletter">
                      <div className="row">
                        <div className="col-md-8 col-12">
                          <input
                            className="form-control newletter-input"
                            placeholder="Email Address here"
                            name="email"
                            type="email"
                            id="email"
                            required
                          />
                        </div>
                        <div className="col-md-4 col-12">
                          <button className="main-btn" type="submit">
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </form>}
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>

      </div>
    </>
  );
};

export default Body;
