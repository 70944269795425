import Commonlisting from "./Commanlisting"


const Tenant = ({isMembershipEnabled}) => {
  return (
   <>
       <Commonlisting pageType={'tenant'} isMembershipEnabled={isMembershipEnabled}/>
   </>
  )
}

export default Tenant