import React from "react";
import './css/SkeletonCommonListing.css';

export const SkeletonCommonListing = () => {
  return (
    [1,2,3].map((x) =>{
      return (<div key={x} className="skeleton-card animate-pulse">
        <div className="skeleton-avatar"></div>
        <div className="skeleton-text">
          <div className="skeleton-line title"></div>
          <div className="skeleton-line"></div>
          <div className="skeleton-line"></div>
          <div className="skeleton-line"></div>
          <div className="skeleton-line"></div>
          <div className="skeleton-line"></div>   
          <div className="skeleton-line"></div>
        </div>
        <div className="skeleton-button"></div>
      </div>)
    })
  );
};
