/**
 * Calculates the distance between two points on the Earth using the Haversine formula.
 *
 * @param {number} lat1 - Latitude of the first point.
 * @param {number} lon1 - Longitude of the first point.
 * @param {number} lat2 - Latitude of the second point.
 * @param {number} lon2 - Longitude of the second point.
 * @returns {number} Distance between the two points in miles.
 */
function haversineDistanceMiles(lat1, lon1, lat2, lon2) {
    const toRadians = angle => angle * (Math.PI / 180);
 
    const R = 3958.8; // Radius of the Earth in miles
 
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
 
    const a =
       Math.sin(dLat / 2) * Math.sin(dLat / 2) +
       Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
       Math.sin(dLon / 2) * Math.sin(dLon / 2);
 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
 
    const distance = R * c; // Distance in miles
    return distance.toFixed(1);
 }
 
//  // Example usage
//  const lat1 = 40.7128;
//  const lon1 = -74.0060; // New York City
//  const lat2 = 34.0522;
//  const lon2 = -118.2437; // Los Angeles
 
//  const distance = haversineDistanceMiles(lat1, lon1, lat2, lon2);
//  console.log(`Distance: ${distance.toFixed(2)} miles`);
//  const distance2 = haversineDistanceMiles(26.22983000,78.17337000,26.434252343403067, 78.05570912718868)
//  console.log(`Distance: ${distance2} miles`);
export default haversineDistanceMiles